/* eslint-disable max-len */
import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
// import { useStoreState } from 'easy-peasy';

// import uuid from 'uuid';
import { startCase, replace } from 'lodash';
// import moment from 'moment';

import client from '../../../utils/apolloClient';

const fetchPlaceQuery = gql`
  query fetchPlaceQuery($placeId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      is_test_place
      sales_member_info {
        sales_member_id
        sales_member_email
        sales_member_name
      }
      payment_account {
        account_id
      }
      place_id
      # vendor_id
      name
      slug
      trading_name
      tax_id {
        key
        value
      }
      tagline
      keywords
      description
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      item_review_place
      default_image_url
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      social {
        type
        logo
        description
        value
        display
      }
      latitude
      longitude
      service_type {
        name
        description
        display_order
      }
      service_category {
        name
        description
        display_order
      }
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      feature {
        name
        description
        logo
        display_order
      }
      dining_style {
        name
        description
        display_order
      }
      dietary_information {
        name
        description
        display_order
      }
      parking_detail {
        name
        description
        display_order
      }
      public_transport {
        name
        description
        display_order
      }
      noise_level {
        name
        description
        display_order
      }
      dress_code {
        name
        description
        display_order
      }
      business_type {
        name
        description
        display_order
      }
      ambience {
        name
        description
        display_order
      }
      good_for {
        name
        description
        display_order
      }
      service_type {
        name
        description
        display_order
      }
      tag
      listing_status
      claim_status
      price_range
      slug
      featured
      event_business
      item_review_place
      business_hour {
        day
        is_active
        option {
          type
          start
          end
          start_in_sec
          end_in_sec
        }
      }
      note {
        type
        value
        date
      }
      verification_mode
      status
      approval_status
      display_order
      url {
        uri
        display_order
      }
      voucher_count
      voucher_list {
        code
        description
        tagline
        display_order
      }

      tax {
        type
        value
      }
      default_url
      location
      #  stars
      review_count
      like_percentage
      claimant_id
      listing_audit {
        listing_approved_by
        listing_approved_at
        listing_contact_user_id
        listing_contact_name
        listing_contact_phone
        listing_contact_email
        listing_contact_role
        listing_verification_note {
          type
          value
          date
        }
      }
    }
  }
`;

export const usePlaceData = ({ placeId }) => {
  const {
    data: fetchPlaceData,
    loading: fetchPlaceLoading,
    error: fetchPlaceError,
  } = useQuery(fetchPlaceQuery, {
    variables: { placeId },
    client: client.clientPublic,
  });

  return [
    fetchPlaceLoading,
    fetchPlaceError,
    fetchPlaceData && fetchPlaceData.fetch_place
      ? fetchPlaceData.fetch_place
      : null,
  ];
};

const searchOnboardingSetupQuery = gql`
  query searchOnboardingSetupQuery {
    search_onboarding_setup(input: {}) {
      total_pages
      total_size
      onboarding_setup_data_listing {
        user_id
        onboarding_setup_id
        subscriptions {
          name
          fee_type
          value
        }
        fee {
          name
          fee_type
          value
        }
        error {
          code
          description
          object {
            id
            type
            name
          }
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const transformValue = (value, type) =>
  ({
    PERCENTAGE: `${parseFloat(value).toFixed(2)}%`,
    PRICE: `$${parseFloat(value).toFixed(2)}`,
  }[type]);

export const useOnboardingSetupData = () => {
  const {
    data: searchOnboardingSetupData,
    loading: searchOnboardingSetupLoading,
    error: searchOnboardingSetupError,
  } = useQuery(searchOnboardingSetupQuery, {
    variables: {},
    client: client.clientPublic,
  });

  const subscriptionsInfoForOnboarding = {};

  if (
    searchOnboardingSetupData?.search_onboarding_setup
      ?.onboarding_setup_data_listing &&
    Array.isArray(
      searchOnboardingSetupData.search_onboarding_setup
        .onboarding_setup_data_listing,
    ) &&
    searchOnboardingSetupData.search_onboarding_setup
      .onboarding_setup_data_listing[0] &&
    searchOnboardingSetupData.search_onboarding_setup
      .onboarding_setup_data_listing[0].subscriptions &&
    Array.isArray(
      searchOnboardingSetupData.search_onboarding_setup
        .onboarding_setup_data_listing[0].subscriptions,
    )
  ) {
    searchOnboardingSetupData.search_onboarding_setup.onboarding_setup_data_listing[0].subscriptions.forEach(
      ele => {
        subscriptionsInfoForOnboarding[ele.name] = {
          ...ele,
          tValue: transformValue(ele.value, ele.fee_type),
          is_selected: null,
          input_value: ele.value,
          type: ele.name.split('_')[0],
          label: `Subscription ${startCase(
            replace(ele.name, '_', ' ').toLowerCase(),
          )} (${
            parseFloat(ele.value) !== 0
              ? `fee ${transformValue(ele.value, ele.fee_type)}`
              : 'Free'
          })`,
          setup_value: ele.value,
        };
      },
    );
  }

  const feesInfoForOnboarding = {};

  if (
    searchOnboardingSetupData?.search_onboarding_setup
      ?.onboarding_setup_data_listing &&
    Array.isArray(
      searchOnboardingSetupData.search_onboarding_setup
        .onboarding_setup_data_listing,
    ) &&
    searchOnboardingSetupData.search_onboarding_setup
      .onboarding_setup_data_listing[0] &&
    searchOnboardingSetupData.search_onboarding_setup
      .onboarding_setup_data_listing[0].fee &&
    Array.isArray(
      searchOnboardingSetupData.search_onboarding_setup
        .onboarding_setup_data_listing[0].fee,
    )
  ) {
    searchOnboardingSetupData.search_onboarding_setup.onboarding_setup_data_listing[0].fee.forEach(
      ele => {
        feesInfoForOnboarding[ele.name] = {
          ...ele,
          tValue: transformValue(ele.value, ele.fee_type),
          is_selected: false,
          input_value: ele.value,
          label: `${startCase(replace(ele.name, '_', ' ').toLowerCase())} (${
            parseFloat(ele.value) !== 0
              ? `fee ${transformValue(ele.value, ele.fee_type)}`
              : 'Free'
          })`,
          setup_value: ele.value,
        };
      },
    );
  }

  return [
    searchOnboardingSetupLoading,
    searchOnboardingSetupError,
    {
      subscriptions: subscriptionsInfoForOnboarding,
      fees: feesInfoForOnboarding,
    },
    searchOnboardingSetupData?.search_onboarding_setup,
  ];
};

const searchOnboardingDataQuery = gql`
  query searchOnboardingDataQuery($placeId: String) {
    search_onboarding_data(
      input: { filter: { onboarding_filter: { place_id: $placeId } } }
    ) {
      onboarding_data_listing {
        lead_catering_type
        catering_lead_time
        catering_cut_off_time
        menu_item_photos
        catering_lead_time
        catering_cut_off_time
        user_id
        onboarding_data_id
        place_id
        place_bank_detail_id
        sales_type
        phone_number
        email
        website
        host_website_by_kravein
        pos_name
        online_aggregator
        is_bank_details
        venue_go_live_date
        comments
        suggested_percentage
        discount_to_pickup
        is_venue_to_kravein_redirection
        table_reservation
        menu_item_photos
        sales_rep_name
        sales_rep_email
        other_menu_suggestion_websites
        form_status
        status
        action
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        business_type {
          description
          display_order
          name
        }
        cuisine_style {
          description
          display_order
          name
        }
        error {
          code
          description
        }
        feature {
          description
          display_order
          name
        }
        good_about_place {
          description
          display_order
          name
        }
        kravein_onboarding_fee {
          fee_type
          name
          value
        }
        menu_document_url
        menu_item_photos
        menu_image
        menu_suggestion_websites
        owner_info {
          email
          name
          phone_number
        }
        service_category {
          description
          display_order
          name
        }
        subscriptions {
          fee_type
          name
          value
        }
        venue_photos
        uber_menu
        menu_onboarded
        fees_onboarded
        google_online_onboarded
        ship_day_key_onboarded
        bank_account_onboarded
        stripe_key_onboarded
        venue_domain_hosted
        venue_domain_purchased
      }
    }
  }
`;

export const useOnboardingPlaceData = ({ placeId }) => {
  const {
    data: onboardingPlaceData,
    loading: onboardingPlaceDataLoading,
    error: onboardingPlaceDataError,
    refetch,
  } = useQuery(searchOnboardingDataQuery, {
    variables: { placeId },
    client: client.clientPrivate,
  });

  const [
    refetchOnboardingPlaceDataLoading,
    setRefetchOnboardingPlaceDataLoading,
  ] = React.useState(false);

  const refetchOnboardingPlaceData = async () => {
    try {
      setRefetchOnboardingPlaceDataLoading(true);
      await refetch();
      setRefetchOnboardingPlaceDataLoading(false);
    } catch {
      setRefetchOnboardingPlaceDataLoading(false);
    }
  };

  const loading =
    onboardingPlaceDataLoading || refetchOnboardingPlaceDataLoading;

  return [
    loading,
    onboardingPlaceDataError,
    onboardingPlaceData &&
    onboardingPlaceData?.search_onboarding_data?.onboarding_data_listing &&
    Array.isArray(
      onboardingPlaceData?.search_onboarding_data?.onboarding_data_listing,
    ) &&
    onboardingPlaceData?.search_onboarding_data?.onboarding_data_listing[0]
      ? onboardingPlaceData?.search_onboarding_data?.onboarding_data_listing[0]
      : null,
    onboardingPlaceData?.search_onboarding_data,
    refetchOnboardingPlaceData,
  ];
};

const createOnboardingDataMutation = gql`
  mutation createOnboardingDataMutation($input: OnboardingDataInput) {
    create_onboarding_data(input: $input) {
      error {
        code
        description
      }
    }
  }
`;

const updateOnboardingDataMutation = gql`
  mutation updateOnboardingDataMutation($input: OnboardingDataInput) {
    update_onboarding_data(input: $input) {
      error {
        code
        description
      }
    }
  }
`;

export const createOnboardingData = async ({ input }) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createOnboardingDataMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.create_onboarding_data?.error) &&
      res.data.create_onboarding_data.error[0]
    ) {
      return {
        error: res.data.create_onboarding_data.error[0]?.description,
        isError: true,
      };
    }
    return {
      error: null,
      isError: false,
    };
  } catch (error) {
    return {
      error:
        "Oops! Something didn't work as expected. Please contact support if the issue persists.",
      isError: true,
    };
  }
};

export const updateOnboardingData = async ({ input }) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateOnboardingDataMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.update_onboarding_data?.error) &&
      res.data.update_onboarding_data.error[0]
    ) {
      return {
        error: res.data.update_onboarding_data.error[0]?.description,
        isError: true,
      };
    }
    return {
      error: null,
      isError: false,
    };
  } catch (error) {
    return {
      error:
        "Oops! Something didn't work as expected. Please contact support if the issue persists.",
      isError: true,
    };
  }
};

export const submitOnboardingData = async ({ input, isUpdate }) => {
  if (isUpdate) {
    const res1 = await updateOnboardingData({ input });
    return res1;
  }

  const res2 = await createOnboardingData({ input });
  return res2;
};

const fetchUserQuery = gql`
  query fetchUserQuery($input: NavigateUserInput) {
    fetch_user(input: $input) {
      user_id
      email
      display_name
      contact {
        type
        value
        is_primary
      }
      error {
        description
      }
    }
  }
`;

export const useAuditUserData = ({ userId }) => {
  const { data: userData } = useQuery(fetchUserQuery, {
    variables: { input: { user_id: userId } },
    client: client.clientPrivate,
  });

  if (userData && userData.fetch_user) {
    const { email, display_name: displayName } = userData.fetch_user;
    if (
      userData.fetch_user?.contact &&
      Array.isArray(userData.fetch_user.contact)
    ) {
      const phone = userData.fetch_user.contact.find(
        ele => ele.type === 'mobile',
      )
        ? userData.fetch_user.contact.find(ele => ele.type === 'mobile').value
        : '';

      return { email, displayName, phone };
    }
    return { email, displayName, phone: '' };
  }
  return { email: '', displayName: '', phone: '' };
};

export const readFile = async file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file.type.startsWith('image/') || file.type === 'application/pdf') {
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    } else {
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(file);
    }
  });

export default {};
