/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';

import BoxWithHeader from '../Order/ActiveCard';
import Card from './Card';

const UsersDashboardContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const getHistoricData = usersDashboardTableData => [
  { k: 'All Users', v: usersDashboardTableData?.allUser || '-' },
  {
    k: 'All Food Order Users',
    v: usersDashboardTableData?.allFoodOrdersUser || '-',
  },
  { k: 'Orders To Date', v: usersDashboardTableData?.ordersToDate || '-' },
  {
    k: 'Total Orders To Date',
    v: `$${parseFloat(
      usersDashboardTableData?.totalOrdersToDateOrderAmount || 0,
    ).toFixed(2)}`,
  },
  {
    k: 'Pickup Order To Date',
    v: usersDashboardTableData?.pickupOrderToDate || '-',
  },
  {
    k: 'Total Pickup Order To Date',
    v: `$${parseFloat(
      usersDashboardTableData?.totalPickupOrderToDateOrderAmount || 0,
    ).toFixed(2)}`,
  },
  {
    k: 'Dinein Order To Date',
    v: usersDashboardTableData?.dineinOrderToDate || '-',
  },
  {
    k: 'Total Dinein Order To Date',
    v: `$${parseFloat(
      usersDashboardTableData?.totalDineinOrderToDateOrderAmount || 0,
    ).toFixed(2)}`,
  },
  {
    k: 'Delivery Order To Date',
    v: usersDashboardTableData?.deliveryOrderToDate || '-',
  },
  {
    k: 'Total Delivery Order To Date',
    v: `$${parseFloat(
      usersDashboardTableData?.totalDeliveryOrderToDateOrderAmount || 0,
    ).toFixed(2)}`,
  },
];

const HistoricDashboardTable = ({ usersDashboardTableData, loading }) => {
  if (loading === false) {
    return (
      <div style={{ padding: '0.5rem', margin: '0.5rem' }}>
        <BoxWithHeader title="Historic Data">
          <UsersDashboardContainer>
            {loading === false &&
              !!usersDashboardTableData &&
              getHistoricData(usersDashboardTableData).map(ele => (
                <Card key={ele.k} heading={ele.k} content={ele.v} />
              ))}
          </UsersDashboardContainer>
        </BoxWithHeader>
      </div>
    );
  }
  return <></>;
};

export default HistoricDashboardTable;
