import React from 'react';

import { Table, Button, Icon } from 'rbx';

import styled from 'styled-components';

const Element = styled.div``;

const List = ({ allFees, onEdit }) => (
  <>
    {allFees.map(item => (
      <Table.Row>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.fee_type}</Table.Cell>
        <Table.Cell>{item.tValue}</Table.Cell>
        <Table.Cell>
          <Element>
            <Button.Group hasAddons>
              <Button onClick={() => onEdit(item)}>
                <Icon size="small">
                  <i className="fas fa-edit" />
                </Icon>
              </Button>
              {/* {!protectedTaxes.has(item.name) && (
                <Button onClick={() => onDelete(item)}>
                  <Icon size="small">
                    <i className="fas fa-trash has-text-grey" />
                  </Icon>
                </Button>
              )} */}
            </Button.Group>
          </Element>
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
