import React from 'react';
import styled from 'styled-components';

import { Label } from 'rbx';

import {
  Select,
  Input,
  CheckBox,
  TextArea,
  MobileInput,
  // InputErrorMessage,
} from '../../../components/elements';

import BookingTablePhoto from './BookingTablePhoto';

// import EnableTableBooking from './EnableTableBooking';

import BookingUrl from './BookingUrl';
import AutoCancelCounter from '../ServiceSetting/OtherSettings/AutoCancelCounter';
import Toggle from '../Menu/Designer/Toggle';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
`;

const Container2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const AlignDiv = styled.div`
  width: 100px;
  height: ${props => props.height || '50px'};
`;

const Info = styled.p`
  color: #b0b0b0;
  font-size: 12px;
`;

const PaddingDiv = styled.div`
  padding: ${props => props.verticalPadding || '1rem'}
    ${props => props.horizontalPadding || '0rem'};
`;

const OnlineBookings = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  menuOptions,
  selectedMenuValue,
  place,
}) => (
  <div style={{ paddingLeft: '4px' }}>
    <Container>
      <div>
        <BookingTablePhoto
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
        />
        <AlignDiv />
        <Label className="is-flex">
          <div style={{ width: '250px' }}>Display Booking Message</div>
          <CheckBox
            value={values.display_booking_message}
            onChange={value => setFieldValue('display_booking_message', value)}
          />
        </Label>
      </div>
      <div>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Publish Table Booking</div>
            <div style={{ position: 'relative', bottom: '2px' }}>
              <Toggle
                checked={values.publish_booking}
                onToggle={value => {
                  setFieldValue('publish_booking', value);
                }}
              />
            </div>
          </Label>
        </PaddingDiv>

        <PaddingDiv>
          <Input
            label="Primary Contact Email"
            name="contact_email"
            value={values.contact_email}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.contact_email}
            touched={touched.contact_email}
            disableToastMessage
            required
          />
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Allow guest to cancel Booking</div>
            <CheckBox
              value={values.allow_booking_cancel}
              onChange={value => setFieldValue('allow_booking_cancel', value)}
            />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Allow guest to modify Booking</div>
            <CheckBox
              value={values.allow_booking_change}
              onChange={value => setFieldValue('allow_booking_change', value)}
            />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>
              Allow booking during closed hours
            </div>
            <CheckBox
              value={values.schedule_order_when_closed}
              onChange={value =>
                setFieldValue('schedule_order_when_closed', value)
              }
            />
          </Label>
        </PaddingDiv>
        <BookingUrl place={place} />
      </div>
      <div>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Enable Table Booking</div>
            <CheckBox
              value={values.status === 'ACTIVE'}
              onChange={value => {
                if (value) {
                  setFieldValue('status', 'ACTIVE');
                } else {
                  setFieldValue('status', 'INACTIVE');
                }
              }}
            />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <MobileInput
            label="Primary Contact Phone"
            name="contact_phone"
            value={values.contact_phone}
            onChange={value => setFieldValue('contact_phone', value)}
            required
          />

          {errors.contact_phone && touched.contact_phone && (
            <p className="help is-danger is-size-4">{errors.contact_phone}</p>
          )}
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Auto Confirm Booking</div>
            <CheckBox
              value={values.auto_confirm}
              onChange={value => setFieldValue('auto_confirm', value)}
            />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Auto Cancel Booking</div>
            <CheckBox
              value={values.is_auto_cancel}
              onChange={value => setFieldValue('is_auto_cancel', value)}
            />
          </Label>
        </PaddingDiv>

        {values.is_auto_cancel && (
          <PaddingDiv>
            <AutoCancelCounter values={values} setFieldValue={setFieldValue} />
          </PaddingDiv>
        )}

        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Allow user to pre-order Menu</div>
            <CheckBox
              value={values.allow_user_pre_order}
              onChange={value => setFieldValue('allow_user_pre_order', value)}
            />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Select
            label="Select Menu"
            options={menuOptions}
            value={[selectedMenuValue]}
            onChange={value => {
              setFieldValue('selectedMenu', value.value);
            }}
            disableToastMessage
            required
          />
        </PaddingDiv>
      </div>
    </Container>
    <Label>Booking Message</Label>
    <TextArea
      name="booking_message"
      value={values.booking_message}
      onChange={handleChange}
      onBlur={handleBlur}
      errors={errors.booking_message}
      touched={touched.booking_message}
      disableToastMessage
      optionalText="(Min 10 and Max 288 characters)"
    />
    {values.booking_message && (
      <div style={{ textAlign: 'right' }}>
        {values.booking_message.length > 288 ||
        values.booking_message.length < 10
          ? ''
          : `Characters left ${288 - values.booking_message.length}`}
      </div>
    )}
    <Container2>
      <div>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Advance Booking</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.advance_booking_days}
              onChange={e =>
                setFieldValue('advance_booking_days', e.target.value)
              }
            />
            <div>Days</div>
          </Label>
          <Info>Maximum number of days user can book in advance</Info>
          {errors.advance_booking_days && (
            <p className="help is-danger is-size-4">
              {errors.advance_booking_days}
            </p>
          )}
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Booking Range</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.booking_range_min}
              onChange={e => setFieldValue('booking_range_min', e.target.value)}
            />
            <div>Minutes</div>
          </Label>
          <Info>Interval of when new bookings can be created.</Info>
          {errors.booking_range_min && (
            <p className="help is-danger is-size-4">
              {errors.booking_range_min}
            </p>
          )}
        </PaddingDiv>

        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Maximum Bookings per slot</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.max_booking_per_slot}
              onChange={e =>
                setFieldValue('max_booking_per_slot', e.target.value)
              }
            />
            <div>Bookings</div>
          </Label>
          <Info>Maximum number of bookings per booking time slot.</Info>
          {errors.max_booking_per_slot && (
            <p className="help is-danger is-size-4">
              {errors.max_booking_per_slot}
            </p>
          )}
        </PaddingDiv>

        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Min guests per booking</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.min_guests_per_booking}
              onChange={e =>
                setFieldValue('min_guests_per_booking', e.target.value)
              }
            />
          </Label>
          <Info>Minimum number of guests allowed to create a new booking</Info>
          {errors.min_guests_per_booking && (
            <p className="help is-danger is-size-4">
              {errors.min_guests_per_booking}
            </p>
          )}
        </PaddingDiv>
      </div>
      <div>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Minimum Lead Time</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.minimum_lead_time_min}
              onChange={e =>
                setFieldValue('minimum_lead_time_min', e.target.value)
              }
            />
            <div>Hour(s)</div>
          </Label>
          <Info>Minimum amount of time for new booking to be created.</Info>
          {errors.minimum_lead_time_min && (
            <p className="help is-danger is-size-4">
              {errors.minimum_lead_time_min}
            </p>
          )}
        </PaddingDiv>

        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Booking Cut-off Time</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.booking_cut_off_time}
              onChange={e =>
                setFieldValue('booking_cut_off_time', e.target.value)
              }
            />
            <div>Minutes</div>
          </Label>
          <Info>Last booking slot before closing time</Info>
          {errors.booking_cut_off_time && (
            <p className="help is-danger is-size-4">
              {errors.booking_cut_off_time}
            </p>
          )}
        </PaddingDiv>

        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Maximum Guests per slot</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.max_guests_per_slot}
              onChange={e =>
                setFieldValue('max_guests_per_slot', e.target.value)
              }
            />
            <div>Guests</div>
          </Label>
          <Info>Maximum number of guests per booking time slot.</Info>
          {errors.max_guests_per_slot && (
            <p className="help is-danger is-size-4">
              {errors.max_guests_per_slot}
            </p>
          )}
        </PaddingDiv>

        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '250px' }}>Max guests per booking</div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value={values.max_guests_per_booking}
              onChange={e =>
                setFieldValue('max_guests_per_booking', e.target.value)
              }
            />
          </Label>
          <Info>Maximum number of guests allowed to create a new booking</Info>
          {errors.max_guests_per_booking && (
            <p className="help is-danger is-size-4">
              {errors.max_guests_per_booking}
            </p>
          )}
        </PaddingDiv>
      </div>
    </Container2>
  </div>
);

export default OnlineBookings;
