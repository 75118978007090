/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import Card from './Card';

const UsersDashboardContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const LoadingSpan = styled.span`
  padding: 10px;
  color: #00aeef;
  font-size: 2rem;
`;

const getData = usersDashboardTableData => [
  { k: 'Active Users', v: usersDashboardTableData?.activeUser || '-' },
  { k: 'New Users', v: usersDashboardTableData?.newUser || '-' },
  {
    k: 'Active Food Order Users',
    v: usersDashboardTableData?.activeFoodOrderUser || '-',
  },
  {
    k: 'New Food Order Users',
    v: usersDashboardTableData?.newFoodOrdersUser || '-',
  },
  {
    k: 'Orders',
    v: usersDashboardTableData?.orders,
  },
  {
    k: 'Total',
    v: `$${parseFloat(usersDashboardTableData?.totalOrderAmount || 0).toFixed(
      2,
    )}`,
  },
  { k: 'Pickup Order', v: usersDashboardTableData?.pickupOrder || '-' },
  {
    k: 'Total Pickup',
    v: `$${parseFloat(
      usersDashboardTableData?.totalPickupOrderAmount || 0,
    ).toFixed(2)}`,
  },
  { k: 'Dinein Order', v: usersDashboardTableData?.dineinOrder || '-' },
  {
    k: 'Total Dinein',
    v: `$${parseFloat(
      usersDashboardTableData?.totalDineinOrderAmount || 0,
    ).toFixed(2)}`,
  },
  { k: 'Delivery Order', v: usersDashboardTableData?.deliveryOrder || '-' },
  {
    k: 'Total Delivery',
    v: `$${parseFloat(
      usersDashboardTableData?.totalDeliveryOrderAmount || 0,
    ).toFixed(2)}`,
  },
  { k: 'Table Booking', v: usersDashboardTableData?.tableBooking || '-' },
  {
    k: 'Total Table Booking',
    v: `$${parseFloat(
      usersDashboardTableData?.totalTableBookingAmount || 0,
    ).toFixed(2)}`,
  },
];

const UsersDashboardTable = ({ usersDashboardTableData, loading }) => (
  <UsersDashboardContainer>
    {loading && <LoadingSpan>Loading...</LoadingSpan>}
    {loading === false &&
      !!usersDashboardTableData &&
      getData(usersDashboardTableData).map(ele => (
        <Card key={ele.k} heading={ele.k} content={ele.v} />
      ))}
  </UsersDashboardContainer>
);

export default UsersDashboardTable;
