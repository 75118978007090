import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { omit, isEqual } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import AdminUserActivity from '../AdminUser/AdminUserActivity';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      onboarding_form_access
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      date_of_birth
      gender
      role
      security {
        assign_roles
      }
      access {
        place_id
      }
      description
      status
      user_handle
      external_payment_id
      visibility
      rating
      contact {
        type
        value
        display
        display_order
        is_primary
      }

      address {
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const updateMutation = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      user_id
      error {
        description
      }
    }
  }
`;

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        status
        claim_status
      }
    }
  }
`;

const UserUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={userQuery}
        variables={{
          userId: match.params.userId,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) {
            return <Message>error</Message>;
          }

          const { fetch_user } = data;

          return (
            <Query
              query={placesQuery}
              variables={{
                input: {
                  sort: 'UPDATED_DESC',
                  filter: {
                    place_filter: {
                      place_id:
                        fetch_user.access && fetch_user.access.place_id
                          ? fetch_user.access.place_id
                          : [],
                    },
                  },
                  user_id: userId,
                },
              }}
              fetchPolicy="network-only"
              client={client.clientPrivate}
            >
              {({ data: data2, loading: loading2, error: error2 }) => {
                const filteredPlaces =
                  data2 &&
                  data2.search_places &&
                  data2.search_places.place_listing.map(item => ({
                    value: item.place_id,
                    label: item.name,
                  }));

                if (loading || loading2) {
                  return <Loading />;
                }

                if (error || error2) {
                  return <Message>error</Message>;
                }

                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updateMutation}
                    onCompleted={() => {
                      swal(
                        'Great!',
                        'User has been updated successfully!',
                        'success',
                      ).then(() => {
                        history.push('/admin-users');
                      });
                    }}
                  >
                    {(
                      update_user,
                      { loading: updateLoading, error: updateUser },
                    ) => (
                      <AdminUserActivity
                        error={updateUser}
                        loading={updateLoading}
                        user={fetch_user}
                        places={filteredPlaces}
                        onSubmit={value => {
                          const input = omit(value, [
                            'primaryContact',
                            'contact',
                            'address',
                            'profile_image',
                            'places',
                          ]);

                          const final = {};
                          Object.assign(
                            final,
                            { object_id: userId },
                            { user_id: fetch_user.user_id },
                            !isEqual(fetch_user.email, input.email) && {
                              email: input.email,
                            },

                            !isEqual(fetch_user.status, input.status) && {
                              status: input.status,
                            },
                            !isEqual(fetch_user.role, input.role) && {
                              role: input.role,
                            },
                            !isEqual(
                              fetch_user.onboarding_form_access,
                              input.onboarding_form_access,
                            ) && {
                              onboarding_form_access:
                                input.onboarding_form_access,
                            },
                            {
                              access: omit(input.access, '__typename'),
                            },
                          );
                          update_user({ variables: { input: final } });
                        }}
                      />
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default UserUpdate;
