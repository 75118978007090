/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { Label } from 'rbx';
import {
  forEach,
  // isNaN,
  omit,
} from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import format from 'date-fns/format';

import Header from './Header';

import Input from '../../../components/elements/Input';
import {
  CheckBox,
  InputAddOn,
  InputErrorMessage,
  MobileInput,
  Radio,
  ReactDateTimePicker,
  Select,
  SelectTime,
  Table,
  TagInputs,
  TextArea,
} from '../../../components/elements';
import UserSuggestions from '../../../components/global/UserSuggestions';
import CheckBox2 from '../../../components/elements/CheckBox2';
import {
  getAmbience,
  getNoiseLevels,
  getPublicTransports,
  getParkingDetails,
  getDressCodes,
  getGoodFor,
  getCuisines,
  getDiningStyle,
  getFeatures,
  getItemTypes,
  getServicesTypes,
  getServiceCategories,
  getPlaceTypes,
  getDietaryInformation,
} from '../../../utils/fetchStatic';
import { submitOnboardingData, useAuditUserData, readFile } from './helpers';
import { upload_onboard_file, upload_onboard_image } from '../../../utils/s3';
import CheckBox3 from '../../../components/elements/CheckBox3';
import Footer from './Footer';

const Container = styled.div`
  margin: 1rem;
  padding: 1rem;
  width: 75vw;
`;

const UserInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`;

const TimelineContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin: 10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 2rem;
`;

const GridContainer2 = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
`;

const STATUS = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'SUBMIT', label: 'Submit' },
  { value: 'HOLD', label: 'Hold' },
  { value: 'INACTIVE', label: 'Inactive' },
  { value: 'COMPLETED', label: 'Completed' },
];

const SALESTYPE = [
  { value: 'LEAD', label: 'Lead' },
  { value: 'ONBOARDING', label: 'Onboarding' },
];

const transformValue = (value, type) =>
  ({
    PERCENTAGE: `${parseFloat(value).toFixed(2)}%`,
    PRICE: `$${parseFloat(value).toFixed(2)}`,
  }[type]);

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename', 'logo']));
  });
  return final;
};

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseDateString = dateString => {
  const parts = dateString.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
};

const formatDateObj = dateString => {
  const dateObject = new Date(dateString);
  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
};

const Tabs = ({ activeTab, setActiveTab }) => (
  <div className="tabs is-left is-medium">
    <ul>
      <li className={`${activeTab === 0 ? 'is-active' : ''}`}>
        <a onClick={() => setActiveTab(0)}>Listing Details</a>
      </li>
      <li className={`${activeTab === 1 ? 'is-active' : ''}`}>
        <a onClick={() => setActiveTab(1)}>Menu Details</a>
      </li>
      <li className={`${activeTab === 2 ? 'is-active' : ''}`}>
        <a onClick={() => setActiveTab(2)}>Listing Place Details</a>
      </li>
      <li className={`${activeTab === 3 ? 'is-active' : ''}`}>
        <a onClick={() => setActiveTab(3)}>Uploads</a>
      </li>
      {/* <li className={`${activeTab === 4 ? 'is-active' : ''}`}>
        <a onClick={() => setActiveTab(4)}>Uploaded Documents</a>
      </li> */}
    </ul>
  </div>
);

const OnboardPlaceForm = props => {
  const {
    isSubmitting,
    handleSubmit,
    place,
    history,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props;

  const [activeTab, setActiveTab] = React.useState(0);

  const [menuImageFiles, setMenuImageFiles] = React.useState([]);
  const [menuImageFilesLoading, setMenuImageFilesLoading] = React.useState(
    false,
  );
  const [pdfFile, setPdfFile] = React.useState([]);
  const [pdfFileLoading, setPdfFileLoading] = React.useState(false);

  const auditUser = useAuditUserData({
    userId: props?.onboardingPlaceData?.audit?.created_by,
  });

  const [data, setData] = React.useState([
    {
      businessTypes: [],
      serviceCategories: [],
      servicesTypes: [],
      itemTypes: [],
      features: [],
      diningStyle: [],
      cuisines: [],
      goodFor: [],
      dressCodes: [],
      parkingDetails: [],
      publicTransports: [],
      ambience: [],
      noiseLevels: [],
      dietaryInformation: [],
    },
  ]);

  console.log('OnboardPlaceFormValues...', values);

  const salesRep =
    values.sales_rep_name && values.sales_rep_email
      ? `${values.sales_rep_name} ${values.sales_rep_email}`
      : '';

  React.useEffect(() => {
    const getData = async () => {
      const serviceCategories = await getServiceCategories();
      const businessTypes = await getPlaceTypes();
      const servicesTypes = await getServicesTypes();
      const itemTypes = await getItemTypes();
      const features = await getFeatures();
      const diningStyle = await getDiningStyle();
      const cuisines = await getCuisines();
      const goodFor = await getGoodFor();
      const dressCodes = await getDressCodes();
      const parkingDetails = await getParkingDetails();
      const publicTransports = await getPublicTransports();
      const ambience = await getAmbience();
      const noiseLevels = await getNoiseLevels();
      const dietaryInformation = await getDietaryInformation();

      setData({
        serviceCategories,
        servicesTypes,
        businessTypes,
        itemTypes,
        features,
        diningStyle,
        cuisines,
        goodFor,
        dressCodes,
        parkingDetails,
        publicTransports,
        ambience,
        noiseLevels,
        dietaryInformation,
      });
    };
    getData();
  }, []);

  console.log('audit object...', props?.onboardingPlaceData?.audit);

  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 4 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  React.useEffect(() => {
    if (formatDateObj(values.venue_go_live_date) === '01/01/1970') {
      setFieldValue('venue_go_live_date', '');
    }
  }, [setFieldValue, values.venue_go_live_date]);

  const shldHaveLeadTimeFunc = (() =>
    values.kravein_onboarding_fee &&
    Array.isArray(values.kravein_onboarding_fee) &&
    !!values.kravein_onboarding_fee.find(
      ele => ele.label.startsWith('Catering') && ele.is_selected,
    ))();

  React.useEffect(() => {
    if (!shldHaveLeadTimeFunc) {
      setFieldValue('lead_catering_type', '');
      setFieldValue('catering_lead_time', '');
      setFieldValue('catering_cut_off_time', '');
    }
  }, [shldHaveLeadTimeFunc]);

  return (
    <>
      <Header
        formStatus={values.status}
        loading={isSubmitting}
        handleSubmit={handleSubmit}
        place={{
          name: place.name,
          state: place.state,
          city: place.city,
          post_code: place.post_code,
          claim_status: place.claim_status,
          status: place.status,
        }}
        history={history}
        activeTab={activeTab}
        next={next}
        prev={prev}
        saveDraft={values.status === 'DRAFT'}
      />
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Container>
        <fieldset className="box">
          <Label>Created by user details</Label>
          <UserInfoContainer>
            <Input
              label="User Full Name"
              value={auditUser.displayName}
              disabled
            />
            <MobileInput
              label="Contact Phone"
              value={auditUser.phone}
              disabled
            />
            <Input label="Contact Email" value={auditUser.email} disabled />
            <Input
              label="Created On"
              value={
                props?.onboardingPlaceData?.audit?.created_at
                  ? format(
                      new Date(props?.onboardingPlaceData?.audit?.created_at),
                      'dd MMMM yyyy',
                    )
                  : format(new Date(), 'dd MMMM yyyy')
              }
              disabled
            />
          </UserInfoContainer>
        </fieldset>
        <br />
        <TimelineContainer>
          <CheckBox
            label="Menu Onboarded"
            name="menu_onboarded"
            value={values.menu_onboarded}
            onChange={value => setFieldValue('menu_onboarded', value)}
          />
          <CheckBox
            label="Fees Onboarded"
            name="fees_onboarded"
            value={values.fees_onboarded}
            onChange={value => setFieldValue('fees_onboarded', value)}
          />
          <CheckBox
            label="Google Online Onboarded"
            name="google_online_onboarded"
            value={values.google_online_onboarded}
            onChange={value => setFieldValue('google_online_onboarded', value)}
          />
          <CheckBox
            label="Ship Day Key Onboarded"
            name="ship_day_key_onboarded"
            value={values.ship_day_key_onboarded}
            onChange={value => setFieldValue('ship_day_key_onboarded', value)}
          />
          <CheckBox
            label="Bank Account Onboarded"
            name="bank_account_onboarded"
            value={values.bank_account_onboarded}
            onChange={value => setFieldValue('bank_account_onboarded', value)}
          />
          <CheckBox
            label="Stripe Key Onboarded"
            name="stripe_key_onboarded"
            value={values.stripe_key_onboarded}
            onChange={value => setFieldValue('stripe_key_onboarded', value)}
          />
          <CheckBox
            label="Venue Domain purchased"
            name="venue_domain_purchased"
            value={values.venue_domain_purchased}
            onChange={value => setFieldValue('venue_domain_purchased', value)}
          />
          <CheckBox
            label="Venue Domain hosted"
            name="venue_domain_hosted"
            value={values.venue_domain_hosted}
            onChange={value => setFieldValue('venue_domain_hosted', value)}
          />
        </TimelineContainer>
        <br />
        {activeTab === 0 && (
          <>
            <GridContainer>
              <Select
                label="Form Status"
                options={STATUS}
                id="onboard_status"
                value={[{ value: values.status, label: values.status }]}
                onChange={({ value }) => setFieldValue('status', value)}
                errors={errors.status}
                touched={touched.status}
              />
              <Select
                label="Sales Type"
                options={SALESTYPE}
                id="sales_type"
                value={[{ value: values.sales_type, label: values.sales_type }]}
                onChange={({ value }) => setFieldValue('sales_type', value)}
                errors={errors.sales_type}
                touched={touched.sales_type}
              />
              <Input label="Place Name" value={place.name} required disabled />
              <Input
                label="Street Address"
                value={place.address_line_1}
                required
                disabled
              />
              <Input
                label="Suburb / City"
                value={place.city}
                required
                disabled
              />
              <Input
                label="Pin / Postcode"
                value={place.post_code}
                required
                disabled
              />
              <Input label="State" value={place.state} required disabled />
              <Input label="Country" value={place.country} required disabled />
              <Input
                label="Listing Status"
                value={place.listing_status}
                required
                disabled
              />
              <div>
                <MobileInput
                  label="Primary Contact Phone"
                  id="values.phone_number"
                  value={values.phone_number}
                  onChange={value => {
                    setFieldValue('phone_number', value);
                  }}
                  required
                />
                <InputErrorMessage
                  errors={errors.phone_number}
                  touched={touched.phone_number}
                  setErrors={() => {}}
                />
              </div>
              <Input
                label="Restaurant Email (for Order / Booking Notifications)"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.email && touched.email && 'is-danger'}
                errors={errors.email}
                touched={touched.email}
                disableToastMessage
                required
              />
              <Input
                label="Restaurant Owner / Manager Name"
                name="owner_info_name"
                value={values.owner_info_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.owner_info_name &&
                  touched.owner_info_name &&
                  'is-danger'
                }
                errors={errors.owner_info_name}
                touched={touched.owner_info_name}
                disableToastMessage
                required
              />
              <Input
                label="Restaurant Owner / Manager Email"
                name="owner_info_email"
                value={values.owner_info_email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.owner_info_email &&
                  touched.owner_info_email &&
                  'is-danger'
                }
                errors={errors.owner_info_email}
                touched={touched.owner_info_email}
                disableToastMessage
                required
              />
              <div>
                <MobileInput
                  label="Restaurant Owner / Manager Mobile Number"
                  id="values.owner_info_phone_number"
                  value={values.owner_info_phone_number}
                  onChange={value => {
                    setFieldValue('owner_info_phone_number', value);
                  }}
                  required
                />
                <InputErrorMessage
                  errors={errors.owner_info_phone_number}
                  touched={touched.owner_info_phone_number}
                  setErrors={() => {}}
                />
              </div>
            </GridContainer>
            <div style={{ position: 'relative' }}>
              <span
                style={{
                  position: 'absolute',
                  left: '150px',
                  fontSize: '12px',
                  fontWeight: '700',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setFieldValue('venue_go_live_date', null);
                }}
              >
                Clear Date
              </span>
              <ReactDateTimePicker
                value={values.venue_go_live_date}
                label="Go Live Date"
                onChange={date => {
                  setFieldValue('venue_go_live_date', date);
                }}
                minDate={new Date()}
                clear
                required
              />
            </div>
            <GridContainer>
              <UserSuggestions
                initialValue=""
                label="Search For Sales Rep"
                onSelect={userData => {
                  setFieldValue('sales_rep_email', userData.email);
                  setFieldValue('sales_rep_name', userData.display_name);
                }}
                onHandleClear={() => {
                  setFieldValue('sales_rep_email', null);
                  setFieldValue('sales_rep_name', null);
                }}
              />
              <Input label="Sales Rep Details" value={salesRep} />
              <Input
                label="Business Website"
                name="website"
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.website && touched.website && 'is-danger'}
                errors={errors.website}
                touched={touched.website}
                required
                disableToastMessage
              />
            </GridContainer>
            <div className="is-flex mt-3">
              <CheckBox
                label="Host Venue Website"
                name="host_website_by_kravein"
                value={values.host_website_by_kravein}
                onChange={value =>
                  setFieldValue('host_website_by_kravein', value)
                }
              />
              <div className="ml-6" />
            </div>
            <GridContainer>
              <Input label="KRAVEiN Purchased Domain Website" />
              <Input
                label="Name of the POS used at this Restaurant"
                name="pos_name"
                value={values.pos_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.pos_name && touched.pos_name && 'is-danger'}
                errors={errors.pos_name}
                touched={touched.pos_name}
                disableToastMessage
              />
              <Input
                label="Name of the Online Aggregrator used at this Restaurant"
                name="online_aggregator"
                value={values.online_aggregator}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.online_aggregator &&
                  touched.online_aggregator &&
                  'is-danger'
                }
                errors={errors.online_aggregator}
                touched={touched.online_aggregator}
                disableToastMessage
              />
            </GridContainer>
            <div className="mt-3">
              <CheckBox
                label="Banking Details Submitted"
                name="bank_id"
                value={values.bank_id}
                disabled
              />
            </div>
            <div className="mt-3">
              <CheckBox
                label="Venue placing KRAVEiN Order online url in their Venue Website"
                name="is_venue_to_kravein_redirection"
                value={values.is_venue_to_kravein_redirection}
                onChange={value =>
                  setFieldValue('is_venue_to_kravein_redirection', value)
                }
              />
            </div>
          </>
        )}
        {activeTab === 1 && (
          <>
            <GridContainer>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Label>Menu to Upload</Label>
                <div>
                  <span
                    className="is-size-4"
                    style={{
                      color: '#F36E21',
                      position: 'relative',
                      top: '-8px',
                    }}
                  >
                    Other Menus can include Uber, doordash, Menulog etc
                  </span>
                </div>
                <div
                  style={{ justifyContent: 'space-between' }}
                  className="is-flex"
                >
                  <Radio
                    label="Other Menu"
                    name="uber_menu"
                    value={values.uber_menu === 'UBER'}
                    onChange={() => {
                      setFieldValue('uber_menu', 'UBER');
                      setFieldValue('website0', '');
                      setFieldValue('discount_to_pickup', null);
                      setFieldValue('suggested_percentage', null);
                    }}
                  />
                  <Radio
                    label="Venu Menu"
                    name="uber_menu"
                    value={values.uber_menu === 'VENUE'}
                    onChange={() => {
                      setFieldValue('uber_menu', 'VENUE');
                      setFieldValue('website0', '');
                      setFieldValue('discount_to_pickup', null);
                      setFieldValue('suggested_percentage', null);
                    }}
                  />
                </div>
              </div>
              <div />
              {values.uber_menu === 'UBER' && (
                <>
                  <Label>Name of the other website to copy the Menu</Label>
                  <Input
                    name="website0"
                    value={values.website0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website0}
                    touched={touched.website0}
                    disableToastMessage
                  />
                  <div style={{ position: 'relative', top: '25px' }}>
                    &nbsp;
                    {/* <CheckBox label="Add KRAVEiN Delivery Fee to Venue menu price" /> */}
                  </div>
                  <div
                    className="is-flex"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div style={{ marginRight: '12px' }}>
                      <Input
                        label="%age to deduct from other menu"
                        addonsText=" "
                        isLeft
                        value={values.suggested_percentage}
                        onChange={e => {
                          if (e.target.value > 100) {
                            alert('The input value must be less than 100!');
                            return;
                          }

                          if (e.target.value < 0) {
                            alert(
                              'The input value must be greater than or equal to 0!',
                            );
                            return;
                          }

                          if (/^\d*$/.test(e.target.value)) {
                            setFieldValue(
                              'suggested_percentage',
                              e.target.value,
                            );
                          }
                        }}
                        errors={errors.suggested_percentage}
                        touched={touched.suggested_percentage}
                        disableToastMessage
                        type="number"
                        min="1"
                        max="1000"
                      />
                    </div>
                    <Input
                      label="Discount Pickup Menu"
                      addonsText=" "
                      isLeft
                      value={values.discount_to_pickup}
                      onChange={e => {
                        if (e.target.value > 100) {
                          alert('The input value must be less than 100!');
                          return;
                        }

                        if (e.target.value < 0) {
                          alert(
                            'The input value must be greater than or equal to 0!',
                          );
                          return;
                        }

                        if (/^\d*$/.test(e.target.value)) {
                          setFieldValue('discount_to_pickup', e.target.value);
                        }
                      }}
                      errors={errors.discount_to_pickup}
                      touched={touched.discount_to_pickup}
                      disableToastMessage
                      type="number"
                      min="1"
                      max="1000"
                    />
                  </div>
                  <Label>Other website references</Label>
                  <Input
                    name="website1"
                    value={values.website1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website1}
                    touched={touched.website1}
                    disableToastMessage
                  />
                  <Input
                    name="website2"
                    value={values.website2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website2}
                    touched={touched.website2}
                    disableToastMessage
                  />
                  <Input
                    name="website3"
                    value={values.website3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website3}
                    touched={touched.website3}
                    disableToastMessage
                  />
                  <Label>Upload PDF, word or photo of Menu</Label>
                  <div />
                  <div className="file has-name is-boxed is-primary">
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="upload-pdf"
                        accept=".pdf,.doc,.docx,.csv,.xlsx,image/*"
                        // multiple
                        onChange={async event => {
                          setPdfFileLoading(true);

                          const tfiles = event.target.files;

                          if (tfiles.length > 1) {
                            alert('Please select one document at a time!');
                            return;
                          }

                          if (tfiles && tfiles[0] && tfiles[0]?.name) {
                            console.log('uploaded files...', tfiles);

                            setPdfFile([...pdfFile, tfiles[0]]);

                            const timestamp = new Date().getTime();

                            const fileName = tfiles[0].name;

                            const file = await readFile(tfiles[0]);

                            if (
                              tfiles[0]?.type &&
                              tfiles[0].type.startsWith('image/')
                            ) {
                              const res = await upload_onboard_image(
                                file,
                                `${timestamp}_${fileName}`,
                                {},
                                tfiles[0].type,
                              );

                              setFieldValue('menu_image', [
                                ...values.menu_image,
                                res.Location,
                              ]);
                            } else {
                              const res = await upload_onboard_file(
                                file,
                                `${timestamp}_${fileName}`,
                                {},
                                tfiles[0].type,
                              );

                              setFieldValue('menu_document_url', [
                                ...values.menu_document_url,
                                res.Location,
                              ]);
                            }
                          }

                          setPdfFileLoading(false);
                        }}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        <span className="file-label"> Choose a file… </span>
                      </span>
                      {Array.isArray(Array.from(pdfFile)) &&
                        Array.from(pdfFile).length !== 0 && (
                          <>
                            <span
                              title={Array.from(pdfFile)
                                .map(ele => ele.name)
                                .join(', ')}
                              className="file-name"
                            >
                              {Array.from(pdfFile)
                                .map(ele => ele.name)
                                .join(', ')}
                            </span>
                          </>
                        )}
                      {pdfFileLoading && (
                        <progress
                          className="progress is-small is-primary"
                          max="100"
                        >
                          15%
                        </progress>
                      )}
                    </label>
                  </div>
                  {Array.isArray(Array.from(pdfFile)) &&
                    Array.from(pdfFile).length !== 0 && (
                      <>
                        <div />
                        <div>
                          {Array.from(pdfFile).map(ele => (
                            <p
                              style={{
                                color: '#00b28c',
                                wordWrap: 'break-word',
                                margin: '10px 0',
                              }}
                            >
                              {ele.name}
                            </p>
                          ))}
                        </div>
                      </>
                    )}
                  <div />
                  {values.documentsUploaded &&
                    Array.isArray(values.documentsUploaded) &&
                    values.documentsUploaded.length !== 0 && (
                      <>
                        <Label> Uploaded Documents </Label>
                        <div />
                        {values.documentsUploaded &&
                        Array.isArray(values.documentsUploaded) &&
                        values.documentsUploaded.length !== 0 ? (
                          <Table>
                            <tbody>
                              {values.documentsUploaded.map(ele => (
                                <tr
                                  style={{
                                    cursor: 'pointer',
                                    color: '#00AEEF',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <a
                                    href={ele}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {ele}
                                  </a>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <span style={{ color: '#F36E21' }}>
                            Not uploaded!
                          </span>
                        )}
                      </>
                    )}
                </>
              )}
              {values.uber_menu === 'VENUE' && (
                <>
                  <Label>Name of the Venue website to copy the Menu</Label>
                  <Input
                    name="website0"
                    value={values.website0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website0}
                    touched={touched.website0}
                    disableToastMessage
                  />

                  <div style={{ position: 'relative', top: '25px' }}>
                    &nbsp;
                    {/* <CheckBox label="Add KRAVEiN Delivery Fee to Venue menu price" /> */}
                  </div>
                  <div
                    className="is-flex"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div style={{ marginRight: '12px' }}>
                      <Input
                        label="%age to add"
                        addonsText=" "
                        isLeft
                        value={values.suggested_percentage}
                        onChange={e => {
                          if (e.target.value > 100) {
                            alert('The input value must be less than 100!');
                            return;
                          }

                          if (e.target.value < 0) {
                            alert(
                              'The input value must be greater than or equal to 0!',
                            );
                            return;
                          }

                          if (/^\d*$/.test(e.target.value)) {
                            setFieldValue(
                              'suggested_percentage',
                              e.target.value,
                            );
                          }
                        }}
                        errors={errors.suggested_percentage}
                        touched={touched.suggested_percentage}
                        disableToastMessage
                        type="number"
                        min="1"
                        max="1000"
                      />
                    </div>
                    <Input
                      label="Discount Pickup Menu"
                      addonsText=" "
                      isLeft
                      value={values.discount_to_pickup}
                      onChange={e => {
                        if (e.target.value > 100) {
                          alert('The input value must be less than 100!');
                          return;
                        }

                        if (e.target.value < 0) {
                          alert(
                            'The input value must be greater than or equal to 0!',
                          );
                          return;
                        }

                        if (/^\d*$/.test(e.target.value)) {
                          setFieldValue('discount_to_pickup', e.target.value);
                        }
                      }}
                      errors={errors.discount_to_pickup}
                      touched={touched.discount_to_pickup}
                      disableToastMessage
                      type="number"
                      min="1"
                      max="1000"
                    />
                  </div>
                  <Label>Other website references</Label>
                  <Input
                    name="website1"
                    value={values.website1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website1}
                    touched={touched.website1}
                    disableToastMessage
                  />
                  <Input
                    name="website2"
                    value={values.website2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website2}
                    touched={touched.website2}
                    disableToastMessage
                  />
                  <Input
                    name="website3"
                    value={values.website3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.website3}
                    touched={touched.website3}
                    disableToastMessage
                  />
                  <Label>Upload PDF, word or photo of Menu</Label>
                  <div />
                  <div className="file has-name is-boxed is-primary">
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="upload-pdf"
                        accept=".pdf,.doc,.docx,.csv,.xlsx,image/*"
                        // multiple
                        onChange={async event => {
                          setPdfFileLoading(true);

                          const tfiles = event.target.files;

                          if (tfiles.length > 1) {
                            alert('Please select one document at a time!');
                            return;
                          }

                          if (tfiles && tfiles[0] && tfiles[0]?.name) {
                            console.log('uploaded files...', tfiles);

                            setPdfFile([...pdfFile, tfiles[0]]);

                            const timestamp = new Date().getTime();

                            const fileName = tfiles[0].name;

                            const file = await readFile(tfiles[0]);

                            if (
                              tfiles[0]?.type &&
                              tfiles[0].type.startsWith('image/')
                            ) {
                              const res = await upload_onboard_image(
                                file,
                                `${timestamp}_${fileName}`,
                                {},
                                tfiles[0].type,
                              );

                              setFieldValue('menu_image', [
                                ...values.menu_image,
                                res.Location,
                              ]);
                            } else {
                              const res = await upload_onboard_file(
                                file,
                                `${timestamp}_${fileName}`,
                                {},
                                tfiles[0].type,
                              );

                              setFieldValue('menu_document_url', [
                                ...values.menu_document_url,
                                res.Location,
                              ]);
                            }
                          }

                          setPdfFileLoading(false);
                        }}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        <span className="file-label"> Choose a file… </span>
                      </span>
                      {Array.isArray(Array.from(pdfFile)) &&
                        Array.from(pdfFile).length !== 0 && (
                          <>
                            <span
                              title={Array.from(pdfFile)
                                .map(ele => ele.name)
                                .join(', ')}
                              className="file-name"
                            >
                              {Array.from(pdfFile)
                                .map(ele => ele.name)
                                .join(', ')}
                            </span>
                          </>
                        )}
                      {pdfFileLoading && (
                        <progress
                          className="progress is-small is-primary"
                          max="100"
                        >
                          15%
                        </progress>
                      )}
                    </label>
                  </div>
                  {Array.isArray(Array.from(pdfFile)) &&
                    Array.from(pdfFile).length !== 0 && (
                      <>
                        <div />
                        <div>
                          {Array.from(pdfFile).map(ele => (
                            <p
                              style={{
                                color: '#00b28c',
                                wordWrap: 'break-word',
                                margin: '10px 0',
                              }}
                            >
                              {ele.name}
                            </p>
                          ))}
                        </div>
                      </>
                    )}
                  <div />
                  {values.documentsUploaded &&
                    Array.isArray(values.documentsUploaded) &&
                    values.documentsUploaded.length !== 0 && (
                      <>
                        <Label> Uploaded Documents </Label>
                        <div />
                        {values.documentsUploaded &&
                        Array.isArray(values.documentsUploaded) &&
                        values.documentsUploaded.length !== 0 ? (
                          <Table>
                            <tbody>
                              {values.documentsUploaded.map(ele => (
                                <tr
                                  style={{
                                    cursor: 'pointer',
                                    color: '#00AEEF',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <a
                                    href={ele}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {ele}
                                  </a>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <span style={{ color: '#F36E21' }}>
                            Not uploaded!
                          </span>
                        )}
                      </>
                    )}
                </>
              )}
            </GridContainer>
            <Label>Online Ordering</Label>
            <GridContainer>
              {values?.kravein_onboarding_fee &&
                Array.isArray(values.kravein_onboarding_fee) &&
                values.kravein_onboarding_fee.map(ele => (
                  <div className="is-flex">
                    <CheckBox2
                      label={ele.label}
                      value={ele.is_selected}
                      onChange={value => {
                        const temp = values.kravein_onboarding_fee.map(ele2 => {
                          if (ele2.label === ele.label) {
                            return {
                              ...ele2,
                              is_selected: value,
                            };
                          }
                          return { ...ele2 };
                        });
                        setFieldValue('kravein_onboarding_fee', temp);
                      }}
                    />
                    <div style={{ width: '120px' }}>
                      {ele.fee_type === 'PRICE' ? (
                        <InputAddOn
                          addonsText="$"
                          isLeft
                          disabled={!ele.is_selected}
                          value={ele.input_value}
                          onChange={e => {
                            if (e.target.value > 999.99) {
                              alert(
                                'The input value must be less than 999.99!',
                              );
                              return;
                            }

                            if (e.target.value < 0) {
                              alert(
                                'The input value must be greater than or equal to 0!',
                              );
                              return;
                            }

                            const temp = values.kravein_onboarding_fee.map(
                              ele2 => {
                                if (ele2.label === ele.label) {
                                  return {
                                    ...ele2,
                                    input_value: e.target.value,
                                  };
                                }
                                return { ...ele2 };
                              },
                            );
                            setFieldValue('kravein_onboarding_fee', temp);
                          }}
                          disableToastMessage
                          type="number"
                          min="1"
                          max="1000"
                        />
                      ) : (
                        <InputAddOn
                          addonsText="%"
                          isRight
                          disabled={!ele.is_selected}
                          value={ele.input_value}
                          onChange={e => {
                            if (e.target.value > 999.99) {
                              alert(
                                'The input value must be less than 999.99!',
                              );
                              return;
                            }

                            if (e.target.value < 0) {
                              alert(
                                'The input value must be greater than or equal to 0!',
                              );
                              return;
                            }

                            const temp = values.kravein_onboarding_fee.map(
                              ele2 => {
                                if (ele2.label === ele.label) {
                                  return {
                                    ...ele2,
                                    input_value: e.target.value,
                                  };
                                }
                                return { ...ele2 };
                              },
                            );
                            setFieldValue('kravein_onboarding_fee', temp);
                          }}
                          disableToastMessage
                          type="number"
                          min="1"
                          max="1000"
                        />
                      )}
                    </div>
                    {parseFloat(ele.setup_value) !==
                      parseFloat(ele.input_value) && (
                      <span
                        style={{
                          fontSize: '10px',
                          color: 'red',
                          padding: '12px',
                        }}
                      >
                        Price Overridden
                      </span>
                    )}
                  </div>
                ))}
            </GridContainer>
            <Label className="is-flex">Catering Informations</Label>
            <GridContainer2>
              <div />
              <Label className="is-flex">Lead Type</Label>
              <Radio
                label="Days"
                value={values.lead_catering_type === 'DAYS'}
                onChange={v => {
                  setFieldValue('catering_lead_time', '');
                  setFieldValue('catering_cut_off_time', '');

                  if (v) {
                    setFieldValue('lead_catering_type', 'DAYS');
                  } else {
                    setFieldValue('lead_catering_type', '');
                  }
                }}
                disabled={!shldHaveLeadTimeFunc}
                preventDefault
              />
              <Radio
                label="Hours"
                value={values.lead_catering_type === 'HOURS'}
                onChange={v => {
                  setFieldValue('catering_lead_time', '');
                  setFieldValue('catering_cut_off_time', '');

                  if (v) {
                    setFieldValue('lead_catering_type', 'HOURS');
                  } else {
                    setFieldValue('lead_catering_type', '');
                  }
                }}
                disabled={!shldHaveLeadTimeFunc}
                preventDefault
              />
              <div />
              <div />
              <Label className="is-flex">Lead Time</Label>
              <InputAddOn
                type="number"
                min="0"
                step="1"
                onChange={e => {
                  if (e.target.value > 999) {
                    alert('The input value must be less than 999!');
                    return;
                  }

                  if (e.target.value < 0) {
                    alert(
                      'The input value must be greater than or equal to 0!',
                    );
                    return;
                  }

                  if (/^\d*$/.test(e.target.value)) {
                    setFieldValue('catering_lead_time', e.target.value);
                  }
                }}
                value={values.catering_lead_time}
                disableToastMessage
                errors={errors.catering_lead_time}
                touched={touched.catering_lead_time}
                required
                disabled={values.lead_catering_type === ''}
              />
              <Label className="is-flex">Cut Off Time</Label>
              <div>
                <SelectTime
                  value={values.catering_cut_off_time}
                  onChange={e => setFieldValue('catering_cut_off_time', e.time)}
                  disabled={
                    values.lead_catering_type === 'HOURS' ||
                    values.lead_catering_type === ''
                  }
                />
                <InputErrorMessage
                  errors={errors.catering_cut_off_time}
                  touched={touched.catering_cut_off_time}
                  disableToastMessage
                />
              </div>
              <div />
            </GridContainer2>
            <div className="is-flex" style={{ margin: '1rem 0' }}>
              <Label className="mr-6">Online Table Reservation</Label>
              <CheckBox
                label="Table Booking"
                name="onboard_table_booking"
                value={values.onboard_table_booking}
                onChange={value => {
                  setFieldValue('onboard_table_booking', value);

                  if (value) {
                    if (Array.isArray(values.standard_subscriptions)) {
                      setFieldValue('table_reservation', 'STANDARD');
                      setFieldValue(
                        'selectedSubscription',
                        values.standard_subscriptions[0].name,
                      );
                    } else if (Array.isArray(values.premium_subscriptions)) {
                      setFieldValue('table_reservation', 'PREMIUM');
                      setFieldValue(
                        'selectedSubscription',
                        values.premium_subscriptions[0].name,
                      );
                    }
                  } else {
                    setFieldValue('table_reservation', '');
                    setFieldValue('selectedSubscription', '');
                  }
                }}
              />
            </div>
            <Radio
              label="STANDARD TABLE RESERVATION"
              name="table_reservation"
              value={values.table_reservation === 'STANDARD'}
              onChange={() => {
                setFieldValue('table_reservation', 'STANDARD');

                if (Array.isArray(values.standard_subscriptions)) {
                  setFieldValue(
                    'selectedSubscription',
                    values.standard_subscriptions[0].name,
                  );
                }
              }}
              disabled={!values.onboard_table_booking}
            />
            <span
              className="is-size-4"
              style={{
                color: '#F36E21',
                position: 'relative',
                top: '-10px',
                left: '22px',
              }}
            >
              With no Booking Deposits or Fee
            </span>
            <GridContainer>
              {values?.standard_subscriptions &&
                Array.isArray(values.standard_subscriptions) &&
                values.standard_subscriptions.map(ele => (
                  <div className="is-flex">
                    <CheckBox3
                      label={ele.label}
                      value={values.selectedSubscription === ele.name}
                      onChange={() => {
                        setFieldValue('selectedSubscription', ele.name);
                      }}
                      disabled={
                        values.table_reservation === 'PREMIUM' ||
                        !values.onboard_table_booking
                      }
                    />
                    <div style={{ width: '120px' }}>
                      {ele.fee_type === 'PRICE' ? (
                        <InputAddOn
                          addonsText="$"
                          isLeft
                          disabled={
                            values.table_reservation === 'PREMIUM' ||
                            values.selectedSubscription !== ele.name ||
                            !values.onboard_table_booking
                          }
                          value={ele.input_value}
                          onChange={e => {
                            if (e.target.value > 999.99) {
                              alert(
                                'The input value must be less than 999.99!',
                              );
                              return;
                            }

                            if (e.target.value < 0) {
                              alert(
                                'The input value must be greater than or equal to 0!',
                              );
                              return;
                            }

                            const temp = values.standard_subscriptions.map(
                              ele2 => {
                                if (ele2.label === ele.label) {
                                  return {
                                    ...ele2,
                                    input_value: e.target.value,
                                  };
                                }
                                return { ...ele2 };
                              },
                            );
                            setFieldValue('standard_subscriptions', temp);
                          }}
                          disableToastMessage
                          type="number"
                          min="1"
                          max="1000"
                        />
                      ) : (
                        <InputAddOn
                          addonsText="%"
                          isRight
                          disabled={
                            values.table_reservation === 'PREMIUM' ||
                            values.selectedSubscription !== ele.name ||
                            !values.onboard_table_booking
                          }
                          value={ele.input_value}
                          onChange={e => {
                            if (e.target.value > 999.99) {
                              alert(
                                'The input value must be less than 999.99!',
                              );
                              return;
                            }

                            if (e.target.value < 0) {
                              alert(
                                'The input value must be greater than or equal to 0!',
                              );
                              return;
                            }

                            const temp = values.standard_subscriptions.map(
                              ele2 => {
                                if (ele2.label === ele.label) {
                                  return {
                                    ...ele2,
                                    input_value: e.target.value,
                                  };
                                }
                                return { ...ele2 };
                              },
                            );
                            setFieldValue('standard_subscriptions', temp);
                          }}
                          disableToastMessage
                          type="number"
                          min="1"
                          max="1000"
                        />
                      )}
                    </div>
                    {parseFloat(ele.setup_value) !==
                      parseFloat(ele.input_value) && (
                      <span
                        style={{
                          fontSize: '10px',
                          color: 'red',
                          padding: '12px',
                        }}
                      >
                        Price Overridden
                      </span>
                    )}
                  </div>
                ))}
            </GridContainer>
            <Radio
              label="PREMIUM TABLE RESERVATION"
              name="table_reservation"
              value={values.table_reservation === 'PREMIUM'}
              onChange={() => {
                setFieldValue('table_reservation', 'PREMIUM');

                if (Array.isArray(values.premium_subscriptions)) {
                  setFieldValue(
                    'selectedSubscription',
                    values.premium_subscriptions[0].name,
                  );
                }
              }}
              disabled={!values.onboard_table_booking}
            />
            <span
              className="is-size-4"
              style={{
                color: '#F36E21',
                position: 'relative',
                top: '-10px',
                left: '22px',
              }}
            >
              Premium is automated with Booking Deposits or Fee
            </span>
            <GridContainer>
              {values?.premium_subscriptions &&
                Array.isArray(values.premium_subscriptions) &&
                values.premium_subscriptions.map(ele => (
                  <div className="is-flex">
                    <CheckBox3
                      disabled={
                        values.table_reservation === 'STANDARD' ||
                        !values.onboard_table_booking
                      }
                      label={ele.label}
                      value={values.selectedSubscription === ele.name}
                      onChange={() => {
                        setFieldValue('selectedSubscription', ele.name);
                      }}
                    />
                    <div style={{ width: '120px' }}>
                      {ele.fee_type === 'PRICE' ? (
                        <InputAddOn
                          disabled={
                            values.table_reservation === 'STANDARD' ||
                            values.selectedSubscription !== ele.name ||
                            !values.onboard_table_booking
                          }
                          addonsText="$"
                          isLeft
                          value={ele.input_value}
                          onChange={e => {
                            if (e.target.value > 999.99) {
                              alert(
                                'The input value must be less than 999.99!',
                              );
                              return;
                            }

                            if (e.target.value < 0) {
                              alert(
                                'The input value must be greater than or equal to 0!',
                              );
                              return;
                            }

                            const temp = values.premium_subscriptions.map(
                              ele2 => {
                                if (ele2.label === ele.label) {
                                  return {
                                    ...ele2,
                                    input_value: e.target.value,
                                  };
                                }
                                return { ...ele2 };
                              },
                            );
                            setFieldValue('premium_subscriptions', temp);
                          }}
                          disableToastMessage
                          type="number"
                          min="1"
                          max="1000"
                        />
                      ) : (
                        <InputAddOn
                          disabled={
                            values.table_reservation === 'STANDARD' ||
                            values.selectedSubscription !== ele.name ||
                            !values.onboard_table_booking
                          }
                          addonsText="%"
                          isRight
                          value={ele.input_value}
                          onChange={e => {
                            if (e.target.value > 999.99) {
                              alert(
                                'The input value must be less than 999.99!',
                              );
                              return;
                            }

                            if (e.target.value < 0) {
                              alert(
                                'The input value must be greater than or equal to 0!',
                              );
                              return;
                            }

                            const temp = values.premium_subscriptions.map(
                              ele2 => {
                                if (ele2.label === ele.label) {
                                  return {
                                    ...ele2,
                                    input_value: e.target.value,
                                  };
                                }
                                return { ...ele2 };
                              },
                            );
                            setFieldValue('premium_subscriptions', temp);
                          }}
                          disableToastMessage
                          type="number"
                          min="1"
                          max="1000"
                        />
                      )}
                    </div>
                    {parseFloat(ele.setup_value) !==
                      parseFloat(ele.input_value) && (
                      <span
                        style={{
                          fontSize: '10px',
                          color: 'red',
                          padding: '12px',
                        }}
                      >
                        Price Overridden
                      </span>
                    )}
                  </div>
                ))}
            </GridContainer>
            <span
              className="is-size-4"
              style={{
                color: '#F36E21',
                position: 'relative',
                top: '-40px',
                left: '22px',
              }}
            >
              Fee will be Percentage of the Booking fee or deposit charge
            </span>
            <Label>Comments or additional Requirements</Label>
            <TextArea
              name="comments"
              value={values.comments}
              onChange={handleChange}
              onBlur={handleBlur}
              // optionalText=""
              errors={errors.comments}
              touched={touched.comments}
              disableToastMessage
            />
            {values.comments && (
              <div style={{ textAlign: 'right' }}>
                ( Min 5 and Max 500 Characters ) <br />
                {values.comments.length > 500 || values.comments.length < 5
                  ? ''
                  : `Characters left ${500 - values.comments.length}`}
              </div>
            )}
          </>
        )}
        {activeTab === 2 && (
          <GridContainer>
            <TagInputs
              label="Business Type"
              optionalText="(Upto 2 Business Types)"
              id="PlaceManagement_AddEditPlace_BusinessType"
              value={parseData(values.business_type)}
              suggestions={
                values.business_type.length === 2
                  ? []
                  : parseData(data.businessTypes)
              }
              onChange={value =>
                setFieldValue('business_type', parseInput(value))
              }
              required
            />
            <TagInputs
              label="Service Category"
              id="PlaceManagement_AddEditPlace_ServiceCategory"
              suggestions={parseData(data.serviceCategories)}
              value={parseData(values.service_category)}
              onChange={value =>
                setFieldValue('service_category', parseInput(value))
              }
              required
            />
            <TagInputs
              label="Place Features"
              id="PlaceManagement_AddEditPlace_PlaceFeatures"
              suggestions={parseData(data.features)}
              value={parseData(values.feature)}
              onChange={value => setFieldValue('feature', parseInput(value))}
            />
            <TagInputs
              label="Cuisine Style"
              id="PlaceManagement_AddEditPlace_CuisineStyle"
              suggestions={parseData(data.cuisines)}
              value={parseData(values.cuisine_style)}
              onChange={value =>
                setFieldValue('cuisine_style', parseInput(value))
              }
              required
            />
            <TagInputs
              label="Good For (Known For)"
              id="PlaceManagement_AddEditPlace_GoodFor"
              suggestions={parseData(data.goodFor)}
              value={parseData(values.good_for)}
              onChange={value => setFieldValue('good_for', parseInput(value))}
            />
            <TagInputs
              label="Dining Style"
              id="PlaceManagement_AddEditPlace_DiningStyle"
              suggestions={parseData(data.diningStyle)}
              value={parseData(values.dining_style)}
              onChange={value =>
                setFieldValue('dining_style', parseInput(value))
              }
            />
          </GridContainer>
        )}
        {activeTab === 3 && (
          <>
            <div>
              <GridContainer>
                <div>
                  <Label>Menu Item Photos</Label>
                  <span className="is-size-4" style={{ color: '#F36E21' }}>
                    Enter the URLs of google drive or other urls to copy Menu
                    Items Photos
                  </span>
                  <Input
                    name="menu_item_photos"
                    value={values.menu_item_photos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disableToastMessage
                  />
                </div>
                <div />
              </GridContainer>
            </div>

            <div>
              <Label>Upload Venue Photos</Label>
              <div className="file has-name is-boxed is-primary">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="upload-menu-item-photos"
                    accept="image/*"
                    // multiple
                    onChange={async event => {
                      setMenuImageFilesLoading(true);

                      const tfiles = event.target.files;

                      if (tfiles.length > 1) {
                        alert('Please select one document at a time!');
                        return;
                      }

                      if (tfiles && tfiles[0] && tfiles[0]?.name) {
                        console.log('uploaded files...', tfiles);

                        setMenuImageFiles([...menuImageFiles, tfiles[0]]);

                        const timestamp = new Date().getTime();

                        const fileName = tfiles[0]?.name;

                        const fileContent = await readFile(tfiles[0]);

                        const res = await upload_onboard_image(
                          fileContent,
                          `${timestamp}_${fileName}`,
                          {},
                          tfiles[0].type,
                        );

                        setFieldValue('venue_photos', [
                          ...values.venue_photos,
                          res.Location,
                        ]);
                      }

                      setMenuImageFilesLoading(false);
                    }}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                    <span className="file-label"> Choose a file… </span>
                  </span>
                  {Array.isArray(Array.from(menuImageFiles)) &&
                    Array.from(menuImageFiles).length !== 0 && (
                      <span
                        title={Array.from(menuImageFiles)
                          .map(ele => ele.name)
                          .join(', ')}
                        className="file-name"
                      >
                        {Array.from(menuImageFiles)
                          .map(ele => ele.name)
                          .join(', ')}
                      </span>
                    )}
                  {menuImageFilesLoading && (
                    <progress
                      className="progress is-small is-primary"
                      max="100"
                    >
                      15%
                    </progress>
                  )}
                </label>
              </div>
            </div>
            {Array.isArray(Array.from(menuImageFiles)) &&
              Array.from(menuImageFiles).length !== 0 && (
                <>
                  <div />
                  <div>
                    {Array.from(menuImageFiles).map(ele => (
                      <p
                        style={{
                          color: '#00b28c',
                          wordWrap: 'break-word',
                          margin: '10px 0',
                        }}
                      >
                        {ele.name}
                      </p>
                    ))}
                  </div>
                </>
              )}
            {props?.onboardingPlaceData?.venue_photos &&
              Array.isArray(props?.onboardingPlaceData.venue_photos) &&
              props?.onboardingPlaceData.venue_photos.length !== 0 && (
                <>
                  <Label> Uploaded Documents </Label>
                  <div />
                  {props?.onboardingPlaceData?.venue_photos &&
                  Array.isArray(props?.onboardingPlaceData.venue_photos) &&
                  props?.onboardingPlaceData.venue_photos.length !== 0 ? (
                    <Table>
                      <tbody>
                        {props?.onboardingPlaceData.venue_photos.map(ele => (
                          <tr
                            style={{
                              cursor: 'pointer',
                              color: '#00AEEF',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <a href={ele} target="_blank" rel="noreferrer">
                              {ele}
                            </a>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <span style={{ color: '#F36E21' }}>Not uploaded!</span>
                  )}
                </>
              )}
          </>
        )}
        {/* {activeTab === 4 && (
          <>
            <Label> Menu Document URL </Label>
            {props?.onboardingPlaceData.documentsUploaded &&
            Array.isArray(values.documentsUploaded) &&
            values.documentsUploaded.length !== 0 ? (
              <Table>
                <tbody>
                  {props?.onboardingPlaceData?.menu_document_url.map(ele => (
                    <tr
                      style={{
                        cursor: 'pointer',
                        color: '#00AEEF',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <a href={ele} target="_blank" rel="noreferrer">
                        {ele}
                      </a>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <span style={{ color: '#F36E21' }}>Not uploaded!</span>
            )}

            <Label> Menu Image </Label>
            {props?.onboardingPlaceData?.menu_item_photos &&
            Array.isArray(props?.onboardingPlaceData?.menu_item_photos) &&
            props?.onboardingPlaceData?.menu_item_photos.length !== 0 ? (
              <Table>
                <tbody>
                  {props?.onboardingPlaceData?.menu_item_photos.map(ele => (
                    <tr
                      style={{
                        cursor: 'pointer',
                        color: '#00AEEF',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <a href={ele} target="_blank" rel="noreferrer">
                        {ele}
                      </a>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <span style={{ color: '#F36E21' }}>Not uploaded!</span>
            )}
            <Label> Venue Photos </Label>
            {props?.onboardingPlaceData?.venue_photos &&
            Array.isArray(props?.onboardingPlaceData?.venue_photos) &&
            props?.onboardingPlaceData?.venue_photos.length !== 0 ? (
              <Table>
                <tbody>
                  {props?.onboardingPlaceData?.venue_photos.map(ele => (
                    <tr
                      style={{
                        cursor: 'pointer',
                        color: '#00AEEF',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <a href={ele} target="_blank" rel="noreferrer">
                        {ele}
                      </a>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <span style={{ color: '#F36E21' }}>Not uploaded!</span>
            )}
          </>
        )} */}
      </Container>
      <Footer
        formStatus={values.status}
        loading={isSubmitting}
        handleSubmit={handleSubmit}
        place={{
          name: place.name,
          state: place.state,
          city: place.city,
          post_code: place.post_code,
          claim_status: place.claim_status,
          status: place.status,
        }}
        history={history}
        activeTab={activeTab}
        next={next}
        prev={prev}
        dirty
        saveDraft={values.status === 'DRAFT'}
      />
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: ({
    place,
    onboardingSetupData,
    onboardingPlaceData,
    user,
  }) => {
    const businessType = (() => {
      if (
        onboardingPlaceData &&
        onboardingPlaceData.business_type &&
        Array.isArray(onboardingPlaceData.business_type)
      ) {
        return removeTypename(onboardingPlaceData.business_type);
      }

      if (place && place.business_type && Array.isArray(place.business_type)) {
        return removeTypename(place.business_type);
      }

      return [];
    })();

    const serviceType = (() => {
      if (
        onboardingPlaceData &&
        onboardingPlaceData.service_category &&
        Array.isArray(onboardingPlaceData.service_category)
      ) {
        return removeTypename(onboardingPlaceData.service_category);
      }

      if (
        place &&
        place.service_category &&
        Array.isArray(place.service_category)
      ) {
        return removeTypename(place.service_category);
      }

      return [];
    })();

    const feature = (() => {
      if (
        onboardingPlaceData &&
        onboardingPlaceData.feature &&
        Array.isArray(onboardingPlaceData.feature)
      ) {
        return removeTypename(onboardingPlaceData.feature);
      }

      if (place && place.feature && Array.isArray(place.feature)) {
        return removeTypename(place.feature);
      }

      return [];
    })();

    const cuisineStyle = (() => {
      if (
        onboardingPlaceData &&
        onboardingPlaceData.cuisine_style &&
        Array.isArray(onboardingPlaceData.cuisine_style)
      ) {
        return removeTypename(onboardingPlaceData.cuisine_style);
      }

      if (place && place.cuisine_style && Array.isArray(place.cuisine_style)) {
        return removeTypename(place.cuisine_style);
      }

      return [];
    })();

    const goodFor = (() => {
      if (
        onboardingPlaceData &&
        onboardingPlaceData.good_about_place &&
        Array.isArray(onboardingPlaceData.good_about_place)
      ) {
        return removeTypename(onboardingPlaceData.good_about_place);
      }

      if (place && place.good_for && Array.isArray(place.good_for)) {
        return removeTypename(place.good_for);
      }

      return [];
    })();

    const diningStyle = (() => {
      if (
        onboardingPlaceData &&
        onboardingPlaceData.dining_style &&
        Array.isArray(onboardingPlaceData.dining_style)
      ) {
        return removeTypename(onboardingPlaceData.dining_style);
      }

      if (place && place.dining_style && Array.isArray(place.dining_style)) {
        return removeTypename(place.dining_style);
      }

      return [];
    })();

    const website0 =
      onboardingPlaceData?.menu_suggestion_websites &&
      Array.isArray(onboardingPlaceData.menu_suggestion_websites) &&
      onboardingPlaceData.menu_suggestion_websites[0]
        ? onboardingPlaceData.menu_suggestion_websites[0]
        : '';

    const website1 =
      onboardingPlaceData?.other_menu_suggestion_websites &&
      Array.isArray(onboardingPlaceData.other_menu_suggestion_websites) &&
      onboardingPlaceData.other_menu_suggestion_websites[0]
        ? onboardingPlaceData.other_menu_suggestion_websites[0]
        : '';

    const website2 =
      onboardingPlaceData?.other_menu_suggestion_websites &&
      Array.isArray(onboardingPlaceData.other_menu_suggestion_websites) &&
      onboardingPlaceData.other_menu_suggestion_websites[1]
        ? onboardingPlaceData.other_menu_suggestion_websites[1]
        : '';

    const website3 =
      onboardingPlaceData?.other_menu_suggestion_websites &&
      Array.isArray(onboardingPlaceData.other_menu_suggestion_websites) &&
      onboardingPlaceData.other_menu_suggestion_websites[2]
        ? onboardingPlaceData.other_menu_suggestion_websites[2]
        : '';

    const phoneNumber = (() => {
      if (onboardingPlaceData?.phone_number) {
        return onboardingPlaceData?.phone_number;
      }

      return place.contact &&
        Array.isArray(place.contact) &&
        place.contact.find(item => item.type === 'phone_primary')
        ? place.contact.find(item => item.type === 'phone_primary').value
        : '';
    })();

    const email = (() => {
      if (onboardingPlaceData?.email) {
        return onboardingPlaceData?.email;
      }

      return place.contact &&
        Array.isArray(place.contact) &&
        place.contact.find(item => item.type === 'email_primary')
        ? place.contact.find(item => item.type === 'email_primary').value
        : '';
    })();

    const salesRepEmail = (() => {
      if (onboardingPlaceData?.sales_rep_email) {
        return onboardingPlaceData?.sales_rep_email;
      }

      return user ? user.email : '';
    })();

    const salesRepName = (() => {
      if (onboardingPlaceData?.sales_rep_name) {
        return onboardingPlaceData?.sales_rep_name;
      }

      return user ? user.display_name : '';
    })();

    console.log('subscriptions...', onboardingPlaceData?.subscriptions);

    console.log(
      'kravein_onboarding_fee...',
      onboardingPlaceData?.kravein_onboarding_fee,
    );

    const subscriptions = { ...onboardingSetupData?.subscriptions };
    const fees = { ...onboardingSetupData?.fees };

    let selectedSubscription = null;

    if (
      onboardingSetupData?.subscriptions &&
      onboardingPlaceData?.subscriptions &&
      Array.isArray(onboardingPlaceData?.subscriptions)
    ) {
      onboardingPlaceData?.subscriptions.forEach(ele => {
        if (subscriptions[ele.name]) {
          selectedSubscription = ele.name;
          subscriptions[ele.name].value = ele.value;
          subscriptions[ele.name].input_value = ele.value;
          subscriptions[ele.name].is_selected = ele.name;
          subscriptions[ele.name].tValue = transformValue(
            ele.value,
            ele.fee_type,
          );
        }
      });
    }

    // if (!selectedSubscription) {
    //   const temp = Object.values(onboardingSetupData?.subscriptions).filter(
    //     ele => ele.type === 'STANDARD',
    //   );
    //   selectedSubscription =
    //     Array.isArray(temp) && temp[0] ? temp[0].name : null;
    // }

    if (
      onboardingSetupData?.fees &&
      onboardingPlaceData?.kravein_onboarding_fee &&
      Array.isArray(onboardingPlaceData?.kravein_onboarding_fee)
    ) {
      onboardingPlaceData?.kravein_onboarding_fee.forEach(ele => {
        if (fees[ele.name]) {
          fees[ele.name].value = ele.value;
          fees[ele.name].input_value = ele.value;
          fees[ele.name].is_selected = true;
          fees[ele.name].tValue = transformValue(ele.value, ele.fee_type);
        }
      });
    }

    let documentsUploaded = [];

    if (
      onboardingPlaceData?.menu_document_url &&
      Array.isArray(onboardingPlaceData?.menu_document_url)
    ) {
      documentsUploaded = [...onboardingPlaceData?.menu_document_url];
    }

    if (
      onboardingPlaceData?.menu_image &&
      Array.isArray(onboardingPlaceData?.menu_image)
    ) {
      documentsUploaded = [
        ...documentsUploaded,
        ...onboardingPlaceData?.menu_image,
      ];
    }

    console.log('subscriptions...', subscriptions);

    console.log('fees...', fees);

    console.log('documentsUploaded...', documentsUploaded);

    return {
      documentsUploaded,
      lead_catering_type: onboardingPlaceData?.lead_catering_type || '',
      catering_lead_time: onboardingPlaceData?.catering_lead_time || '',
      catering_cut_off_time: onboardingPlaceData?.catering_cut_off_time || '',
      bank_id: !!onboardingPlaceData?.place_bank_detail_id || false,
      enter_bank_details: false,
      is_draft: true,
      venue_go_live_date: onboardingPlaceData?.venue_go_live_date
        ? parseDateString(onboardingPlaceData?.venue_go_live_date)
        : '',
      owner_info_name: onboardingPlaceData?.owner_info?.name || '',
      owner_info_email: onboardingPlaceData?.owner_info?.email || '',
      owner_info_phone_number:
        onboardingPlaceData?.owner_info?.phone_number || '',
      phone_number: phoneNumber,
      sales_rep_email: salesRepEmail,
      sales_rep_name: salesRepName,
      website: onboardingPlaceData?.website || '',
      email,
      host_website_by_kravein:
        onboardingPlaceData?.host_website_by_kravein || false,
      pos_name: onboardingPlaceData?.pos_name || '',
      online_aggregator: onboardingPlaceData?.online_aggregator || '',
      is_venue_to_kravein_redirection:
        onboardingPlaceData?.is_venue_to_kravein_redirection || false,
      status: onboardingPlaceData?.form_status || 'DRAFT',
      sales_type: onboardingPlaceData?.sales_type || 'LEAD',
      business_type: businessType,
      service_category: serviceType,
      feature,
      cuisine_style: cuisineStyle,
      good_for: goodFor,
      dining_style: diningStyle,
      suggested_percentage: onboardingPlaceData?.suggested_percentage || '',
      discount_to_pickup: onboardingPlaceData?.discount_to_pickup || '',
      website1,
      website2,
      website3,
      comments: onboardingPlaceData?.comments || '',
      menu_onboarded: onboardingPlaceData?.menu_onboarded || false,
      fees_onboarded: onboardingPlaceData?.fees_onboarded || false,
      google_online_onboarded:
        onboardingPlaceData?.google_online_onboarded || false,
      ship_day_key_onboarded:
        onboardingPlaceData?.ship_day_key_onboarded || false,
      bank_account_onboarded:
        onboardingPlaceData?.bank_account_onboarded || false,
      stripe_key_onboarded: onboardingPlaceData?.stripe_key_onboarded || false,
      venue_domain_purchased:
        onboardingPlaceData?.venue_domain_purchased || false,
      venue_domain_hosted: onboardingPlaceData?.venue_domain_hosted || false,
      menu_document_url: [],
      venue_photos: [],
      menu_image: [],
      menu_item_photos: onboardingPlaceData?.menu_item_photos || '',
      table_reservation: onboardingPlaceData?.table_reservation || '',
      kravein_onboarding_fee: Object.values(onboardingSetupData?.fees),
      standard_subscriptions: Array.isArray(
        Object.values(onboardingSetupData?.subscriptions),
      )
        ? Object.values(onboardingSetupData?.subscriptions).filter(
            ele => ele.type === 'STANDARD',
          )
        : [],
      premium_subscriptions: Array.isArray(
        Object.values(onboardingSetupData?.subscriptions),
      )
        ? Object.values(onboardingSetupData?.subscriptions).filter(
            ele => ele.type === 'PREMIUM',
          )
        : [],

      selectedSubscription,
      website0,
      uber_menu: onboardingPlaceData?.uber_menu ? 'UBER' : 'VENUE',
      is_bank_details: !!onboardingPlaceData?.is_bank_details,
      onboard_table_booking:
        onboardingPlaceData?.table_reservation === 'STANDARD' ||
        onboardingPlaceData?.table_reservation === 'PREMIUM',
    };
  },

  validate: () => {},

  validationSchema: yup.object().shape({
    owner_info_phone_number: yup
      .string()
      .max(15, 'Please provide a valid phone number!'),
    phone_number: yup.string().max(15, 'Please provide a valid phone number!'),
    sales_rep_name: yup
      .string()
      .min(3, 'At least 3 Characters Required!')
      .max(75, 'Not greater than 75 Characters!'),
    sales_rep_email: yup.string().email('Please enter valid email address'),
    email: yup.string().email('Please enter valid email address'),
    owner_info_name: yup
      .string()
      .min(3, 'At least 3 Characters Required!')
      .max(75, 'Not greater than 75 Characters!'),
    owner_info_email: yup.string().email('Please enter valid email address'),
    pos_name: yup
      .string()
      .min(3, 'POS Name must be at least 3 characters')
      .max(75, 'POS Name must be less than 75 Characters!'),
    online_aggregator: yup
      .string()
      .min(3, 'Onlne Aggregator Name must be at least 3 characters')
      .max(75, 'Onlne Aggregator Name must be less than 75 Characters!'),
    comments: yup
      .string()
      .nullable('Please enter at least 5 characters')
      .min(5, 'Please enter at least 5 characters')
      .max(500, 'Please enter not greater than 500 characters')
      .test('no-only-spaces', 'Please enter a valid service message!', value =>
        /\S/.test(value),
      ),
  }),

  handleSubmit: async (values, { props }) => {
    const input = {
      form_status: values.status,
      onboarding_data_id: props?.onboardingPlaceData?.onboarding_data_id || '',
      user_id: props.userId,
      place_id: props.place.place_id,
      phone_number: values.phone_number,
      email: values.email,
      sales_type: values.sales_type,
      website: values.website,
      host_website_by_kravein: values.host_website_by_kravein,
      pos_name: values.pos_name,
      online_aggregator: values.online_aggregator,
      venue_go_live_date: values.venue_go_live_date
        ? formatDateObj(values.venue_go_live_date)
        : null,
      comments: values.comments,
      is_venue_to_kravein_redirection: values.is_venue_to_kravein_redirection,
      sales_rep_name: values.sales_rep_name,
      sales_rep_email: values.sales_rep_email,
      owner_info: {
        email: values.owner_info_email,
        name: values.owner_info_name,
        phone_number: values.owner_info_phone_number,
      },
      business_type: values.business_type,
      service_category: values.service_category,
      feature: values.feature,
      cuisine_style: values.cuisine_style,
      good_about_place: values.good_for,
      menu_suggestion_websites: [values.website0].filter(ele => !!ele),
      menu_document_url: Array.isArray(
        props?.onboardingPlaceData?.menu_document_url,
      )
        ? [
            ...props?.onboardingPlaceData?.menu_document_url,
            ...values.menu_document_url,
          ]
        : values.menu_document_url,
      venue_photos: Array.isArray(props?.onboardingPlaceData?.venue_photos)
        ? [...props?.onboardingPlaceData?.venue_photos, ...values.venue_photos]
        : values.venue_photos,
      menu_image: Array.isArray(props?.onboardingPlaceData?.menu_image)
        ? [...props?.onboardingPlaceData?.menu_image, ...values.menu_image]
        : values.menu_image,
      menu_item_photos: Array.isArray(
        props?.onboardingPlaceData?.menu_item_photos,
      )
        ? [
            ...props?.onboardingPlaceData?.menu_item_photos,
            ...values.menu_item_photos,
          ]
        : values.menu_item_photos,
      dining_style: values.dining_style,
      table_reservation: values.onboard_table_booking
        ? values.table_reservation
        : '',
      subscriptions:
        values.table_reservation === 'PREMIUM'
          ? values.premium_subscriptions
              .filter(
                ele =>
                  values.onboard_table_booking &&
                  ele.name === values.selectedSubscription,
              )
              .map(ele => ({
                fee_type: ele.fee_type,
                name: ele.name,
                value: ele.input_value || ele.value,
              }))
          : values.standard_subscriptions
              .filter(
                ele =>
                  values.onboard_table_booking &&
                  ele.name === values.selectedSubscription,
              )
              .map(ele => ({
                fee_type: ele.fee_type,
                name: ele.name,
                value: ele.input_value || ele.value,
              })),
      kravein_onboarding_fee: values.kravein_onboarding_fee
        .filter(ele => ele.is_selected)
        .map(ele => ({
          fee_type: ele.fee_type,
          name: ele.name,
          value: ele.input_value || ele.value,
        })),
      uber_menu: values.uber_menu === 'UBER',
      other_menu_suggestion_websites: [
        values.website1,
        values.website2,
        values.website3,
      ].filter(ele => !!ele),
      menu_onboarded: values?.menu_onboarded,
      fees_onboarded: values?.fees_onboarded,
      google_online_onboarded: values?.google_online_onboarded,
      ship_day_key_onboarded: values?.ship_day_key_onboarded,
      bank_account_onboarded: values?.bank_account_onboarded,
      stripe_key_onboarded: values?.stripe_key_onboarded,
      venue_domain_purchased: values?.venue_domain_purchased,
      venue_domain_hosted: values?.venue_domain_hosted,
    };

    const shldHaveLeadTimeFunc = () =>
      values.kravein_onboarding_fee &&
      Array.isArray(values.kravein_onboarding_fee) &&
      !!values.kravein_onboarding_fee.find(
        ele => ele.label.startsWith('Catering') && ele.is_selected,
      );

    if (shldHaveLeadTimeFunc()) {
      if (!values.lead_catering_type) {
        toast.error('Please select a lead type!');
        return;
      }
      if (!values.catering_lead_time) {
        toast.error('Please enter lead time!');
        return;
      }
      if (
        values.lead_catering_type === 'DAYS' &&
        !values.catering_cut_off_time
      ) {
        toast.error('Please select cut off time!');
        return;
      }
    }

    // if (values.lead_catering_type && !values.catering_lead_time) {
    //   toast.error('Please enter lead time!');
    //   return;
    // }

    // if (values.lead_catering_type === 'DAYS' && !values.catering_cut_off_time) {
    //   toast.error('Please select cut off time!');
    //   return;
    // }

    // if (values.status) {
    //   input.form_status = 'DRAFT';
    // } else {
    //   input.form_status = 'SUBMIT';
    // }

    if (values.catering_lead_time) {
      input.catering_lead_time = values.catering_lead_time;
    }

    if (values.catering_cut_off_time) {
      input.catering_cut_off_time = values.catering_cut_off_time;
    }

    if (values.lead_catering_type) {
      input.lead_catering_type = values.lead_catering_type;
    }

    if (values.suggested_percentage) {
      input.suggested_percentage = values.suggested_percentage;
    }

    if (values.discount_to_pickup) {
      input.discount_to_pickup = values.discount_to_pickup;
    }

    if (values.status === 'SUBMIT') {
      const validationSchema = yup.object().shape({
        owner_info_name: yup
          .string()
          .required('Restaurant Owner / Manager Name is required!')
          .min(
            3,
            'Restaurant Owner / Manager Name must be at least 3 characters',
          )
          .max(
            75,
            'Restaurant Owner / Manager Name must be less than 75 Characters!',
          ),
        owner_info_email: yup
          .string()
          .email('Restaurant Owner / Manager Email is invalid!')
          .required('Restaurant Owner / Manager Email is required!'),
        owner_info_phone_number: yup
          .string()
          .required('Restaurant Owner / Manager Phone number is required!'),
        phone_number: yup
          .string()
          .required('Restaurant Phone number is required!'),
        email: yup
          .string()
          .email('Email is invalid!')
          .required('Email is required!'),
        service_category: yup
          .array()
          .min(1, 'Service category must have at least one selection!'),
        business_type: yup
          .array()
          .min(1, 'Business type must have at least one selection!')
          .max(2, 'Business type must have only two selections!'),
        cuisine_style: yup
          .array()
          .min(1, 'Cuisine Style must have at least one selection!'),
        venue_go_live_date: yup
          .string()
          .required('Venue go live date is required!'),
        website: yup.string().required('Business Website is required!'),
      });

      try {
        await validationSchema.validate(values, { abortEarly: false });

        const res = await submitOnboardingData({
          input,
          isUpdate: props.isUpdate,
        });

        if (res.isError) {
          toast.error(res.error);
          return;
        }

        props.refetchOnboardingPlaceData();

        swal({
          title: 'Onboarding Data Submitted Successfully!',
          type: 'success',
        }).then(() => {
          props.history.goBack();
        });
      } catch (error) {
        const firstErrorMessage = error.errors[0];

        // const errorTabs = {
        //   'Restaurant Owner / Manager Name must be at least 3 characters': 0,
        //   'Restaurant Owner / Manager Name must be less than 75 Characters!': 0,
        //   'Restaurant Owner / Manager Name is required!': 0,
        //   'Restaurant Owner / Manager Email is invalid!': 0,
        //   'Restaurant Owner / Manager Email is required!': 0,
        //   'Restaurant Owner / Manager Phone number is required!': 0,
        //   'Restaurant Phone number is required!': 0,
        //   'Email is invalid!': 0,
        //   'Email is required!': 0,
        //   'Service category must have at least one selection!': 2,
        //   'Business type must have at least one selection!': 2,
        //   'Business type must have only two selections!': 2,
        //   'Cuisine Style must have at least one selection!': 2,
        //   'Venue go live date is required!': 1,
        //   'Business Website is required!': 0,
        // };

        toast.error(firstErrorMessage);
      }
    } else {
      const res = await submitOnboardingData({
        input,
        isUpdate: props.isUpdate,
      });

      if (res.isError) {
        toast.error(res.error);

        return;
      }

      props.refetchOnboardingPlaceData();

      swal({
        title: 'Onboarding Data Saved Successfully!',
        type: 'success',
      }).then(() => {
        props.history.goBack();
      });
    }
  },

  displayName: 'AddPlaceForm',
})(OnboardPlaceForm);

export default Form;
