import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import uuid from 'uuid';

import Toggle from './Toggle';
import { useDateSlots, useUpdateTableSlotsFunc } from './helpers';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 75vw;
  margin: 1rem;
  ${({ height }) => height && `height:${height};`}
`;

const GridItem = styled.div`
  padding: 20px;
  height: 80px;
  margin: 4px;
  ${({ noborder }) => !noborder && `border: 1px solid #ccc;`}
`;

const FlexContainer = styled.div`
  &&& {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }
`;

const Item = styled.div`
  text-align: center;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 0.3rem 1.5rem;
  margin: 0.5rem !important;
  width: 23%;
  :hover {
    background-color: ${({ active }) => (active ? '#F36E21' : '#e2e2e2')};
  }
  background-color: ${({ active }) => (active ? '#F36E21' : '#f6f6f6')};
  color: ${({ active }) => (active ? '#ffffff' : '#505050')};
`;

const LoadingText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #00aeef;
  font-size: 24px;
  padding: 1rem;
`;

const OpenCloseButton = ({ checked, onToggle }) => (
  <div style={{ fontSize: '12px', marginLeft: '60px' }}>
    <Toggle checked={checked} onToggle={onToggle} />
  </div>
);

const BookingSlots = ({ slot, bookingCount, guestCount }) => (
  <div>
    <p style={{ fontWeight: '600', fontSize: '12px' }}>{slot}</p>
    {bookingCount && guestCount && (
      <p style={{ fontSize: '12px' }}>
        Bookings: {bookingCount}, Guests: {guestCount}
      </p>
    )}
    {bookingCount && !guestCount && (
      <p style={{ fontSize: '12px' }}>Bookings: {bookingCount}, Guests: NA</p>
    )}
    {!bookingCount && guestCount && (
      <p style={{ fontSize: '12px' }}>Bookings: NA, Guests: {guestCount}</p>
    )}
    {!bookingCount && !guestCount && (
      <p style={{ fontSize: '12px' }}>No Bookings</p>
    )}
  </div>
);

const Cell = ({ slot, bookingCount, guestCount, isOccupied, onToggle }) => (
  <div className="is-flex is-justify-content-space-between">
    <BookingSlots
      slot={slot}
      bookingCount={bookingCount}
      guestCount={guestCount}
    />
    <OpenCloseButton checked={isOccupied} onToggle={onToggle} />
  </div>
);

const toLocaleString = dateString => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', { weekday: 'short' }).toUpperCase();
};

const formatDateProp = dateObj => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return dateObj.toLocaleDateString('en-US', options);
};

const isNonEmptyArray = o => o && Array.isArray(o) && o.length !== 0;

const calcPropsFromSlotsData = slotsData => {
  const totalBookingsFunc = type =>
    isNonEmptyArray(slotsData[type])
      ? slotsData[type].reduce(
          (p, c) =>
            typeof c?.stats?.totalBookings === 'number'
              ? p + c?.stats?.totalBookings
              : p,
          0,
        )
      : 0;

  const totalGuestsFunc = type =>
    isNonEmptyArray(slotsData[type])
      ? slotsData[type].reduce(
          (p, c) =>
            typeof c?.stats?.totalGuests === 'number'
              ? p + c?.stats?.totalGuests
              : p,
          0,
        )
      : 0;

  const isOccupiedFunc = type =>
    isNonEmptyArray(slotsData[type])
      ? slotsData[type].map(e => e.occupied).every(e => e === true)
      : false;

  return {
    dateTotalBookings: totalBookingsFunc('allSlots'),
    dateTotalGuests: totalGuestsFunc('allSlots'),
    dateIsOccupied: isOccupiedFunc('allSlots'),
    morningTotalBookings: totalBookingsFunc('morning'),
    morningTotalGuests: totalGuestsFunc('morning'),
    morningIsOccupied: isOccupiedFunc('morning'),
    afternoonTotalBookings: totalBookingsFunc('afternoon'),
    afternoonTotalGuests: totalGuestsFunc('afternoon'),
    afternoonIsOccupied: isOccupiedFunc('afternoon'),
    eveningTotalBookings: totalBookingsFunc('evening'),
    eveningTotalGuests: totalGuestsFunc('evening'),
    eveningIsOccupied: isOccupiedFunc('evening'),
    keyId: uuid(),
  };
};

const calculateActivateTab = (morningSlots, afternoonSlots, eveningSlots) => {
  let res = null;

  if (isNonEmptyArray(morningSlots)) {
    res = 'morning';
  } else if (isNonEmptyArray(afternoonSlots)) {
    res = 'afternoon';
  } else if (isNonEmptyArray(eveningSlots)) {
    res = 'evening';
  }

  return res;
};

const BookingSessions = ({
  dateFilter,
  placeId,
  userId,
  serviceTypeSettingId,
}) => {
  const [activeTab, setActiveTab] = React.useState();
  const [
    tableSlotsUpdating,
    refetchSlots,
    updateTableSlots,
  ] = useUpdateTableSlotsFunc({
    userId,
    serviceTypeSettingId,
    placeId,
  });
  const [slotsDataLoading, slotsData, setSlotsData] = useDateSlots({
    shldStart: !!serviceTypeSettingId,
    day: toLocaleString(dateFilter),
    date1: format(dateFilter, 'dd-MM-yyyy'),
    date: format(dateFilter, 'yyyy-MM-dd'),
    placeId,
    serviceTypeSettingId,
    userId,
    refetchSlots,
  });

  React.useEffect(() => {
    setActiveTab();
  }, [dateFilter, refetchSlots]);

  if (slotsDataLoading) {
    return <LoadingText>Loading Slots...</LoadingText>;
  }

  const {
    dateTotalBookings,
    dateTotalGuests,
    dateIsOccupied,
    morningTotalBookings,
    morningTotalGuests,
    morningIsOccupied,
    afternoonTotalBookings,
    afternoonTotalGuests,
    afternoonIsOccupied,
    eveningTotalBookings,
    eveningTotalGuests,
    eveningIsOccupied,
    keyId,
  } = calcPropsFromSlotsData(slotsData);

  console.log({
    slotsDataLoading,
    slotsData,
    dateTotalBookings,
    dateTotalGuests,
    dateIsOccupied,
    morningTotalBookings,
    morningTotalGuests,
    morningIsOccupied,
    afternoonTotalBookings,
    afternoonTotalGuests,
    afternoonIsOccupied,
    eveningTotalBookings,
    eveningTotalGuests,
    eveningIsOccupied,
  });

  const activeTabCalc =
    activeTab ||
    calculateActivateTab(
      slotsData.morning,
      slotsData.afternoon,
      slotsData.evening,
    );

  if (!activeTabCalc) {
    return (
      <span style={{ fontSize: '14px', padding: '1rem' }}>
        No Slots Available!
      </span>
    );
  }

  return (
    <>
      <button
        className={`button is-primary is-rounded ${tableSlotsUpdating &&
          'is-loading'}`}
        type="button"
        style={{ position: 'absolute', right: '500px', top: '14px' }}
        onClick={() =>
          updateTableSlots({
            date: format(dateFilter, 'yyyy-MM-dd'),
            slots: isNonEmptyArray(slotsData.allSlots)
              ? slotsData.allSlots
                  .filter(ele => ele.occupied)
                  .map(ele => ele.time)
              : [],
          })
        }
        disabled={tableSlotsUpdating}
      >
        Submit Changes
      </button>
      <GridContainer height="15vh">
        <GridItem noborder>
          <Cell
            key={keyId}
            slot={formatDateProp(dateFilter)}
            bookingCount={dateTotalBookings === 0 ? null : dateTotalBookings}
            guestCount={dateTotalGuests === 0 ? null : dateTotalGuests}
            isOccupied={dateIsOccupied}
            onToggle={() => {
              setSlotsData({
                morning: isNonEmptyArray(slotsData.morning)
                  ? slotsData.morning.map(ele => ({
                      ...ele,
                      occupied: !dateIsOccupied,
                    }))
                  : [],
                afternoon: isNonEmptyArray(slotsData.afternoon)
                  ? slotsData.afternoon.map(ele => ({
                      ...ele,
                      occupied: !dateIsOccupied,
                    }))
                  : [],
                evening: isNonEmptyArray(slotsData.evening)
                  ? slotsData.evening.map(ele => ({
                      ...ele,
                      occupied: !dateIsOccupied,
                    }))
                  : [],
                allSlots: isNonEmptyArray(slotsData.allSlots)
                  ? slotsData.allSlots.map(ele => ({
                      ...ele,
                      occupied: !dateIsOccupied,
                    }))
                  : [],
              });
            }}
          />
        </GridItem>
      </GridContainer>
      <FlexContainer>
        <Item
          active={activeTabCalc === 'morning'}
          onClick={() => setActiveTab('morning')}
        >
          Morning
        </Item>
        <Item
          active={activeTabCalc === 'afternoon'}
          onClick={() => setActiveTab('afternoon')}
        >
          Afternoon
        </Item>
        <Item
          active={activeTabCalc === 'evening'}
          onClick={() => setActiveTab('evening')}
        >
          Evening
        </Item>
      </FlexContainer>
      {activeTabCalc === 'morning' && !isNonEmptyArray(slotsData.morning) && (
        <span
          style={{
            padding: '1rem',
            margin: '1rem',
            color: 'red',
            fontSize: '12px',
          }}
        >
          No Slots Available!
        </span>
      )}
      {activeTabCalc === 'afternoon' && !isNonEmptyArray(slotsData.afternoon) && (
        <span
          style={{
            padding: '1rem',
            margin: '1rem',
            color: 'red',
            fontSize: '12px',
          }}
        >
          No Slots Available!
        </span>
      )}
      {activeTabCalc === 'evening' && !isNonEmptyArray(slotsData.evening) && (
        <span
          style={{
            padding: '1rem',
            margin: '1rem',
            color: 'red',
            fontSize: '12px',
          }}
        >
          No Slots Available!
        </span>
      )}
      <GridContainer height="15vh">
        {activeTabCalc === 'morning' && isNonEmptyArray(slotsData.morning) && (
          <GridItem noborder>
            <Cell
              key={`Morning ${keyId}`}
              slot="Morning"
              bookingCount={
                morningTotalBookings === 0 ? null : morningTotalBookings
              }
              guestCount={morningTotalGuests === 0 ? null : morningTotalGuests}
              isOccupied={morningIsOccupied}
              onToggle={() => {
                setSlotsData({
                  morning: isNonEmptyArray(slotsData.morning)
                    ? slotsData.morning.map(ele => ({
                        ...ele,
                        occupied: !morningIsOccupied,
                      }))
                    : [],
                  afternoon: isNonEmptyArray(slotsData.afternoon)
                    ? [...slotsData.afternoon]
                    : [],
                  evening: isNonEmptyArray(slotsData.evening)
                    ? [...slotsData.evening]
                    : [],
                  allSlots: isNonEmptyArray(slotsData.allSlots)
                    ? slotsData.allSlots.map(ele1 => {
                        if (
                          isNonEmptyArray(slotsData.morning) &&
                          slotsData.morning.find(
                            ele2 => ele1.time === ele2.time,
                          )
                        ) {
                          return {
                            ...ele1,
                            occupied: !morningIsOccupied,
                          };
                        }
                        return ele1;
                      })
                    : [],
                });
              }}
            />
          </GridItem>
        )}
        {activeTabCalc === 'afternoon' && isNonEmptyArray(slotsData.afternoon) && (
          <GridItem noborder>
            <Cell
              key={`Afternoon ${keyId}`}
              slot="Afternoon"
              bookingCount={
                afternoonTotalBookings === 0 ? null : afternoonTotalBookings
              }
              guestCount={
                afternoonTotalGuests === 0 ? null : afternoonTotalGuests
              }
              isOccupied={afternoonIsOccupied}
              onToggle={() => {
                setSlotsData({
                  morning: isNonEmptyArray(slotsData.morning)
                    ? [...slotsData.morning]
                    : [],
                  afternoon: isNonEmptyArray(slotsData.afternoon)
                    ? slotsData.afternoon.map(ele => ({
                        ...ele,
                        occupied: !afternoonIsOccupied,
                      }))
                    : [],
                  evening: isNonEmptyArray(slotsData.evening)
                    ? [...slotsData.evening]
                    : [],
                  allSlots: isNonEmptyArray(slotsData.allSlots)
                    ? slotsData.allSlots.map(ele1 => {
                        if (
                          isNonEmptyArray(slotsData.afternoon) &&
                          slotsData.afternoon.find(
                            ele2 => ele1.time === ele2.time,
                          )
                        ) {
                          return {
                            ...ele1,
                            occupied: !afternoonIsOccupied,
                          };
                        }
                        return ele1;
                      })
                    : [],
                });
              }}
            />
          </GridItem>
        )}
        {activeTabCalc === 'evening' && isNonEmptyArray(slotsData.evening) && (
          <GridItem noborder>
            <Cell
              key={`Evening ${keyId}`}
              slot="Evening"
              bookingCount={
                eveningTotalBookings === 0 ? null : eveningTotalBookings
              }
              guestCount={eveningTotalGuests === 0 ? null : eveningTotalGuests}
              isOccupied={eveningIsOccupied}
              onToggle={() => {
                setSlotsData({
                  morning: isNonEmptyArray(slotsData.morning)
                    ? [...slotsData.morning]
                    : [],
                  afternoon: isNonEmptyArray(slotsData.afternoon)
                    ? [...slotsData.afternoon]
                    : [],
                  evening: isNonEmptyArray(slotsData.evening)
                    ? slotsData.evening.map(ele => ({
                        ...ele,
                        occupied: !eveningIsOccupied,
                      }))
                    : [],
                  allSlots: isNonEmptyArray(slotsData.allSlots)
                    ? slotsData.allSlots.map(ele1 => {
                        if (
                          isNonEmptyArray(slotsData.evening) &&
                          slotsData.evening.find(
                            ele2 => ele1.time === ele2.time,
                          )
                        ) {
                          return {
                            ...ele1,
                            occupied: !eveningIsOccupied,
                          };
                        }
                        return ele1;
                      })
                    : [],
                });
              }}
            />
          </GridItem>
        )}
      </GridContainer>
      <GridContainer>
        {isNonEmptyArray(slotsData[activeTabCalc]) ? (
          slotsData[activeTabCalc].map(ele => (
            <GridItem>
              <Cell
                key={`${ele.time} ${keyId}`}
                slot={ele.time}
                bookingCount={ele?.stats?.totalBookings || null}
                guestCount={ele?.stats?.totalGuests || null}
                isOccupied={ele.occupied}
                onToggle={() => {
                  setSlotsData({
                    morning: isNonEmptyArray(slotsData.morning)
                      ? slotsData.morning.map(ele2 => {
                          if (ele.time === ele2.time) {
                            return { ...ele2, occupied: !ele.occupied };
                          }
                          return ele2;
                        })
                      : [],
                    afternoon: isNonEmptyArray(slotsData.afternoon)
                      ? slotsData.afternoon.map(ele2 => {
                          if (ele.time === ele2.time) {
                            return { ...ele2, occupied: !ele.occupied };
                          }
                          return ele2;
                        })
                      : [],
                    evening: isNonEmptyArray(slotsData.evening)
                      ? slotsData.evening.map(ele2 => {
                          if (ele.time === ele2.time) {
                            return { ...ele2, occupied: !ele.occupied };
                          }
                          return ele2;
                        })
                      : [],
                    allSlots: isNonEmptyArray(slotsData.allSlots)
                      ? slotsData.allSlots.map(ele2 => {
                          if (ele.time === ele2.time) {
                            return { ...ele2, occupied: !ele.occupied };
                          }
                          return ele2;
                        })
                      : [],
                  });
                }}
              />
            </GridItem>
          ))
        ) : (
          <></>
        )}
      </GridContainer>
    </>
  );
};

export default BookingSessions;
