import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import PlaceSearch from './PlaceSearch';
import PlaceList from './PlaceList';
import client from '../../../utils/apolloClient';
import { Loading, Pagination, Table, Box } from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      total_pages
      total_size
      place_listing {
        company_id
        place_id
        display_order
        # is_open
        listing_status
        claim_status
        featured
        event_business
        item_review_place
        status
        approval_status
        voucher_count
        default_url
        price_range
        name
        trading_name
        tagline
        description
        slug
        # stars
        review_count
        like_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;
const listSize = 30;
const AddNewOnboarding = props => {
  const routeState = qs.parse(props.location.search);
  const { placeName, vendorName, page, city } = routeState;

  const placeNameFilter = placeName || '';
  const vendorNameFilter = vendorName || '';
  const cityFilter = city || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [claimStatusSort, setClaimStatusSort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');

  // for pagination
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  // for pagination
  const { userId } = useStoreState(state => state.auth);

  const input = {
    user_id: userId,
    sort,
    from: currentPage * listSize,
    size: listSize,
  };
  const place_filter = {};
  Object.assign(
    place_filter,
    vendorNameFilter && { vendor_name: vendorNameFilter },
    placeNameFilter && { place_name: placeNameFilter },
    cityFilter && { city: cityFilter },
  );

  Object.assign(input, {
    filter: {
      place_filter,
    },
  });

  return (
    <Layout>
      <Header />
      <Box>
        <PlaceSearch
          placeName={placeNameFilter}
          city={cityFilter}
          vendorName={vendorNameFilter}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              props.history.push(
                `/add-new-onboard${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>Status</span>
                  <br />
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setClaimStatusSort(
                          claimStatusSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          claimStatusSort === 'AES'
                            ? 'CLAIM_STATUS_DESC'
                            : 'CLAIM_STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          claimStatusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Claim Status
                  </span>
                </div>
              </th>

              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedBySort(updatedBySort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedBySort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedBySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated By
                </span>
              </th>
              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedAtSort(updatedAtSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedAtSort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedAtSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated At
                </span>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_places } = data;

              if (
                !search_places.place_listing ||
                search_places.place_listing.length === 0
              ) {
                return <div>NO RECORDS FOUND</div>;
              }
              return (
                <React.Fragment>
                  <View
                    search_places={search_places}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ search_places, setPageCount, setTotalElements }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_places.total_pages);
    setTotalElements(search_places.total_size);

    if (search_places.place_listing) {
      const userFilter = search_places.place_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: {
              filter: {
                user_filter: {
                  user_id: uniq(userFilter),
                  role_not_exist: true,
                },
              },
            },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_places.total_pages]);

  const placeData = search_places.place_listing;

  return (
    <tbody>
      {placeData.map(place => (
        <tr key={place.place_id}>
          <PlaceList place={place} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default AddNewOnboarding;
