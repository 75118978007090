import React from 'react';
import gql from 'graphql-tag';
import uuid from 'uuid';
import moment from 'moment';

import client from '../../../utils/apolloClient';

const fetchTableBookingSlotsQuery = gql`
  query fetchTableBookingSlots(
    $date: String!
    $day: String!
    $placeId: String!
    $serviceTypeSettingId: String!
  ) {
    fetch_table_booking_slots(
      input: {
        day: $day
        date: $date
        place_id: $placeId
        service_type_setting_id: $serviceTypeSettingId
      }
    ) {
      time
      greeting
      occupied
    }
  }
`;

export const searchTableBookingSlotForDate = async ({
  placeId,
  serviceTypeSettingId,
  day,
  date,
}) => {
  try {
    const data = await client.clientPublic.query({
      client: client.clientPublic,
      query: fetchTableBookingSlotsQuery,
      variables: {
        placeId,
        serviceTypeSettingId,
        day,
        date,
      },
      fetchPolicy: 'network-only',
    });

    if (data && data?.data?.fetch_table_booking_slots) {
      return data?.data?.fetch_table_booking_slots;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

const toLocaleString = dateString => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', { weekday: 'short' }).toUpperCase();
};

export const useBookingSlots = ({ date, placeId, serviceTypeSettingId }) => {
  const [slots, setSlots] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      // setLoading(true);
      // setSlots([]);
      if (serviceTypeSettingId && date && placeId) {
        try {
          const res = await searchTableBookingSlotForDate({
            serviceTypeSettingId,
            date: moment(date).format('YYYY-MM-DD'),
            day: toLocaleString(date),
            placeId,
          });
          if (res.length !== 0) {
            setSlots(res);
          } else {
            setSlots([]);
          }
        } catch {
          setSlots([]);
        }
      }
    };
    fetchFunc();
  }, [serviceTypeSettingId, date, placeId]);

  return [slots];
};

const updateTableBookingMutation = gql`
  mutation updateTableBookingMutation($input: TableBookingInput) {
    update_table_booking(input: $input) {
      error {
        code
        description
      }
      user_id
      service_type_setting_id
      place_id
      platform
      guest_count
      guest_notes
      table_booking_id
      booking_date
      booking_time
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      service_type
      booking_reference
      booking_status
      guest_status
      image_url
      table_number
    }
  }
`;

export const updateTableBooking = async ({ input }) => {
  try {
    const data = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateTableBookingMutation,
      variables: {
        input,
      },
    });

    if (
      data &&
      data?.data?.update_table_booking &&
      data.data.update_table_booking.error
    ) {
      return data.data.update_table_booking.error[0].description;
    }
    return false;
  } catch (error) {
    console.log(error);
    return 'ENCOUNTERED AN UNEXPECTED ERROR!';
  }
};

export const useUpdateTableBooking = () => {
  const [loading, setLoading] = React.useState(false);
  const [shldReload, setShldReload] = React.useState(uuid());
  const [error, setError] = React.useState();

  const mutateFnc = async input => {
    setError();
    setLoading(true);
    const res = await updateTableBooking({ input });
    if (!res) {
      setShldReload(uuid());
    } else {
      setError(res);
    }
    setLoading(false);
    return res;
  };
  return [loading, mutateFnc, shldReload, error];
};

const createTableBookingMutation = gql`
  mutation createTableBookingMutation($input: TableBookingInput) {
    create_table_booking(input: $input) {
      error {
        code
        description
      }
      user_id
      service_type_setting_id
      place_id
      platform
      guest_count
      guest_notes
      table_booking_id
      booking_date
      booking_time
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      service_type
      booking_reference
      booking_status
      guest_status
      image_url
      table_number
    }
  }
`;

export const createTableBooking = async ({ input }) => {
  try {
    const data = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createTableBookingMutation,
      variables: {
        input,
      },
    });

    if (
      data &&
      data?.data?.create_table_booking &&
      data.data.create_table_booking.error
    ) {
      return { isError: true, data: null };
    }
    return { isError: false, data: data.data.create_table_booking };
  } catch (error) {
    console.log(error);
    return { isError: true, data: null };
  }
};

export const useCreateTableBooking = () => {
  const [loading, setLoading] = React.useState(false);
  const [shldReload, setShldReload] = React.useState(uuid());

  const mutateFnc = async input => {
    setLoading(true);
    const res = await createTableBooking({ input });
    if (!res.isError) {
      setShldReload(uuid());
    }
    setLoading(false);
    return res;
  };
  return [loading, mutateFnc, shldReload];
};

const fetchPlaceQuery = gql`
  query MyQuery($placeId: [String], $userId: String) {
    search_places(
      input: {
        filter: { place_filter: { place_id: $placeId } }
        user_id: $userId
      }
    ) {
      place_listing {
        city
        name
        state
        post_code
        status
      }
    }
  }
`;

export const fetchPlace = async ({ adminUserId, placeId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: fetchPlaceQuery,
      variables: {
        placeId: [placeId],
        userId: adminUserId,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_places &&
      Array.isArray(data?.data?.search_places?.place_listing)
    ) {
      return data?.data?.search_places?.place_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const usePlaceData = ({ adminUserId, placeId }) => {
  const [placeData, setPlaceData] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      const place = await fetchPlace({ adminUserId, placeId });
      setPlaceData(place);
    };
    fetchFunc();
  }, [adminUserId, placeId]);

  return placeData;
};
export default {};
