import React from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';
import { Table } from '../../../components/elements';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const OrderSummary = ({
  userOrderSummaryData,
  loading,
  selectUserId,
  changeTab,
  canLoadMoreData,
  loadMoreData,
}) => (
  <>
    <div style={{ padding: '10px' }}>
      <h1 style={{ display: 'none' }}>Order Summary</h1>
      <Table id="order-summary-table">
        <thead>
          <tr>
            {[
              'Customer',
              'Email',
              'Phone Number',
              'Orders',
              'Sales',
              'Location',
              'Venues',
              'Avg Order',
              '1st Order',
              'Last Order',
              'Pickup Orders',
              'Pickup Sales',
              'Dinein Orders',
              'Dinein Sales',
              'Delivery Orders',
              'Delivery Sales',
            ].map(headerCellValue => (
              <th key={headerCellValue} style={{ fontWeight: 700 }}>
                <div>
                  <span>{headerCellValue}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        {userOrderSummaryData.length !== 0 && (
          <tbody>
            {userOrderSummaryData.map(summary => (
              <tr
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  selectUserId(summary?.id);
                  changeTab();
                }}
              >
                <td style={{ whiteSpace: 'nowrap' }}>{summary.Customer}</td>
                <td style={{ color: '#00AEEF', whiteSpace: 'nowrap' }}>
                  {summary.Email}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  Need To Implement In BE
                </td>
                <td>{summary.Orders}</td>
                <td>{summary.Sales}</td>
                <td>{summary.Location}</td>
                <td>{summary.Venues}</td>
                <td>{summary['Avg Order']}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{summary['1st Order']}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {summary['Last Order']}
                </td>
                <td>{summary['Pickup Orders']}</td>
                <td>{summary['Pickup Sales']}</td>
                <td>{summary['Dinein Orders']}</td>
                <td>{summary['Dinein Sales']}</td>
                <td>{summary['Delivery Orders']}</td>
                <td>{summary['Delivery Sales']}</td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {loading && (
        <Center>
          <Loading type="spin" color="#363636" width="60px" height="60px" />
        </Center>
      )}
      {!loading && canLoadMoreData && (
        <PaginationContainer>
          <ShowMoreButton type="button" onClick={loadMoreData}>
            <span>Show More</span>
          </ShowMoreButton>
        </PaginationContainer>
      )}
    </div>
  </>
);

export default OrderSummary;
