import gql from 'graphql-tag';
import { omit, forEach, sortBy } from 'lodash';

import client from '../../../utils/apolloClient';

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        menu_section {
          name
          menu_section_id
          display_order
        }
        place {
          place_id
        }
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        internal_name
        slug
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenus = ({ placeId, userId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: { status: 'ACTIVE' },
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menus && data.search_menus.menu_listing) {
          resolve(data.search_menus.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

export const updateSectionsInMenu = async ({
  user_id,
  menu_section,
  menu_id,
}) => {
  const input = {
    user_id,
    menu_section,
    menu_id,
  };
  const response = await client.clientPrivate.mutate({
    client: client.clientPrivate,
    mutation: updateMenuMutation,
    variables: {
      input,
    },
  });
  return response;
};

const searchItemLinkQuery = gql`
  query searchItemsLink($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        service_type_setting {
          service_type_setting_id
          service_type
        }
        modifier {
          modifier_group_id
          variant {
            prompt_id
            prompt
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
            }
          }
        }
        item_link_id
        place_id
        description
        menu {
          menu_id
          name
        }
        menu_section {
          menu_section_id
          name
        }
        display_order
        menu_item {
          menu_item_id
          internal_name
          description
          image_url
          name
          price
          availability_status
          variant {
            prompt_id
            prompt
            variants {
              variant_name
              variant_price
            }
          }
        }
      }
    }
  }
`;

export const getAllItemLink = filter =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchItemLinkQuery,
        variables: {
          input: {
            filter: {
              menu_item_link_filter: filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        console.log('data', data);
        if (
          data.search_menu_item_links &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve(
            sortBy(
              data.search_menu_item_links.menu_item_link_listing,
              'display_order',
            ),
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const removeIdFromVariantsInput = variants => {
  const data = [];
  forEach(variants, item => {
    const temp = [];
    const variant = omit(item, ['__typename']);
    forEach(variant.variants, item2 => {
      const variant2 = omit(item2, ['__typename']);
      temp.push({ ...variant2 });
    });
    data.push({ ...variant, variants: temp });
  });
  return data;
};

const searchSectionsSettingsQuery = gql`
  query searchSections($input: SearchInput) {
    search_menu_sections(input: $input) {
      menu_section_listing {
        menu_section_id
        place_id
        menu_id
        name
        hours
        section_hour {
          day
          is_active
          option {
            type
            start
            end
            start_in_sec
            end_in_sec
          }
        }
      }
    }
  }
`;

export const fetchAllCategoriesOfThePlace = ({
  user_id,
  place_id,
  // menu_id,
}) => {
  const input = {
    user_id,
    size: 50,
    from: 0,
    sort: 'UPDATED_DESC',
  };
  const menu_section_filter = { status: 'ACTIVE' };
  Object.assign(input, {
    filter: { place_filter: { place_id }, menu_section_filter },
  });
  return new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchSectionsSettingsQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (Array.isArray(data?.search_menu_sections?.menu_section_listing)) {
          resolve(data.search_menu_sections.menu_section_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
