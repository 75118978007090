import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Form from './Form';

const Container = styled.section`
  &&& {
    height: 100vh;
    .title {
      font-size: 3rem;
      letter-spacing: 9px;
    }
    .is-flex {
      justify-content: space-between;
    }
  }
`;

const FooterTextWrapper = styled.div`
  padding: 1rem 1rem;
  background: #f2f2f2;
`;

const Login = ({ history }) => {
  const clearError = useStoreActions(state => state.auth.clearError);
  const isError = useStoreState(state => state.auth.isError);
  const isLoading = useStoreState(state => state.auth.loading);
  const login = useStoreActions(state => state.auth.logIn);
  const { defaultPlace, defaultPlaceId } = useStoreState(state => state.menu);
  const { setDefaultPlaceId, setDefaultPlace } = useStoreActions(
    state => state.menu,
  );

  if (defaultPlace) {
    setDefaultPlace(null);
  }

  if (defaultPlaceId) {
    setDefaultPlaceId(null);
  }

  useEffect(() => {
    clearError();
  }, [clearError]);

  return (
    <Container className="hero is-medium">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-uppercase">Kravein</h1>
          <div className="columns is-centered">
            <div className="column is-4 ">
              <Form
                isError={isError}
                isLoading={isLoading}
                onSubmit={values => {
                  login({
                    username: values.email.toLowerCase(),
                    password: values.password,
                    history,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hero-foot">
        <FooterTextWrapper className="is-flex">
          <p className="is-size-4">@2025 KraveIn All right reserved</p>
          <p className="is-size-4">
            <a>Term of use</a> & <a>Privacy policy</a>
          </p>
        </FooterTextWrapper>
      </div>
    </Container>
  );
};

export default Login;
