import React, {
  useState,
  // useEffect
} from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import PlaceSearch from './PlaceSearch';
import PlaceList from './PlaceList';
import client from '../../../utils/apolloClient';
import { Loading, Pagination, Table, Box } from '../../../components/elements';

const searchOnboardingDataQuery = gql`
  query searchOnboardingDataQuery($input: SearchInput) {
    search_onboarding_data(input: $input) {
      total_pages
      total_size
      onboarding_data_listing {
        claim_status
        user_id
        place_name
        city
        onboarding_data_id
        place_id
        place_bank_detail_id
        sales_type
        phone_number
        email
        website
        host_website_by_kravein
        pos_name
        online_aggregator
        is_bank_details
        venue_go_live_date
        comments
        suggested_percentage
        discount_to_pickup
        is_venue_to_kravein_redirection
        table_reservation
        menu_item_photos
        sales_rep_name
        sales_rep_email
        status
        action
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        business_type {
          description
          display_order
          name
        }
        cuisine_style {
          description
          display_order
          name
        }
        error {
          code
          description
        }
        feature {
          description
          display_order
          name
        }

        kravein_onboarding_fee {
          fee_type
          name
          value
        }
        owner_info {
          email
          name
          phone_number
        }
        service_category {
          description
          display_order
          name
        }
        subscriptions {
          fee_type
          name
          value
        }
        venue_photos
        form_status
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;
const listSize = 20;
const Places = props => {
  const routeState = qs.parse(props.location.search);
  const {
    placeName,
    city,
    salesType,
    formStatus,
    salesRepEmail,
    page,
  } = routeState;

  const placeNameFilter = placeName || '';
  const salesTypeFilter = salesType || 'All';
  const formStatusFilter = formStatus || 'All';
  const salesRepEmailFilter = salesRepEmail || null;
  const cityFilter = city || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const { userId } = useStoreState(state => state.auth);

  const input = {
    user_id: userId,
    sort,
    from: currentPage * listSize,
    size: listSize,
  };
  const onboarding_filter = {};
  Object.assign(
    onboarding_filter,
    placeNameFilter && { place_name: placeNameFilter },
    salesTypeFilter !== 'All' && { sales_type: salesTypeFilter },
    formStatusFilter !== 'All' && { form_status: formStatusFilter },
    salesRepEmailFilter && { sales_rep_email: salesRepEmailFilter },
    cityFilter && { city: cityFilter },
  );

  Object.assign(input, {
    filter: {
      onboarding_filter,
    },
  });

  return (
    <Layout>
      <Header />
      <Box>
        <PlaceSearch
          routeState={routeState}
          history={props.history}
          placeName={placeNameFilter}
          city={cityFilter}
          salesType={salesTypeFilter}
          formStatus={formStatusFilter}
          salesRepEmail={salesRepEmailFilter}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              props.history.push(
                `/onboards${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>Status</span>
                  <br />
                </div>
              </th>
              <th>
                <div>
                  <span>Listing Type</span>
                  <br />
                </div>
              </th>
              <th>
                <div>
                  <span>Sales Type</span>
                  <br />
                </div>
              </th>
              <th>
                <div>
                  <span>Form Status</span>
                  <br />
                </div>
              </th>
              <th>
                <div>
                  <span>Claim Status</span>
                  <br />
                </div>
              </th>
              <th>
                <div>
                  <span>Sales Rep</span>
                  <br />
                </div>
              </th>
              <th>
                <span>Updated By</span>
              </th>
              <th>
                <span>Updated At</span>
              </th>
            </tr>
          </thead>
          <Query
            query={searchOnboardingDataQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_onboarding_data } = data;

              if (
                !search_onboarding_data.onboarding_data_listing ||
                search_onboarding_data.onboarding_data_listing.length === 0
              ) {
                return <div>NO RECORDS FOUND</div>;
              }
              return (
                <React.Fragment>
                  <View
                    search_places={search_onboarding_data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ search_places, setPageCount, setTotalElements }) => {
  const [users, setUsers] = useState([]);

  React.useEffect(() => {
    setPageCount(search_places.total_pages);
    setTotalElements(search_places.total_size);

    if (search_places.onboarding_data_listing) {
      const userFilter = search_places.onboarding_data_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: {
              filter: {
                user_filter: {
                  user_id: uniq(userFilter),
                  role_not_exist: true,
                },
              },
            },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_places.total_pages]);

  const placeData = search_places.onboarding_data_listing;

  return (
    <tbody>
      {placeData.map(place => (
        <tr key={place.place_id}>
          <PlaceList place={place} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default Places;
