import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { omit } from 'lodash';
import qs from 'querystringify';
import { Input } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const PlaceSearch = ({ placeName, city, vendorName, routeState, history }) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/add-new-onboard${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };

  return (
    <Wrapper>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              id="PlaceManagement_AddEditPlace_SearchPlaceName"
              value={placeName}
              onChange={place => {
                handlePush({ placeName: place.target.value }, 'placeName');
              }}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Company Name"
              type="text"
              id="PlaceManagement_AddEditPlace_SearchCompanyName"
              value={vendorName}
              onChange={place => {
                handlePush({ vendorName: place.target.value }, 'vendorName');
              }}
              placeholder="Start entering company name..."
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    handlePush({ city: value.name }, 'city');
                  }}
                  onHandleClear={() => handlePush({ city: '' }, 'city')}
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(PlaceSearch);
