import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { omit, capitalize, debounce } from 'lodash';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

import Input from '../elements/Input';
import client from '../../utils/apolloClient';

const usersQuery = gql`
  query users($input: SearchInput) {
    search_users(input: $input) {
      total_size
      total_pages
      user_listing {
        last_login
        user_id
        first_name
        last_name
        display_name
        email
        date_of_birth
        gender
        status
        role
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        error {
          code
          description
        }
      }
    }
  }
`;

const SuggestionsContainer = styled.div`
  max-height: 20rem;
  overflow-y: scroll;
`;
const renderInputComponent = inputProps => {
  const props = omit(inputProps, ['className', 'ref']);

  return <Input {...props} />;
};

const renderSuggestion = suggestion => (
  <p className="is-size-4 has-text-weight-medium is-capitalized">
    {capitalize(suggestion.display_name)}, {suggestion.email}
  </p>
);

const renderSuggestionsContainer = ({ containerProps, children }) =>
  children && (
    <SuggestionsContainer {...containerProps}>{children}</SuggestionsContainer>
  );

const UserSuggestions = ({
  label,
  initialValue,
  onSelect,
  onHandleClear,
  placeholder,
}) => {
  const [value, setValue] = React.useState(`${initialValue}`);
  const [suggestions, setSuggestions] = React.useState([]);
  const { userId } = useStoreState(state => state.auth);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (!newValue) {
      onHandleClear(newValue);
    }
  };

  const getSuggestionValue = suggestion => {
    onSelect(suggestion);
    return suggestion.display_name;
  };

  const onSuggestionsFetchRequested = inputValue => {
    client.clientPrivate
      .query({
        query: usersQuery,
        variables: {
          input: {
            filter: {
              user_filter: {
                email: inputValue.value ? inputValue.value.toLowerCase() : '',
                role: true,
                status: 'ACTIVE',
              },
            },
            sort: 'FIRSTNAME_DESC',
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data?.search_users?.user_listing &&
          Array.isArray(data.search_users.user_listing)
        ) {
          setSuggestions(data.search_users.user_listing);
        } else {
          setSuggestions([]);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const debouncedSave = React.useRef(
    debounce((func, input) => func(input), 300),
  );

  const fetchData = inputValue => {
    debouncedSave.current(onSuggestionsFetchRequested, inputValue);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: placeholder || 'Search User',
    value,
    onChange,
    type: 'Search',
  };

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={fetchData}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={renderSuggestionsContainer}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
      </div>
    </div>
  );
};

UserSuggestions.defaultProps = {
  label: '',
  initialValue: '',
  onSelect: () => {},
  onHandleClear: () => {},
};

UserSuggestions.propTypes = {
  label: PropTypes.string,
  initialValue: PropTypes.string,
  onSelect: PropTypes.func,
  onHandleClear: PropTypes.func,
};

export default UserSuggestions;
