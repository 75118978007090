/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

import { useBookingSlots } from './helpers';

const GreenCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin: 2px;
`;

const RedCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin: 2px;
`;

const SelectedSlotContainer = styled.div`
  border: 1px solid #e2e2e2;
  background-color: #737373;
  color: white;
  min-width: 60px;
  font-size: 10px;
  font-weight: 700;
  flex: 1;
  min-height: 60px;
  cursor: pointer;
`;

const DefaultSlotContainer = styled.div`
  border: 1px solid #e2e2e2;
  background-color: #737373;
  color: white;
  width: 60px;
  font-size: 10px;
  font-weight: 700;
  height: 60px;
  cursor: pointer;
`;

const SlotContainer = styled.div`
  border: 2px solid#e2e2e2;
  min-width: 60px;
  height: 60px;
  font-size: 10px;
  font-weight: 700;
  flex: 1;
  min-height: 60px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  height: 60px;
  width: 75vw;
  position: relative;
  left: 40px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &&::-webkit-scrollbar {
    display: none;
  }
  margin-top: 20px;
`;

const SlotLoadingText = styled.span`
  color: #00aeef;
  font-size: 16px;
  font-weight: 800;
`;

const Slot = ({ hasTooManyGuests, timing, onClick, isSelected }) =>
  isSelected ? (
    <SelectedSlotContainer
      className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
      onClick={onClick}
    >
      {hasTooManyGuests ? <RedCircle /> : <GreenCircle />}
      <div>{timing}</div>
    </SelectedSlotContainer>
  ) : (
    <SlotContainer
      className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
      onClick={onClick}
    >
      {hasTooManyGuests ? <RedCircle /> : <GreenCircle />}
      <div>{timing}</div>
    </SlotContainer>
  );

const BookingSlotsList = ({
  setFieldValue,
  booking_time,
  date,
  placeId,
  serviceTypeSettingId,
  isAdd,
}) => {
  const [currentDateBookingSlots] = useBookingSlots({
    date,
    placeId,
    serviceTypeSettingId,
  });

  if (currentDateBookingSlots === null) {
    return (
      <ContentContainer className="is-flex">
        <SlotLoadingText>loading Slots...</SlotLoadingText>
      </ContentContainer>
    );
  }

  if (isAdd && currentDateBookingSlots.length === 0) {
    return (
      <ContentContainer className="is-flex">
        <span className="has-text-danger">
          No slots available for this date
        </span>
      </ContentContainer>
    );
  }

  if (booking_time && currentDateBookingSlots.length === 0) {
    return (
      <ContentContainer className="is-flex">
        <DefaultSlotContainer className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <GreenCircle />
          <div>{booking_time}</div>
        </DefaultSlotContainer>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer className="is-flex">
      {currentDateBookingSlots.map(ele => (
        <Slot
          hasTooManyGuests={ele.occupied}
          timing={ele.time}
          onClick={() => {
            setFieldValue('booking_time', ele.time);
          }}
          isSelected={ele.time === booking_time}
          key={ele.time}
        />
      ))}
    </ContentContainer>
  );
};

export default BookingSlotsList;
