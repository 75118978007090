import React from 'react';
import moment from 'moment';
import { Button, Icon } from 'rbx';
import styled from 'styled-components';

import { Table, Box, Loading } from '../../../../components/elements';

const Element = styled.div``;

const hourCol = hours => {
  if (!Array.isArray(hours.option)) {
    return '';
  }
  if (hours.option.length === 0) {
    return 'Closed all day';
  }
  if (
    hours.option[0].start === '12:00 AM' &&
    hours.option[0].end === '11:59 PM'
  ) {
    return 'Open 24 Hour';
  }

  return (
    hours.option.length !== 0 &&
    hours.option.map(ele => `${ele.start} - ${ele.end}`).join(' ')
  );
};

const ActionButtons = ({ onDelete, onEdit }) => {
  const [deleting, setDeleting] = React.useState(false);

  return (
    <Element>
      <Button.Group hasAddons>
        <Button onClick={() => onEdit()}>
          <Icon size="small">
            <i className="fas fa-edit" />
          </Icon>
        </Button>
        {deleting ? (
          <span>&nbsp;&nbsp;deleting...</span>
        ) : (
          <Button
            onClick={async () => {
              setDeleting(true);
              await onDelete();
              setDeleting(false);
            }}
            disabled={deleting}
          >
            <Icon size="small">
              <i className="fas fa-trash has-text-grey" />
            </Icon>
          </Button>
        )}
      </Button.Group>
    </Element>
  );
};

const List = ({
  holidays,
  holidayLoading,
  onHandleUpdate,
  onDelete,
  onEdit,
}) => (
  <Box>
    <Table>
      <thead>
        <tr>
          <th>
            <div>
              <span>Date</span>
            </div>
          </th>

          <th>
            <div>
              <span>Hours</span>
            </div>
          </th>
          <th>
            <div>
              <span>Action</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {!holidayLoading &&
          holidays
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map(hours => (
              <tr key={hours.custom_hour_id}>
                <td>
                  <a onClick={() => onHandleUpdate(hours)}>
                    {moment(hours.date, 'YYYY-MM-DD').format('MMM, DD YYYY')}
                  </a>
                </td>
                <td
                  style={{
                    width: '150px',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {hourCol(hours)}
                </td>
                <td>
                  <ActionButtons
                    onDelete={async () => onDelete(hours.custom_hour_id)}
                    onEdit={() => onEdit(hours)}
                  />
                </td>
              </tr>
            ))}
      </tbody>
    </Table>

    {holidayLoading && <Loading />}
    {holidays.length === 0 && !holidayLoading && (
      <p style={{ textAlign: 'center' }}>No Data Found</p>
    )}
  </Box>
);
export default List;
