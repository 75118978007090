import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const Wrapper = styled.div`
  &&& {
    a {
      width: fit-content;
    }
    .disabled a span {
      color: #a8a8a8 !important;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const Span = styled.span`
  padding-left: 2px;
  color: #ff0000;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 150px;
  gap: 10px;
`;

const GridItem = styled.div`
  padding: 5px 0;
`;

const CheckBox2 = ({
  required,
  label,
  value,
  onChange,
  disabled,
  secondary,
}) => {
  const [enable, setEnable] = useState(value);
  useEffect(() => {
    setEnable(value);
  }, [value]);
  return (
    <Wrapper className="field">
      <div className={classNames('control', { disabled })}>
        <input type="checkbox" className="is-hidden" />
        <a
          onClick={
            disabled
              ? null
              : () => {
                  setEnable(!enable);
                  onChange(!enable);
                }
          }
          className="has-text-weight-semibold label is-flex"
        >
          <GridContainer>
            <GridItem>
              <span className="has-text-grey">
                <i
                  id="CheckBox"
                  className={
                    enable
                      ? 'fas fa-check-square has-text-info'
                      : 'far fa-square'
                  }
                />
              </span>
            </GridItem>
            <GridItem style={{}}>
              <span
                className={
                  secondary
                    ? 'has-text-grey has-text-weight-bold'
                    : 'has-text-grey has-text-weight-normal'
                }
                style={{ fontSize: '13px', fontWeight: 700 }}
              >
                {label}
                {required && <Span>*</Span>}
                &nbsp; &nbsp;
              </span>
            </GridItem>
          </GridContainer>
        </a>
      </div>
    </Wrapper>
  );
};

CheckBox2.defaultProps = {
  label: '',
  value: false,
  onChange: () => {},
};

CheckBox2.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckBox2;
