import React from 'react';
import styled from 'styled-components';

import { Label } from 'rbx';

import {
  // CheckBox,
  // TextArea,
  Radio,
  InputAddOn,
  // InputErrorMessage,
  // InputErrorMessage,
} from '../../../components/elements';

const Info = styled.p`
  color: #b0b0b0;
  font-size: 12px;
`;

const PaddingDiv = styled.div`
  padding: ${props => props.verticalPadding || '1rem'}
    ${props => props.horizontalPadding || '0rem'};
`;

const PaymentPolicy = ({
  values,
  errors,
  // touched,
  //   handleChange,
  //   handleBlur,
  setFieldValue,
  //   menuOptions,
  //   selectedMenuValue,
  //   onChangeMenu,
  //   place,
}) => (
  <div style={{ padding: '2rem' }}>
    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Customer can cancel booking</div>
      </Label>
    </PaddingDiv>
    <div className="is-flex">
      <div style={{ paddingRight: '12px' }}>
        <Radio
          label="Never"
          value={!values.cancel_booking}
          onChange={value => {
            if (value) {
              setFieldValue('cancel_booking', false);
            } else {
              setFieldValue('cancel_booking', true);
            }
          }}
        />
      </div>

      <Radio
        label="Any Time"
        value={values.cancel_booking}
        onChange={value => {
          if (value) {
            setFieldValue('cancel_booking', true);
          } else {
            setFieldValue('cancel_booking', false);
          }
        }}
      />
    </div>
    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Refund</div>
      </Label>
    </PaddingDiv>
    <div className="is-flex">
      <div style={{ paddingRight: '12px' }}>
        <Radio
          label="Full Refund"
          value={values.refund_before_cut_off_time === 'FULL'}
          onChange={value => {
            if (value) {
              setFieldValue('refund_before_cut_off_time', 'FULL');
            }
          }}
          disabled={!values.cancel_booking}
        />
      </div>

      <Radio
        label="Partial Refund"
        value={values.refund_before_cut_off_time === 'PARTIAL'}
        onChange={value => {
          if (value) {
            setFieldValue('refund_before_cut_off_time', 'PARTIAL');
          }
        }}
        disabled={!values.cancel_booking}
      />
      <Radio
        label="No Refund"
        value={values.refund_before_cut_off_time === 'NO_REFUND'}
        onChange={value => {
          if (value) {
            setFieldValue('refund_before_cut_off_time', 'NO_REFUND');
          }
        }}
        disabled={values.cut_off_time_in_hours !== '0'}
      />
    </div>
    {values.refund_before_cut_off_time === 'PARTIAL' && (
      <PaddingDiv>
        <Label className="is-flex">
          <div style={{ width: '250px' }}>Partial Cancellation Fee</div>
          <InputAddOn
            addonsText="$"
            isLeft
            type="number"
            id="numberInput"
            name="numberInput"
            min="0"
            max="100"
            step="1"
            value={values.refund_fee_before_cut_off}
            onChange={e =>
              setFieldValue('refund_fee_before_cut_off', e.target.value)
            }
            disabled={
              !values.cancel_booking ||
              values.refund_before_cut_off_time !== 'PARTIAL'
            }
          />
          &nbsp; &nbsp;
          <div>per user / booking</div>
        </Label>
        <Info>Cancellation Fee paid by the customer before the Cut-off.</Info>
        {errors.refund_fee_before_cut_off && (
          <p className="help is-danger is-size-4">
            {errors.refund_fee_before_cut_off}
          </p>
        )}
      </PaddingDiv>
    )}

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '180px' }}>Cut-off Time in</div>
        <input
          type="number"
          id="numberInput"
          name="numberInput"
          min="0"
          max="100"
          step="1"
          value={values.cut_off_time_in_hours}
          onChange={e => setFieldValue('cut_off_time_in_hours', e.target.value)}
          disabled={!values.cancel_booking}
        />
        &nbsp; &nbsp;
        <div>hour (s)</div>
      </Label>
      <Info>
        Cancellation Notice - Number of hours before a booking can be cancelled
        withouth incurring a penalty fee
      </Info>
      {errors.cut_off_time_in_hours && (
        <p className="help is-danger is-size-4">
          {errors.cut_off_time_in_hours}
        </p>
      )}
    </PaddingDiv>

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Refund after Cut-off Time</div>
      </Label>
    </PaddingDiv>
    <div className="is-flex">
      <div style={{ paddingRight: '12px' }}>
        <Radio
          label="Full Refund"
          value={values.refund_after_cut_off_time === 'FULL'}
          onChange={value => {
            if (value) {
              setFieldValue('refund_after_cut_off_time', 'FULL');
            }
          }}
          disabled={!values.cancel_booking}
        />
      </div>

      <Radio
        label="Partial Refund"
        value={values.refund_after_cut_off_time === 'PARTIAL'}
        onChange={value => {
          if (value) {
            setFieldValue('refund_after_cut_off_time', 'PARTIAL');
          }
        }}
        disabled={!values.cancel_booking}
      />

      <Radio
        label="No Refund"
        value={values.refund_after_cut_off_time === 'NO_REFUND'}
        onChange={value => {
          if (value) {
            setFieldValue('refund_after_cut_off_time', 'NO_REFUND');
          }
        }}
        disabled={!values.cancel_booking}
      />
    </div>

    {values.refund_after_cut_off_time === 'PARTIAL' && (
      <PaddingDiv>
        <Label className="is-flex">
          <div style={{ width: '250px' }}>Partial Cancellation Fee</div>
          <InputAddOn
            addonsText="$"
            isLeft
            type="number"
            id="numberInput"
            name="numberInput"
            min="0"
            max="100"
            step="1"
            value={values.refund_fee_after_cut_off}
            onChange={e =>
              setFieldValue('refund_fee_after_cut_off', e.target.value)
            }
            disabled={
              !values.cancel_booking ||
              values.refund_after_cut_off_time !== 'PARTIAL'
            }
          />
          &nbsp; &nbsp;
          <div>per user / booking</div>
        </Label>
        <Info>
          Cancellation Fee paid by the customer after the Cut-off has passed or
          if they do not show up.
        </Info>
        {errors.refund_fee_after_cut_off && (
          <p className="help is-danger is-size-4">
            {errors.refund_fee_after_cut_off}
          </p>
        )}
      </PaddingDiv>
    )}

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Customer can modify booking </div>
      </Label>
    </PaddingDiv>
    <div className="is-flex">
      <div style={{ paddingRight: '12px' }}>
        <Radio
          label="Any Time"
          disabled
          // value={values.booking_type === 'FLAT_FEE'}
          // onChange={value => {
          //   if (value) {
          //     setFieldValue('booking_type', 'FLAT_FEE');
          //   } else {
          //     setFieldValue('booking_type', 'FEE_PER_GUEST');
          //   }
          // }}
          //   disabled={!values.collect_booking_fee}
        />
      </div>

      <Radio
        label="Never"
        disabled
        // value={values.booking_type === 'FEE_PER_GUEST'}
        // onChange={value => {
        //   if (value) {
        //     setFieldValue('booking_type', 'FEE_PER_GUEST');
        //   } else {
        //     setFieldValue('booking_type', 'FLAT_FEE');
        //   }
        // }}
        // disabled={!values.collect_booking_fee}
      />

      <Radio
        label="Till Cut-off time"
        disabled
        // value={values.booking_type === 'FEE_PER_GUEST'}
        // onChange={value => {
        //   if (value) {
        //     setFieldValue('booking_type', 'FEE_PER_GUEST');
        //   } else {
        //     setFieldValue('booking_type', 'FLAT_FEE');
        //   }
        // }}
        // disabled={!values.collect_booking_fee}
      />
    </div>

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>No Show Process</div>
      </Label>
    </PaddingDiv>
    <div className="is-flex">
      <div style={{ paddingRight: '12px' }}>
        <Radio
          label="Full Refund"
          value={values.no_show_refund === 'FULL'}
          onChange={value => {
            if (value) {
              setFieldValue('no_show_refund', 'FULL');
            }
          }}
          disabled={!values.cancel_booking}
        />
      </div>

      <Radio
        label="Partial Refund"
        value={values.no_show_refund === 'PARTIAL'}
        onChange={value => {
          if (value) {
            setFieldValue('no_show_refund', 'PARTIAL');
          }
        }}
        disabled={!values.cancel_booking}
      />

      <Radio
        label="No Refund"
        value={values.no_show_refund === 'NO_REFUND'}
        onChange={value => {
          if (value) {
            setFieldValue('no_show_refund', 'NO_REFUND');
          }
        }}
        disabled={!values.cancel_booking}
      />
    </div>

    {values.no_show_refund === 'PARTIAL' && (
      <PaddingDiv>
        <Label className="is-flex">
          <div style={{ width: '250px' }}>Partial Refund</div>
          <InputAddOn
            addonsText="$"
            isLeft
            type="number"
            id="numberInput"
            name="numberInput"
            min="0"
            max="100"
            step="1"
            value={values.no_show_fee_refund}
            onChange={e => setFieldValue('no_show_fee_refund', e.target.value)}
            disabled={
              !values.cancel_booking || values.no_show_refund !== 'PARTIAL'
            }
          />
          &nbsp; &nbsp;
          <div>per user / booking</div>
        </Label>
        <Info>
          Cancellation Fee paid by the customer after the Cut-off has passed or
          if they do not show up.
        </Info>
        {errors.no_show_fee_refund && (
          <p className="help is-danger is-size-4">
            {errors.no_show_fee_refund}
          </p>
        )}
      </PaddingDiv>
    )}

    <Info>Note: Processing fee will not be refunded</Info>
    <br />
  </div>
);

export default PaymentPolicy;
