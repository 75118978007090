import React from 'react';
import Editor from 'react-simple-wysiwyg';
import styled from 'styled-components';

import { InputErrorMessage } from '../../../components/elements';

const OptionalText = styled.p`
  &&& {
    padding-left: 4px;
    color: #737373;
  }
`;

const BasicTextEditor = ({
  value,
  handleChange,
  disabled,
  errors,
  touched,
  disableToastMessage,
  optionalText,
}) => (
  <div className="mb-2">
    {optionalText && <OptionalText>{optionalText}</OptionalText>}
    <Editor
      value={value}
      onChange={e => {
        handleChange(e.target.value);
      }}
      disabled={disabled}
    />
    <InputErrorMessage
      errors={errors}
      touched={touched}
      disableToastMessage={disableToastMessage}
    />
  </div>
);

export default BasicTextEditor;
