/* eslint-disable max-len */
// import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';

// import uuid from 'uuid';
// import { startCase, replace } from 'lodash';
// import moment from 'moment';

import client from '../../../utils/apolloClient';

const fetchPlaceQuery = gql`
  query fetchPlaceQuery($placeId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      is_test_place
      sales_member_info {
        sales_member_id
        sales_member_email
        sales_member_name
      }
      payment_account {
        account_id
      }
      place_id
      # vendor_id
      name
      slug
      trading_name
      tax_id {
        key
        value
      }
      tagline
      keywords
      description
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      item_review_place
      default_image_url
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      social {
        type
        logo
        description
        value
        display
      }
      latitude
      longitude
      service_type {
        name
        description
        display_order
      }
      service_category {
        name
        description
        display_order
      }
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      feature {
        name
        description
        logo
        display_order
      }
      dining_style {
        name
        description
        display_order
      }
      dietary_information {
        name
        description
        display_order
      }
      parking_detail {
        name
        description
        display_order
      }
      public_transport {
        name
        description
        display_order
      }
      noise_level {
        name
        description
        display_order
      }
      dress_code {
        name
        description
        display_order
      }
      business_type {
        name
        description
        display_order
      }
      ambience {
        name
        description
        display_order
      }
      good_for {
        name
        description
        display_order
      }
      service_type {
        name
        description
        display_order
      }
      tag
      listing_status
      claim_status
      price_range
      slug
      featured
      event_business
      item_review_place
      business_hour {
        day
        is_active
        option {
          type
          start
          end
          start_in_sec
          end_in_sec
        }
      }
      note {
        type
        value
        date
      }
      verification_mode
      status
      approval_status
      display_order
      url {
        uri
        display_order
      }
      voucher_count
      voucher_list {
        code
        description
        tagline
        display_order
      }

      tax {
        type
        value
      }
      default_url
      location
      #  stars
      review_count
      like_percentage
      claimant_id
      listing_audit {
        listing_approved_by
        listing_approved_at
        listing_contact_user_id
        listing_contact_name
        listing_contact_phone
        listing_contact_email
        listing_contact_role
        listing_verification_note {
          type
          value
          date
        }
      }
    }
  }
`;

export const usePlaceData = ({ placeId }) => {
  const {
    data: fetchPlaceData,
    loading: fetchPlaceLoading,
    error: fetchPlaceError,
  } = useQuery(fetchPlaceQuery, {
    variables: { placeId },
    client: client.clientPublic,
  });

  return [
    fetchPlaceLoading,
    fetchPlaceError,
    fetchPlaceData && fetchPlaceData.fetch_place
      ? fetchPlaceData.fetch_place
      : null,
  ];
};

// const searchOnboardingDataQuery = gql`
//   query searchOnboardingDataQuery($placeId: String) {
//     search_onboarding_data(
//       input: { filter: { onboarding_filter: { place_id: $placeId } } }
//     ) {
//       onboarding_data_listing {
//         onboarding_data_id
//         place_bank_detail_id
//       }
//     }
//   }
// `;

const bankDetailsQuery = gql`
  query bankDetailsQuery($place_id: String, $user_id: String) {
    fetch_place_bank_details(
      input: { place_id: $place_id, user_id: $user_id }
    ) {
      place_bank_detail_id
      place_id
      acc_name
      acc_number
      acc_bsb_number
      status
      audit {
        created_at
        updated_at
        created_by
        updated_by
      }
    }
  }
`;

export const useBankDetails = ({ placeId }) => {
  const { userId } = useStoreState(state => state.auth);

  const {
    loading: bankDetailsDataLoading,
    data: bankDetailsData,
    error: bankDetailsDataError,
  } = useQuery(bankDetailsQuery, {
    variables: { place_id: placeId, user_id: userId },
    client: client.clientPrivate,
  });

  return [
    bankDetailsDataLoading,
    bankDetailsData ? bankDetailsData?.fetch_place_bank_details : null,
    bankDetailsDataError,
  ];
};

const createPlaceBankDetailsMutation = gql`
  mutation createPlaceBankDetailsMutation(
    $acc_bsb_number: String
    $status: String
    $user_id: String
    $place_id: String
    $acc_number: String
    $acc_name: String
    $place_bank_detail_id: String
  ) {
    create_place_bank_details(
      input: {
        acc_bsb_number: $acc_bsb_number
        status: $status
        user_id: $user_id
        place_id: $place_id
        acc_number: $acc_number
        acc_name: $acc_name
        place_bank_detail_id: $place_bank_detail_id
      }
    ) {
      acc_bsb_number
      acc_name
      acc_number
      audit {
        updated_at
        updated_by
        created_by
        created_at
      }
      place_bank_detail_id
      status
      place_id
      error {
        code
        description
      }
    }
  }
`;

const updatePlaceBankDetailsMutation = gql`
  mutation updatePlaceBankDetailsMutation(
    $acc_bsb_number: String
    $status: String
    $user_id: String
    $place_id: String
    $acc_number: String
    $acc_name: String
    $place_bank_detail_id: String
  ) {
    update_place_bank_details(
      input: {
        acc_bsb_number: $acc_bsb_number
        status: $status
        user_id: $user_id
        place_id: $place_id
        acc_number: $acc_number
        acc_name: $acc_name
        place_bank_detail_id: $place_bank_detail_id
      }
    ) {
      acc_bsb_number
      acc_name
      acc_number
      audit {
        updated_at
        updated_by
        created_by
        created_at
      }
      place_bank_detail_id
      status
      place_id
      error {
        code
        description
      }
    }
  }
`;

export const createPlaceBankDetails = async ({
  acc_bsb_number,
  status,
  user_id,
  place_id,
  acc_number,
  acc_name,
  place_bank_detail_id,
}) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createPlaceBankDetailsMutation,
      variables: {
        acc_bsb_number,
        status,
        user_id,
        place_id,
        acc_number,
        acc_name,
        place_bank_detail_id,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.create_place_bank_details?.error) &&
      res.data.create_place_bank_details.error[0]
    ) {
      return {
        error: res.data.create_place_bank_details.error[0]?.description,
        isError: true,
      };
    }
    return {
      error: null,
      isError: false,
    };
  } catch (error) {
    return {
      error:
        "Oops! Something didn't work as expected. Please contact support if the issue persists.",
      isError: true,
    };
  }
};

export const updatePlaceBankDetails = async ({
  acc_bsb_number,
  status,
  user_id,
  place_id,
  acc_number,
  acc_name,
  place_bank_detail_id,
}) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updatePlaceBankDetailsMutation,
      variables: {
        acc_bsb_number,
        status,
        user_id,
        place_id,
        acc_number,
        acc_name,
        place_bank_detail_id,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.update_place_bank_details?.error) &&
      res.data.update_place_bank_details.error[0]
    ) {
      return {
        error: res.data.update_place_bank_details.error[0]?.description,
        isError: true,
      };
    }
    return {
      error: null,
      isError: false,
    };
  } catch (error) {
    return {
      error:
        "Oops! Something didn't work as expected. Please contact support if the issue persists.",
      isError: true,
    };
  }
};

export const submitBankDetails = async ({ input, isUpdate }) => {
  if (isUpdate) {
    const res1 = await updatePlaceBankDetails({ ...input });
    return res1;
  }

  const res2 = await createPlaceBankDetails({ ...input });
  return res2;
};

export default {};
