/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import gql from 'graphql-tag';

import swal from 'sweetalert';

import { useMutation } from '@apollo/react-hooks';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';

import { Table as CustomTable, Box } from '../../../../components/elements';

import Header from './Header';
import List from './List';
import AddEditForm from './AddEditForm';
import client from '../../../../utils/apolloClient';
import { fetchUserAccess } from './helpers';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

const updateUserMutation = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      user_id
      error {
        description
      }
    }
  }
`;

const Details = ({ admins, refetch, placeId }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);

  const [loadingUserAccessData, setLoadingUserAccessData] = useState(false);

  const [editUser, setEditUser] = useState(null);

  const [updateUser, { loading: loading2 }] = useMutation(updateUserMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_user }) => {
      if (!isNil(update_user.error)) {
        update_user.error.map(item => toast.error(item.description));
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  return (
    <React.Fragment>
      <Header setIsActiveModal={setIsActiveModal} />
      <Box>
        <Container>
          <CustomTable>
            <Table.Head>
              <Table.Row>
                <Table.Heading>User Id</Table.Heading>
                <Table.Heading>Name</Table.Heading>
                <Table.Heading>Email</Table.Heading>
                <Table.Heading>Roles</Table.Heading>
                <Table.Heading>Phone</Table.Heading>
                <Table.Heading>Actions</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                admins={admins}
                onEdit={user => {
                  setEditUser(user);
                  setIsActiveModal(true);
                }}
                onDelete={async user => {
                  if (user) {
                    swal({
                      icon: 'warning',
                      text:
                        'Would you like to remove the user from this place?',
                      buttons: ['Cancel', 'OK'],
                    }).then(async yes => {
                      if (yes) {
                        const userObj = await fetchUserAccess({
                          userId: user?.user_id,
                        });

                        if (userObj && userObj?.access?.place_id) {
                          const input = { ...userObj };

                          delete input.__typename;
                          delete input.access.__typename;

                          const temp = input.access.place_id.filter(
                            ele => ele !== placeId,
                          );

                          input.access.place_id = temp;

                          await updateUser({ variables: { input } });
                        } else {
                          toast.error(
                            'An unknown error occurred please try again later.',
                          );
                        }
                      }
                    });
                  }
                }}
              />
            </Table.Body>
          </CustomTable>
        </Container>
      </Box>
      {isActiveModal && (
        <AddEditForm
          loading={loading2 || loadingUserAccessData}
          isActive={isActiveModal}
          editUser={editUser}
          onClose={() => {
            setEditUser(null);
            setIsActiveModal(false);
          }}
          onSubmit={async values => {
            setLoadingUserAccessData(true);
            const userObj = await fetchUserAccess({
              userId: values.user?.user_id,
            });
            setLoadingUserAccessData(false);

            if (values.role.length === 0) {
              toast.error('Please assign at least one role to the user!');
              return;
            }

            if (userObj && userObj?.access?.place_id) {
              const input = { ...userObj };

              input.role = values.role;

              delete input.__typename;
              delete input.access.__typename;

              if (Array.isArray(userObj.access.place_id)) {
                if (userObj.access.place_id.find(ele => ele === placeId)) {
                  const places = [...userObj.access.place_id];
                  input.access.place_id = places;
                } else {
                  const places = [...userObj.access.place_id, placeId];
                  input.access.place_id = places;
                }
              } else {
                const place = [placeId];
                input.access.place_id = place;
              }
              updateUser({ variables: { input } });
              setEditUser(null);
            } else {
              const input = { ...userObj };

              input.role = values.role;

              input.access = { place_id: [placeId] };

              delete input.__typename;
              delete input.access.__typename;

              updateUser({ variables: { input } });
              setEditUser(null);
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Details;
