import React from 'react';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';

// import ListingDetails from './ListingDetails';
// import MenuDetails from './MenuDetails';
import {
  useOnboardingPlaceData,
  useOnboardingSetupData,
  usePlaceData,
} from './helpers';

import Form from './Form';

const OnboardPlace = ({ match, history }) => {
  const { userId, user } = useStoreState(state => state.auth);

  const [fetchPlaceLoading, fetchPlaceError, fetchPlaceData] = usePlaceData({
    placeId: match.params.placeId,
  });

  const [
    onboardingSetupDataLoading,
    onboardingSetupDataError,
    onboardingSetupData,
  ] = useOnboardingSetupData({});

  const [
    onboardingPlaceDataLoading,
    onboardingPlaceDataError,
    onboardingPlaceData,
    ,
    refetchOnboardingPlaceData,
  ] = useOnboardingPlaceData({ placeId: match.params.placeId });

  if (fetchPlaceLoading) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: '#00AEEF',
            fontSize: '22px',
          }}
        >
          Loading Place Data...
        </span>
      </Layout>
    );
  }

  if (fetchPlaceError) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: 'red',
            fontSize: '22px',
          }}
        >
          Error Loading Place Data!
        </span>
      </Layout>
    );
  }

  if (onboardingSetupDataLoading) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: '#00AEEF',
            fontSize: '22px',
          }}
        >
          Loading Onboarding Setup Data...
        </span>
      </Layout>
    );
  }

  if (onboardingSetupDataError) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: 'red',
            fontSize: '22px',
          }}
        >
          Error Loading Onboarding Setup Data!
        </span>
      </Layout>
    );
  }

  if (onboardingPlaceDataLoading) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: '#00AEEF',
            fontSize: '22px',
          }}
        >
          Loading Onboarding Place Data...
        </span>
      </Layout>
    );
  }

  if (onboardingPlaceDataError) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: 'red',
            fontSize: '22px',
          }}
        >
          Error Loading Onboarding Place Data!
        </span>
      </Layout>
    );
  }

  console.log('onboardingSetupData...', { onboardingSetupData });

  console.log('onboardingPlaceData...', { onboardingPlaceData });

  console.log('isUpdate...', !!onboardingPlaceData);

  if (fetchPlaceData) {
    return (
      <Layout>
        <Form
          user={user}
          history={history}
          userId={userId}
          place={fetchPlaceData}
          onboardingSetupData={onboardingSetupData}
          onboardingPlaceData={onboardingPlaceData}
          isUpdate={!!onboardingPlaceData}
          refetchOnboardingPlaceData={refetchOnboardingPlaceData}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <span
        style={{
          padding: '2rem',
          color: '#00AEEF',
          fontSize: '22px',
        }}
      >
        No Data!
      </span>
    </Layout>
  );
};

export default OnboardPlace;
