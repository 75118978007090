/* eslint-disable import/prefer-default-export */
import AWS from 'aws-sdk';

import config from './config';
import { checkTokenExpiration } from './auth';

const set_credentials = () =>
  new Promise(resolve => {
    const cognito_id_token = window.localStorage.getItem('token');
    const aws_cognito_config = {};
    aws_cognito_config.IdentityPoolId = config.userPoolConfig.IdentityPoolId;
    aws_cognito_config.Logins = {};
    aws_cognito_config.Logins[
      `cognito-idp.${config.userPoolConfig.region}.amazonaws.com/${config.userPoolConfig.UserPoolId}`
    ] = cognito_id_token;
    // console.log('aws_cognito_config ---> ', aws_cognito_config);
    AWS.config.update({
      region: config.userPoolConfig.region,
      credentials: new AWS.CognitoIdentityCredentials(aws_cognito_config),
    });
    setTimeout(() => resolve(), 2000);
  });

export const upload_image_s3 = async (file, s3Key, metaData) => {
  await checkTokenExpiration();
  await set_credentials();
  AWS.config.credentials.get(err => {
    if (err) console.log(err);
  });

  const metadata = { ...metaData, type: s3Key };

  const s3obj = new AWS.S3({
    params: {
      Bucket: `${config.apiPrefix}images.kravein.com.au`,
      Key: s3Key,
      Metadata: metadata,
    },
  });

  return new Promise((resolve, reject) => {
    // to buffer 10 megabyte chunks and reduce concurrency down to 2
    const params = {
      Key: s3Key,
      ContentType: file.type,
      Body: file,
      ACL: 'public-read',
    };
    s3obj.upload(
      params,
      {
        queueSize: 2,
        partSize: 1024 * 1024 * 10,
        leavePartsOnError: true,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        console.log(data);
        return resolve(data);
      },
    );
  });
};

const excelUploadHelperObj = {
  prod: {
    Bucket: 'upload-menu-items-prod',
  },
  dev: {
    Bucket: 'upload-menu-items',
  },
};

export const upload_excel_file = async (file, s3Key, metaData) => {
  await checkTokenExpiration();
  await set_credentials();
  AWS.config.credentials.get(err => {
    if (err) console.log(err);
  });

  const metadata = { ...metaData, type: s3Key };

  const Bucket =
    config.apiPrefix === 'dev1.'
      ? excelUploadHelperObj.dev.Bucket
      : excelUploadHelperObj.prod.Bucket;

  const s3obj = new AWS.S3({
    params: {
      Bucket,
      Key: s3Key,
      Metadata: metadata,
    },
  });

  return new Promise((resolve, reject) => {
    // to buffer 10 megabyte chunks and reduce concurrency down to 2
    const params = {
      Key: s3Key,
      ContentType: file.type,
      Body: file,
    };
    s3obj.upload(
      params,
      {
        queueSize: 2,
        partSize: 1024 * 1024 * 10,
        leavePartsOnError: true,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        console.log(data);
        return resolve(data);
      },
    );
  });
};

export const upload_onboard_file = async (file, s3Key, metaData, type) => {
  await checkTokenExpiration();
  await set_credentials();
  AWS.config.credentials.get(err => {
    if (err) console.log(err);
  });

  const metadata = { ...metaData, type };

  const Bucket =
    config.apiPrefix === 'dev1.'
      ? 'kravein-onboarding-documents-dev'
      : 'kravein-onboarding-documents-prod-frontend';

  const s3obj = new AWS.S3({
    params: {
      Bucket,
      Key: s3Key,
      Metadata: metadata,
    },
  });

  return new Promise((resolve, reject) => {
    // to buffer 10 megabyte chunks and reduce concurrency down to 2
    const params = {
      Key: s3Key,
      ContentType: type,
      Body: file,
    };
    s3obj.upload(
      params,
      {
        queueSize: 2,
        partSize: 1024 * 1024 * 10,
        leavePartsOnError: true,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        console.log(data);
        return resolve(data);
      },
    );
  });
};

export const upload_onboard_image = async (file, s3Key, metaData, type) => {
  await checkTokenExpiration();
  await set_credentials();
  AWS.config.credentials.get(err => {
    if (err) console.log(err);
  });

  const metadata = { ...metaData, type };

  const Bucket =
    config.apiPrefix === 'dev1.'
      ? 'kravein-onboarding-documents-dev'
      : 'kravein-onboarding-documents-prod-frontend';

  const s3obj = new AWS.S3({
    params: {
      Bucket,
      Key: s3Key,
      Metadata: metadata,
    },
  });

  return new Promise((resolve, reject) => {
    // to buffer 10 megabyte chunks and reduce concurrency down to 2
    const params = {
      Key: s3Key,
      ContentType: type,
      Body: file,
    };
    s3obj.upload(
      params,
      {
        queueSize: 2,
        partSize: 1024 * 1024 * 10,
        leavePartsOnError: true,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }
        console.log(data);
        return resolve(data);
      },
    );
  });
};

export const delete_image_s3 = async s3Key => {
  await checkTokenExpiration();
  await set_credentials();
  AWS.config.credentials.get(err => {
    if (err) console.log(err);
  });

  const params = {
    Bucket: `${config.apiPrefix}images.kravein.com.au`,
    Key: s3Key,
  };
  const s3obj = new AWS.S3({
    params: {
      ...params,
    },
  });

  return new Promise((resolve, reject) => {
    s3obj.deleteObject(params, (err, data) => {
      if (err) {
        return reject(err); // Error in Deletion
      }
      console.log(data);
      return resolve(data); // deleted
    });
  });
};

export const getTicketUrlPdf = async key => {
  await set_credentials();
  const s3 = new AWS.S3({
    params: { Bucket: `${config.apiPrefix}static.kravein.com.au`, Key: key },
  });
  return new Promise(resolve => {
    s3.getObject(
      { Bucket: `${config.apiPrefix}static.kravein.com.au`, Key: key },
      (err, data) => {
        if (err) {
          console.log(err);
        } else {
          console.log('data', data);
          const url = new Blob([data.Body]);
          console.log('url', URL.createObjectURL(url));
          resolve(URL.createObjectURL(url));
        }
      },
    );
  });
};

const googleFeedHelperObj = {
  prod: {
    Bucket: 'google-order-feed',
    Key: 'prod_merchant_service_all_feed.json',
  },
  dev: {
    Bucket: 'dev1.google-order-feed',
    Key: 'merchant_service_all_feed.json',
  },
};

export const getGoogleFeedJSON = async setLoading => {
  const params =
    config.apiPrefix === 'dev1.'
      ? googleFeedHelperObj.dev
      : googleFeedHelperObj.prod;

  await set_credentials();
  const s3 = new AWS.S3({
    params,
  });
  return s3.getObject(params, (err, data) => {
    if (err) {
      console.log(err);
    } else {
      console.log('data', data);
      const jsonContent = JSON.parse(data.Body.toString());
      const blob = new Blob([JSON.stringify(jsonContent)], {
        type: 'application/json',
      });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `feeds-${Date.now()}.json`;
      downloadLink.innerHTML = 'Download JSON';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    setLoading(false);
  });
};

export const getGoogleLogsJSON = async filename => {
  const params =
    config.apiPrefix === 'dev1.'
      ? googleFeedHelperObj.dev
      : googleFeedHelperObj.prod;

  params.Key = filename;

  await set_credentials();
  const s3 = new AWS.S3({
    params,
  });
  return s3.getObject(params, (err, data) => {
    if (err) {
      console.log(err);
    } else {
      console.log('data', data);
      const jsonContent = JSON.parse(data.Body.toString());
      const blob = new Blob([JSON.stringify(jsonContent)], {
        type: 'application/json',
      });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `feeds-${Date.now()}.json`;
      downloadLink.innerHTML = 'Download JSON';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  });
};

export const getEncodedImage = (imageUrl, width, height) => {
  let keySplit;
  if (imageUrl.includes(`//${config.apiPrefix}images.kravein.com.au`)) {
    keySplit = imageUrl.split('amazonaws.com/')[1];
  } else {
    keySplit = imageUrl && imageUrl.split('au/')[1];
  }
  const image_request = JSON.stringify({
    bucket: `${config.apiPrefix}images.kravein.com.au`,
    key: keySplit,
    edits: {
      rotate: null,
      resize: {
        width,
        height,
        fit: 'cover',
      },
    },
  });

  const url_prefix = `https://${config.apiPrefix}images.kravein.com.au/`;
  const url = url_prefix + btoa(image_request);
  return url;
};
