import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import swal from 'sweetalert';
import * as yup from 'yup';
// import uuidv4 from 'uuid/v4';

import AdminUserDetails from './AdminUserDetails';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Admin User',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { handleSubmit, loading } = props;
  const prev = () =>
    activeTab === 0 ? setActiveTab(0) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 0 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  return (
    <React.Fragment>
      <Header
        handleSubmit={handleSubmit}
        heading="Admin Users"
        loading={loading}
        next={next}
        prev={prev}
      />
      <hr />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Container>
          {activeTab === 0 && <AdminUserDetails {...props} />}
        </Container>
      </Box>
      <hr />
      <Footer
        prev={prev}
        next={next}
        loading={loading}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

const UserActivity = withFormik({
  mapPropsToValues: ({ user, places }) => ({
    user_id: user ? user.user_id : '',
    first_name: user ? user.first_name : '',
    last_name: user ? user.last_name : '',
    display_name: user ? user.display_name : '',
    status: user ? user.status : 'ACTIVE',
    email: user ? user.email : '',
    role: user && user.role ? user.role : [],
    access: user ? user.access : '',
    security: user ? user.security : [],
    primaryContact: user
      ? user.contact?.find(c => c.is_primary && c.type === 'mobile')?.value ||
        null
      : null,
    places,
    onboarding_form_access:
      user &&
      user.onboarding_form_access !== null &&
      user.onboarding_form_access !== undefined
        ? user.onboarding_form_access
        : false,
  }),
  validationSchema: yup.object().shape({
    role: yup
      .array()
      .test('role', 'Please assign at least one role to the user', function(
        value,
      ) {
        const { access } = this.parent;

        if (
          access &&
          access.place_id &&
          Array.isArray(access.place_id) &&
          access.place_id.length > 0
        ) {
          return value && value.length > 0;
        }

        return true;
      }),
  }),

  validateOnBlur: false,
  validateOnChange: false,

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      if (
        values.role.length !== 0 &&
        values?.access?.place_id &&
        Array.isArray(values?.access?.place_id) &&
        values?.access?.place_id.length === 0
      ) {
        swal({
          icon: 'warning',
          text: 'Please assign at least one place to the user!',
        });
      } else if (
        values.role.length === 0 &&
        values?.access?.place_id &&
        Array.isArray(values?.access?.place_id) &&
        values?.access?.place_id.length === 0
      ) {
        swal({
          icon: 'warning',
          text:
            'Are you sure that you want to update the user without a role and a place?',
          buttons: ['Cancel', 'OK'],
        }).then(yes => {
          if (yes) {
            props.onSubmit(values);
            setSubmitting(false);
          }
        });
      } else {
        props.onSubmit(values);
        setSubmitting(false);
      }
    }, 300);
  },

  displayName: 'UserActivity',
})(Form);

export default UserActivity;
