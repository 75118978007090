import React from 'react';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchTableBookingsQuery = gql`
  query searchTableBookingsQuery($input: SearchInput) {
    search_table_booking(input: $input) {
      table_booking_listing {
        address_line_1
        address_line_2
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        place_contact {
          display
          display_order
          is_primary
          type
          value
        }
        app_type
        place_id
        booking_date
        booking_reference
        booking_status
        booking_time
        cancelled {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
        city
        confirmed_count
        confirmed {
          created_at
          created_by
          status
          user_type
          note {
            date
            type
            value
          }
          user_data {
            email
            first_name
            last_name
          }
        }
        country
        error {
          code
          description
        }
        contact {
          contact_phone
          contact_email
          first_name
          last_name
        }
        guest_count
        guest_notes
        guest_status
        image_url
        latitude
        location
        longitude
        place {
          name
          place_id
          contact {
            display
            display_order
            is_primary
            type
            value
          }
          state
          city
        }
        platform
        post_code
        received {
          created_at
          created_by
        }
        service_type
        service_type_setting_id
        state
        table_booking_id
        table_number
        tags
        user_id
        waitlisted {
          created_at
          created_by
          status
          user_type
        }
      }
      total_pages
      total_size
    }
  }
`;

const countsQuery = gql`
  query bookingCountsQuery($input: SearchInput) {
    search_table_booking(input: $input) {
      total_pages
      total_size
    }
  }
`;

// {user_id: "",
// size: 10,
// sort: "",
// from: 10,
// filter: {table_booking_filter:
// {booking_date: "",
// booking_status: "",
// date_range: {end_date: "", start_date: ""},
// guest_status: "",
// table_booking_id: "",
// user_id: ""},
// place_filter: {place_id: "", place_name: ""}},
// place_id: "", search_criteria: ""}

export const fetchTableBookingsData = async ({
  adminUserId,
  from,
  size = 50,
  bookingStatus = null,
  startDate = null,
  endDate = null,
  guestStatus = null,
  placeName = null,
  bookingReference = null,
  city = null,
  bookingDate = null,
  searchByBookingNumber = false,
}) => {
  const input = {
    user_id: adminUserId,
    size,
    from,
    filter: {
      table_booking_filter: {},
      place_filter: {},
    },
  };

  if (bookingDate) {
    input.filter.table_booking_filter.booking_date = new Date(bookingDate)
      .toLocaleDateString('en-GB')
      .split('/')
      .join('-');
  }

  if (bookingReference) {
    input.filter.table_booking_filter.booking_reference = bookingReference;
  }

  if (bookingStatus) {
    input.filter.table_booking_filter.booking_status = bookingStatus;
  }

  if (!searchByBookingNumber) {
    if (!startDate && !endDate && bookingStatus !== 'RECEIVED') {
      input.filter.table_booking_filter.date_range = {
        end_date: new Date().toISOString(),
        start_date: new Date().toISOString(),
      };
    }

    if (startDate && !endDate) {
      input.filter.table_booking_filter.date_range = {
        end_date: new Date().toISOString(),
        start_date: startDate,
      };
    }

    if (endDate && !startDate) {
      input.filter.table_booking_filter.date_range = {
        end_date: endDate,
        start_date: new Date().toISOString(),
      };
    }

    if (startDate && endDate) {
      input.filter.table_booking_filter.date_range = {
        end_date: endDate,
        start_date: startDate,
      };
    }
  }

  if (guestStatus) {
    input.filter.table_booking_filter.guest_status = guestStatus;
  }

  if (placeName) {
    input.filter.place_filter.place_id = placeName;
  }

  if (city) {
    input.filter.place_filter.city = city;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingsQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_booking &&
      Array.isArray(data.data.search_table_booking?.table_booking_listing) &&
      data.data.search_table_booking?.table_booking_listing.length !== 0
    ) {
      return data.data.search_table_booking;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchBookingsCount = async ({
  adminUserId,
  bookingStatus = null,
  guestStatus = null,
  startDate = null,
  endDate = null,
  placeName = null,
  city = null,
  from = 0,
  size = 50,
}) => {
  const input = {
    user_id: adminUserId,
    from,
    size,
    filter: {
      table_booking_filter: {},
      place_filter: {},
    },
  };

  if (bookingStatus) {
    input.filter.table_booking_filter.booking_status = bookingStatus;
  }

  if (!startDate && !endDate && bookingStatus !== 'RECEIVED') {
    input.filter.table_booking_filter.date_range = {
      end_date: new Date().toISOString(),
      start_date: new Date().toISOString(),
    };
  }

  if (startDate && !endDate) {
    input.filter.table_booking_filter.date_range = {
      end_date: new Date().toISOString(),
      start_date: startDate,
    };
  }

  if (endDate && !startDate) {
    input.filter.table_booking_filter.date_range = {
      end_date: endDate,
      start_date: new Date().toISOString(),
    };
  }

  if (startDate && endDate) {
    input.filter.table_booking_filter.date_range = {
      end_date: endDate,
      start_date: startDate,
    };
  }

  if (guestStatus) {
    input.filter.table_booking_filter.guest_status = guestStatus;
  }

  if (placeName) {
    input.filter.place_filter.place_id = placeName;
  }

  if (city) {
    input.filter.place_filter.city = city;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: countsQuery,
      variables: { input },
      fetchPolicy: 'network-only',
    });

    if (data && data?.data) {
      return data.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useTableBookingsData = ({
  adminUserId,
  from,
  size = 50,
  bookingStatus = null,
  startDate = null,
  endDate = null,
  guestStatus = null,
  placeName = null,
  bookingReference = null,
  city = null,
  bookingDate = null,
  triggerRerender,
  searchByBookingNumber = false,
}) => {
  const [
    loadingTableBookingsData,
    setLoadingTableBookingsData,
  ] = React.useState(false);
  const [tableBookingsData, setTableBookingsData] = React.useState([]);
  const [totalSize, setTotalSize] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    let loadData = true;
    const fetchFunc = async () => {
      setLoadingTableBookingsData(true);
      setTableBookingsData([]);
      setTotalSize(0);
      setTotalPages(0);
      try {
        const res = await fetchTableBookingsData({
          adminUserId,
          from,
          size,
          bookingStatus,
          startDate,
          endDate,
          guestStatus,
          placeName,
          bookingReference,
          city,
          bookingDate,
          searchByBookingNumber,
        });
        if (loadData) {
          if (res && res?.table_booking_listing) {
            setTableBookingsData(res.table_booking_listing);
            setTotalSize(res.total_size);
            setTotalPages(res.total_pages);
          } else {
            setTotalSize(0);
            setTotalPages(0);
          }
        } else {
          // pass
        }
      } catch {
        setLoadingTableBookingsData(false);
        setTotalSize(0);
        setTotalPages(0);
      }
      setLoadingTableBookingsData(false);
    };
    fetchFunc();
    return () => {
      loadData = false;
    };
  }, [
    bookingDate,
    adminUserId,
    from,
    size,
    bookingStatus,
    startDate,
    endDate,
    guestStatus,
    placeName,
    bookingReference,
    city,
    triggerRerender,
    searchByBookingNumber,
  ]);
  return [
    loadingTableBookingsData,
    tableBookingsData,
    totalSize,
    totalPages,
    async () => {
      try {
        const res = await fetchTableBookingsData({
          adminUserId,
          from,
          size,
          bookingStatus,
          startDate,
          endDate,
          guestStatus,
          placeName,
          bookingReference,
          city,
          bookingDate,
        });
        return res && res?.table_booking_listing
          ? res.table_booking_listing
          : [];
      } catch {
        // pass
      }
      return [];
    },
  ];
};

export const useBookingsCount = ({
  adminUserId,
  startDate = null,
  endDate = null,
  placeName = null,
  city = null,
  triggerRerender,
  searchByBookingNumber = false,
}) => {
  const [newBookingCount, setNewBookingCount] = React.useState(0);
  const [confirmedBookingCount, setConfirmedBookingCount] = React.useState(0);
  const [waitlistedBookingCount, setWaitlistedBookingCount] = React.useState(0);
  const [cancelledBookingCount, setCancelledBookingCount] = React.useState(0);
  const [upcomingBookingCount, setUpcomingBookingCount] = React.useState(0);
  React.useEffect(() => {
    let loadState = true;
    const fetchFunc = async () => {
      try {
        const res1 = await fetchBookingsCount({
          adminUserId,
          startDate,
          endDate,
          placeName,
          city,
          bookingStatus: 'RECEIVED',
          guestStatus: null,
        });
        if (res1 && res1?.search_table_booking) {
          if (loadState) {
            setNewBookingCount(res1.search_table_booking?.total_size);
          }
        }
        const res2 = await fetchBookingsCount({
          adminUserId,
          startDate,
          endDate,
          placeName,
          city,
          bookingStatus: 'CONFIRMED',
          guestStatus: null,
        });
        if (res2 && res2?.search_table_booking) {
          if (loadState) {
            setConfirmedBookingCount(res2.search_table_booking?.total_size);
          }
        }
        const res3 = await fetchBookingsCount({
          adminUserId,
          startDate,
          endDate,
          placeName,
          city,
          bookingStatus: 'WAITLISTED',
          guestStatus: null,
        });
        if (res3 && res3?.search_table_booking) {
          if (loadState) {
            setWaitlistedBookingCount(res3.search_table_booking?.total_size);
          }
        }
        const res4 = await fetchBookingsCount({
          adminUserId,
          startDate,
          endDate,
          placeName,
          city,
          bookingStatus: 'CANCELLED',
          guestStatus: null,
        });
        if (res4 && res4?.search_table_booking) {
          if (loadState) {
            setCancelledBookingCount(res4.search_table_booking?.total_size);
          }
        }
        const res5 = await fetchBookingsCount({
          adminUserId,
          startDate,
          endDate,
          placeName,
          city,
          bookingStatus: null,
          guestStatus: 'UPCOMING',
        });
        if (res5 && res5?.search_table_booking) {
          if (loadState) {
            setUpcomingBookingCount(res5.search_table_booking?.total_size);
          }
        }
      } catch {
        // pass
      }
    };
    if (!searchByBookingNumber) {
      fetchFunc();
    }
    return () => {
      loadState = false;
    };
  }, [adminUserId, startDate, endDate, placeName, city, searchByBookingNumber]);
  return [
    newBookingCount,
    setNewBookingCount,
    confirmedBookingCount,
    setConfirmedBookingCount,
    waitlistedBookingCount,
    setWaitlistedBookingCount,
    cancelledBookingCount,
    setCancelledBookingCount,
    upcomingBookingCount,
    setUpcomingBookingCount,
    triggerRerender,
  ];
};

const fetchAuditUserDataQuery = gql`
  query searchUsersQuery($userId: [String]) {
    search_users(input: { filter: { user_filter: { user_id: $userId } } }) {
      user_listing {
        display_name
        email
      }
    }
  }
`;

const fetchAuditUserData = async ({ userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: fetchAuditUserDataQuery,
      variables: {
        userId: [userId],
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_users &&
      Array.isArray(data.data.search_users?.user_listing) &&
      data.data.search_users?.user_listing.length !== 0
    ) {
      return data.data.search_users.user_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useAuditUserData = ({ userId }) => {
  const [auditUserData, setAuditUserData] = React.useState(null);
  React.useEffect(() => {
    let loadData = true;
    const fetchFunc = async () => {
      setAuditUserData(null);
      const res = await fetchAuditUserData({ userId });
      if (loadData) {
        if (res) {
          setAuditUserData(res);
        }
      }
    };
    fetchFunc();
    return () => {
      loadData = false;
    };
  }, [userId]);
  return auditUserData;
};

export default {};
