import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  history,
  place,
  handleSubmit = () => {},
  loading,
  showNoButton = false,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>Bank Details</Title>
      </Level.Item>

      <Level.Item align="right">
        {!showNoButton && (
          <>
            <Level.Item>
              <a onClick={() => history.goBack()} className="button is-light">
                <span>Cancel</span>
              </a>
            </Level.Item>
            <Level.Item>
              <button
                type="submit"
                className={`button is-primary ${loading && 'is-loading'}`}
                onClick={handleSubmit}
                disabled={loading}
              >
                <span>Submit</span>
              </button>
            </Level.Item>
          </>
        )}
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">
      {`${place.name} | ${place.state} | ${place.city}, ${place.post_code} (${place.status} / ${place.claim_status})`}
    </SubHeading>
  </Wrapper>
);

export default withRouter(Header);
