/* eslint-disable import/prefer-default-export */
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import gql from 'graphql-tag';
import client from '../../../../../utils/apolloClient';
import { checkItemVariant } from '../helpers';

export const checkUnavailableDates = (date, location) => {
  if (date) {
    const time_zone = tzLookup(
      parseFloat(location.latitude),
      parseFloat(location.longitude),
    );
    const dateNow = moment().tz(time_zone);
    const endDate = moment(date).tz(time_zone);
    if (moment(endDate).isAfter(dateNow)) {
      return `Avl ${moment(endDate).format('DD MMM')}`;
    }
    return null;
  }
  return null;
};

export const transformLinks = links => {
  const temp = [];
  const sortedLinks = sortBy(links, 'display_order');

  sortedLinks.forEach(ele1 => {
    const { item_link_id } = ele1;
    const { name, image_url, description, menu_item_id } = ele1.menu_item;
    const { menu_section_id } = ele1.menu_section;
    let basePrice = ele1.menu_item.price;
    const { price } = ele1;
    let overridePriceString;
    if (price) {
      overridePriceString = `Override Price $${parseFloat(price).toFixed(2)}`;
    }
    const rootLevelVariant = ele1.variant;
    const menuItemVariant = ele1.menu_item.variant;
    let minVariantBasePrice;
    const variantMergedWithOverrideData = [];
    if (checkItemVariant(menuItemVariant)) {
      const temp2 = [];
      menuItemVariant[0].variants.forEach(ele2 => {
        let rootLevelObj;
        if (checkItemVariant(rootLevelVariant)) {
          rootLevelObj = rootLevelVariant[0].variants.find(
            rlv => rlv.variant_id === ele2.variant_id,
          );
        }
        if (rootLevelObj) {
          if (!minVariantBasePrice) {
            overridePriceString = rootLevelObj.variant_price
              ? `Override Price $${parseFloat(
                  rootLevelObj.variant_price,
                ).toFixed(2)}`
              : null;
          } else if (
            parseFloat(minVariantBasePrice) > parseFloat(ele2.variant_price)
          ) {
            overridePriceString = rootLevelObj.variant_price
              ? `Override Price $${parseFloat(rootLevelObj.variant_price)}`
              : null;
          }
        }

        if (!minVariantBasePrice) {
          minVariantBasePrice = ele2.variant_price;
        } else {
          minVariantBasePrice = Math.min(
            ele2.variant_price,
            minVariantBasePrice,
          );
        }

        const { variant_name, variant_id } = ele2;
        const variant_price = rootLevelObj?.variant_price;
        temp2.push({
          variant_name,
          variant_id,
          variant_price,
        });
      });
      variantMergedWithOverrideData.push({
        prompt_id: menuItemVariant[0].prompt_id,
        prompt: menuItemVariant[0].prompt,
        variants: temp2,
      });
    }
    if (minVariantBasePrice) {
      basePrice = minVariantBasePrice;
    }
    // availability to be implemented

    // const menuItemAvailDate = ele1.menu_item.availability_date;
    // const menuItemAvailStatus = ele1.menu_item.availability_status;
    // const rootAvailDate = ele1.availability_date;

    if (basePrice === null || basePrice === undefined) {
      basePrice = 0;
    }
    temp.push({
      item_link_id,
      menu_section_id,
      name,
      image_url,
      description,
      menu_item_id,
      basePrice,
      price,
      overridePriceString,
      variantMergedWithOverrideData,
      menuItemVariant,
      availability_date: '',
    });
  });
  return temp;
};

const searchSectionsAndLinksQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        item_link_id
        display_order
        availability_date
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_price
            variant_name
            availability_date
          }
        }
        menu_section {
          menu_section_id
        }
        menu_item {
          menu_item_id
          name
          price
          image_url
          item_id
          description
          popular
          availability_status
          availability_date
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
              availability_date
            }
          }
        }
      }
    }
  }
`;

export const getLinks = ({ menuId, menuSectionId = null }) =>
  new Promise((resolve, reject) => {
    const menu_item_link_filter = { status: 'ACTIVE', menu_id: menuId };
    if (menuSectionId) {
      Object.assign(menu_item_link_filter, { menu_section_id: menuSectionId });
    }
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchSectionsAndLinksQuery,
        variables: {
          input: {
            search_criteria: '',
            filter: {
              menu_item_link_filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_item_links &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve(data.search_menu_item_links.menu_item_link_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const itemsOfInitialActiveCategory = async ({
  setItemIsLoading,
  setItemsOfActiveCategory,
  menuId,
  setItemDetailsFormData,
  categoryId,
}) => {
  setItemDetailsFormData(null);
  setItemIsLoading(true);
  try {
    let links = await getLinks({ menuId, menuSectionId: categoryId });
    links =
      Array.isArray(links) && links.length !== 0
        ? links.filter(lnk => lnk.menu_section.menu_section_id === categoryId)
        : [];
    if (links.length !== 0) {
      setItemsOfActiveCategory(transformLinks(links));
      setItemIsLoading(false);
    } else {
      setItemsOfActiveCategory([]);
      setItemIsLoading(false);
    }
  } catch (error) {
    setItemsOfActiveCategory([]);
    setItemIsLoading(false);
    console.log(error);
  }
};

const fetchMenuSectionQuery = gql`
  query fetchMenuSection($input: NavigateMenuSectionInput) {
    fetch_menu_section(input: $input) {
      menu_id
      menu_section_id
    }
  }
`;

export const getMenusOfMenuSection = ({ menuSectionId }) =>
  new Promise((resolve, reject) => {
    const input = {
      menu_section_id: menuSectionId,
    };
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: fetchMenuSectionQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data &&
          data.fetch_menu_section &&
          data.fetch_menu_section.menu_id
        ) {
          resolve(data.fetch_menu_section.menu_id);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const updateMenuSectionMutation = gql`
  mutation updateService($input: [MenuSectionInput]) {
    update_menu_section(input: $input) {
      menu_section_id
      name
      error {
        description
      }
    }
  }
`;

export const updateMenuSection = async ({ userId, menuSectionId, menuIds }) => {
  const input = {
    user_id: userId,
    menu_section_id: menuSectionId,
    menu_id: menuIds,
  };
  const response = await client.clientPrivate.mutate({
    client: client.clientPrivate,
    mutation: updateMenuSectionMutation,
    variables: {
      input: [input],
    },
  });
  return response;
};

export const removeCategoryFromMenu = async ({
  menuSectionId,
  menuId,
  userId,
}) => {
  let response;
  try {
    const menus = await getMenusOfMenuSection({ menuSectionId });
    if (Array.isArray(menus) && menus.length !== 0) {
      const filteredMenus = menus.filter(mnu => mnu !== menuId);
      try {
        response = await updateMenuSection({
          userId,
          menuSectionId,
          menuIds: filteredMenus,
        });
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {
    console.log(error);
  }
  return response;
};

const deleteMenuSectionMutation = gql`
  mutation deleteMenuSection($input: NavigateMenuSectionInput) {
    delete_menu_section(input: $input) {
      menu_section_id
      error {
        description
      }
    }
  }
`;

export const deleteMenuSection = async ({ userId, menuSectionId }) => {
  try {
    await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: deleteMenuSectionMutation,
      variables: {
        input: { user_id: userId, menu_section_id: menuSectionId },
      },
    });
  } catch (error) {
    console.log('error at deleteMenuSection function', { error });
  }
};
