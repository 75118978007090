import React, { useState } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import * as yup from 'yup';
import {
  // forEach,
  //  omit,
  isNull,
  first,
  // isNil,
} from 'lodash';
// import moment from 'moment';
import { Tab } from 'rbx';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import swal from 'sweetalert';
// import { toast } from 'react-toastify';

// import ClaimPlace from './ClaimPlace';
// import PlaceInformation from './PlaceInformation';
// import PlaceDetails from './PlaceDetails';

// import PlaceHoursNew from './PlaceHoursNew';
// import PlaceImages from './PlaceImages';
import Header from './Header';
// import Footer from './Footer';
// import Payouts from './Payouts';
import { Box, Tabs } from '../../../components/elements';

import {
  defaultHours,
  addIdInputHours,
  // parseFinalInputHours,
} from '../../MenuManagement/Menu/helper';
import {
  placeBusinessHoursHandler,
  // menuHourSorter,
} from '../../../utils/helpers';
import PlaceInformation from './PlaceInformation';
import PlaceHoursNew from '../AddPlace/PlaceHoursNew';
import PlaceImage from './PlaceImage';
import ZoneSetting from './ZoneSetting';
import GalleryImages from '../AddPlace/GalleryImages';
import Preview from './Preview';

const Wrapper = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 'place-information',
    name: 'Information',
    index: 0,
  },
  {
    key: 'business-hours',
    name: 'Business Hours',
    index: 1,
  },
  {
    key: 'hero-image',
    name: 'Hero Image and Logo',
    index: 2,
  },
  {
    key: 'gallery-images',
    name: 'Gallery Images',
    index: 3,
  },
  {
    key: 'zone-setting',
    name: 'Zone Setting',
    index: 4,
  },
  {
    key: 'preview',
    name: 'Preview',
    index: 5,
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    values,
    // claim,
    handleSubmit,
    loading,
    // isClaimed,
    dirty,
    // errors,
    // isSubmitting,
    place,
  } = props;

  const { cuisine_style, city } = place;

  const footerText = [];

  footerText.push(`Food Delivery ${city}`);

  footerText.push(`Take away ${city}`);

  cuisine_style.forEach(ele => {
    const { name } = ele;
    // <suburb> <cuisine> food
    footerText.push(`${city} ${name} food`);
    // <suburb> best <cuisine>
    footerText.push(`${city} best ${name}`);
    // best <cuisine> rest @ <citu>
    footerText.push(`Best ${name} restaurant at ${city}`);

    footerText.push(`${name} delivery ${city}`);

    footerText.push(`${name} food delivery ${city}`);

    footerText.push(`best ${name} take away ${city}`);

    footerText.push(`${name} take away ${city}`);
  });
  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 4 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  console.log('image', values.business_hour);
  return (
    <>
      <Header
        prev={prev}
        next={next}
        activeTab={activeTab}
        loading={loading}
        handleSubmit={handleSubmit}
        placeName={values.name}
        status={values.status}
        values={values}
        claimStatus={values.claim_status}
        dirty={dirty}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.index}
              key={tab.key}
              onClick={() => setActiveTab(tab.index)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Wrapper>
          <>
            {activeTab === 0 && (
              <PlaceInformation
                props={props}
                next={() => setActiveTab('business-hours')}
              />
            )}
            {activeTab === 1 && (
              <PlaceHoursNew
                action={['update_place', 'create_place']}
                {...props}
                next={() => setActiveTab('hero-image')}
              />
            )}
            {activeTab === 2 && <PlaceImage {...props} />}
            {activeTab === 3 && <GalleryImages {...props} />}
            {activeTab === 4 && <ZoneSetting {...props} />}
            {activeTab === 5 && <Preview values={values} />}
          </>
        </Wrapper>
      </Box>
    </>
  );
};

const GenerateStaticSiteForm = withFormik({
  mapPropsToValues: ({ place, userId, nearestLocations }) => {
    const primaryEmail =
      place && !isNull(place.contact)
        ? place.contact.filter(item => item.type === 'email_primary')
        : [];

    const primaryMobile =
      place && !isNull(place.contact)
        ? place.contact.filter(item => item.type === 'phone_primary')
        : [];

    const businessSite =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'website',
          )
        : [];

    const { cuisine_style, city: placeCity } = place;

    const nearestCity = new Set([placeCity]);

    nearestLocations.forEach(nearestLocationObj => {
      const { city } = nearestLocationObj;
      if (nearestCity.has(city)) {
        // pass
      } else if (nearestCity.size < 3) {
        nearestCity.add(city);
      }
    });

    const footerText = [];

    Array.from(nearestCity).forEach(city => {
      footerText.push(`Food Delivery ${city}`);

      footerText.push(`Take away ${city}`);

      cuisine_style.forEach(ele => {
        const { description } = ele;

        footerText.push(`${city} ${description} food`);

        footerText.push(`${city} best ${description}`);

        footerText.push(`Best ${description} restaurant ${city}`);

        footerText.push(`${description} delivery ${city}`);

        footerText.push(`${description} food delivery ${city}`);

        footerText.push(`best ${description} take away ${city}`);

        footerText.push(`${description} take away ${city}`);
      });
    });

    return {
      default_image_url: [],
      bookingURL: '',
      image: [
        {
          url: '',
          preview: null,
          tooltip: '',
          link: '',
          tag: [],
          uploadingStatus: 'pending',
        },
      ],
      zone_1_amount: '',
      zone_1_fee: '',
      zone_2_amount: '',
      zone_2_fee: '',
      zone_3_amount: '',
      zone_3_fee: '',
      logo_url: '',
      image_url: '',
      foodPickupURL: '',
      foodDeliveryURL: '',
      primaryEmail: primaryEmail.length !== 0 ? first(primaryEmail).value : '',
      primaryMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      businessSite: businessSite.length !== 0 ? first(businessSite).value : '',
      user_id: userId,
      place_id: place ? place.place_id : uuidv4(),
      name: place ? place.name : '',
      slug: place ? place.slug : '',
      status: place ? place.status : 'ACTIVE',
      listing_status: place ? place.listing_status : 'APPROVED',
      claim_status: place ? place.claim_status : 'UNCLAIMED',
      price_range: place ? parseInt(place.price_range, 10) : '',
      footer_keywords: [],
      description: '',
      food_delivery_description: '',
      footer_text: footerText,
      tag: [],
      address_line_1:
        place && !isNull(place.address_line_1) ? place.address_line_1 : '',
      address_line_2:
        place && !isNull(place.address_line_2) ? place.address_line_2 : '',
      city: place && !isNull(place.city) ? place.city : '',
      state: place && !isNull(place.state) ? place.state : '',
      country: place && !isNull(place.country) ? place.country : '',
      post_code: place && !isNull(place.post_code) ? place.post_code : '',
      latitude: place && !isNull(place.latitude) ? place.latitude : '',
      longitude: place && !isNull(place.longitude) ? place.longitude : '',
      location: place && !isNull(place.location) ? place.location : '',
      timezone: place && !isNull(place.timezone) ? place.timezone : '+10',
      business_hour:
        place &&
        !isNull(place.business_hour) &&
        place.business_hour.length !== 0
          ? placeBusinessHoursHandler(addIdInputHours(place.business_hour))
          : defaultHours,
    };
  },

  validate: values => {
    const errors = {};
    if (values.primaryMobile && values.primaryMobile.length !== 0) {
      const check = isPossiblePhoneNumber(values.primaryMobile);
      if (!check) {
        errors.primaryMobile = 'Please enter valid mobile number';
      }
    }

    return errors;
  },

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log(values, props, setSubmitting);
    if (
      !(values.foodPickupURL || values.foodDeliveryURL || values.bookingURL)
    ) {
      swal('Please Fill Up Atleast One URL!');
      return;
    }
    import('./generateHTML')
      .then(module => {
        const generateHTML = module.default;
        generateHTML(values);
      })
      .catch(error => {
        console.error('Error loading Handlebars:', error);
      });
  },

  displayName: 'GenerateStaticSiteForm',
})(Form);

export default GenerateStaticSiteForm;
