import React from 'react';
import styled from 'styled-components';
import { ReactDateTimePicker } from '../../../components/elements';

const FilterSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 200px repeat(4, 1fr);
  gap: 1px;
  padding: 15px;
  width: 100%;
`;

const FilterSectionItems = styled.div`
  font-size: 12px;
  text-align: center;
  color: #00aeef;
  font-weight: 700;
`;

const FilterSection = ({
  tab,
  onClickAddBooking,
  disableDateSelector,
  dateFilter,
  setDateFilter,
  openTagsFilterModal,
  tags,
  bookingStatusFilter,
  guestStatusFilter,
  openStatusFilterModal,
  openChartsScreen,
  clearTagsFilters,
  clearStatusFilters,
}) => (
  <FilterSectionContainer>
    <FilterSectionItems>
      {tab === 4 ? (
        <ReactDateTimePicker
          minDate={new Date()}
          value={dateFilter}
          onChange={value => {
            setDateFilter(new Date(value));
          }}
          disabled={disableDateSelector}
        />
      ) : (
        <ReactDateTimePicker
          value={dateFilter}
          onChange={value => {
            setDateFilter(new Date(value));
          }}
          disabled={disableDateSelector}
        />
      )}
    </FilterSectionItems>
    {tab !== 4 && (
      <>
        <FilterSectionItems style={{ cursor: 'pointer' }}>
          <div onClick={openStatusFilterModal}>FILTERS</div>
          {(bookingStatusFilter || guestStatusFilter) && (
            <div
              style={{
                textDecoration: 'underline',
                fontSize: '8px',
                color: '#737373',
                fontWeight: 500,
              }}
            >
              filters applied
            </div>
          )}
          {(bookingStatusFilter || guestStatusFilter) && (
            <div
              style={{
                textDecoration: 'underline',
                fontSize: '8px',
                color: '#737373',
                fontWeight: 500,
              }}
              onClick={clearStatusFilters}
            >
              <i
                style={{ position: 'relative', top: '1px' }}
                className="fas fa-times"
                aria-hidden="true"
              />{' '}
              remove filters
            </div>
          )}
        </FilterSectionItems>
        <FilterSectionItems style={{ cursor: 'pointer' }}>
          <div onClick={openTagsFilterModal}>TAGS</div>
          {tags.length !== 0 && (
            <div
              style={{
                textDecoration: 'underline',
                fontSize: '8px',
                color: '#737373',
                fontWeight: 500,
              }}
            >
              tags filter applied
            </div>
          )}
          {tags.length !== 0 && (
            <div
              style={{
                textDecoration: 'underline',
                fontSize: '8px',
                color: '#737373',
                fontWeight: 500,
              }}
              onClick={clearTagsFilters}
            >
              <i
                style={{ position: 'relative', top: '1px' }}
                className="fas fa-times"
                aria-hidden="true"
              />{' '}
              remove filters
            </div>
          )}
        </FilterSectionItems>
        <FilterSectionItems
          style={{ cursor: 'pointer' }}
          onClick={openChartsScreen}
        >
          CHARTS
        </FilterSectionItems>
        <FilterSectionItems>
          <button
            className="button is-primary"
            onClick={() => onClickAddBooking()}
          >
            Add a Booking
          </button>
        </FilterSectionItems>
      </>
    )}
  </FilterSectionContainer>
);

export default FilterSection;
