/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import { Tab, Column } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, first, lowerCase, omit } from 'lodash';
import * as yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { Box, Tabs, Select } from '../../../components/elements';
import PlacePageHeader from '../../../components/PlacePageHeader';
import Header from './Header';
import Footer from './Footer';
import Details from './Details';
import ServiceTypeOpenClose from './ServiceTypeOpenClose';

import Settings from './Settings';
import ZoneSettings from './ZoneSettings';

import OtherSettings from './OtherSettings';

import {
  dayEstimateValueParser,
  removeTypenameValueParserInZoneSetting,
} from './helpers';
import Menus from './Menus';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Menu Service Information',
  },
];

const Form = props => {
  // const [isActiveTab, setIsActiveTab] = useState('details');
  const [activeTab, setActiveTab] = useState(0);

  const {
    loading,
    dirty,
    values,
    handleSubmit,
    setFieldValue,
    serviceTypes,
    errors,
    touched,
    isAdd,
  } = props;
  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 4 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  useEffect(() => {
    const controller = new AbortController();
    if (errors.zone_setting && touched.zone_setting) {
      toast.error(errors.zone_setting);
    }
    return () => controller.abort();
  }, [errors.zone_setting, touched.zone_setting]);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        if (errors.delivery_integration) {
          swal({ text: errors.delivery_integration, icon: 'error' });
        }
        handleSubmit();
      }}
    >
      <Header
        loading={loading}
        dirty={dirty}
        serviceType={values.service_type}
        prev={prev}
        next={next}
        activeTab={activeTab}
        values={values}
      >
        <PlacePageHeader place={props.place} placeId={values.place_id} />
      </Header>
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}

          {(lowerCase(values.service_type) === 'delivery' ||
            lowerCase(values.service_type) === 'dinein' ||
            lowerCase(values.service_type) === 'pickup') && (
            <>
              <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
                Menu Service Details
              </Tab>
            </>
          )}
          {lowerCase(values.service_type) === 'delivery' && (
            <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>
              Zone Settings
            </Tab>
          )}
          <Tab active={activeTab === 3} onClick={() => setActiveTab(3)}>
            Menus
          </Tab>
          {!(isAdd === true) && (
            <Tab active={activeTab === 4} onClick={() => setActiveTab(4)}>
              Open / Closed Status
            </Tab>
          )}
          {!(isAdd === true) &&
            (lowerCase(values.service_type) === 'delivery' ||
              lowerCase(values.service_type) === 'dinein' ||
              lowerCase(values.service_type) === 'pickup') && (
              <Tab active={activeTab === 5} onClick={() => setActiveTab(5)}>
                Other Settings
              </Tab>
            )}
        </Tabs>
        <Container>
          {values.step === 1 && (
            <Column.Group multiline gapSize={8}>
              <Column size="4">
                <Select
                  label="Select / Create Service Type Name"
                  options={serviceTypes}
                  onChange={value => {
                    if (value.value === 'custom') {
                      setFieldValue('is_custom_service', true);
                    } else {
                      setFieldValue('service_type', value.label);
                      setFieldValue('description', value.description);
                      if (lowerCase(value.label) === 'dinein') {
                        setFieldValue('display_attendee_options', true);
                      }
                    }
                    setFieldValue('step', 2);
                  }}
                  disableToastMessage
                  required
                />
              </Column>
            </Column.Group>
          )}

          {values.step === 2 && (
            <>
              {activeTab === 0 && <Details {...props} />}
              {activeTab === 1 && <Settings {...props} />}
              {activeTab === 2 && <ZoneSettings {...props} />}
              {activeTab === 3 && (
                <Menus serviceSettingId={values.service_type_setting_id} />
              )}
              {activeTab === 4 && (
                <ServiceTypeOpenClose
                  serviceSettingId={values.service_type_setting_id}
                  openClose={props.openClose}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}
              {activeTab === 5 && (
                <OtherSettings
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              )}
            </>
          )}
        </Container>
      </Box>
      <hr />
      <Footer
        loading={loading}
        dirty={dirty}
        prev={prev}
        next={next}
        values={values}
        activeTab={activeTab}
      />
    </form>
  );
};

const MenuTypeForm = withFormik({
  mapPropsToValues: ({ place, userId, setting, step, openClose, isAdd }) => {
    const primaryEmailPlace =
      !setting && place && !isNull(place.contact)
        ? place.contact.filter(item => item.type === 'email_primary')
        : [];
    const primaryMobilePlace =
      !setting && place && !isNull(place.contact)
        ? place.contact.filter(item => item.type === 'phone_primary')
        : [];
    const primaryEmail =
      setting && !isNull(setting.contact)
        ? setting.contact.filter(item => item.type === 'email_primary')
        : primaryEmailPlace;
    const primaryMobile =
      setting && !isNull(setting.contact)
        ? setting.contact.filter(item => item.type === 'phone_primary')
        : primaryMobilePlace;

    const inStore =
      setting && !isNull(setting.pickup_method)
        ? setting.pickup_method.filter(item => item === 'InStore')
        : [];

    const curbSide =
      setting && !isNull(setting.pickup_method)
        ? setting.pickup_method.filter(item => item === 'CurbSide')
        : [];

    const carPark =
      setting && !isNull(setting.pickup_method)
        ? setting.pickup_method.filter(item => item === 'Carpark')
        : [];

    const driveThru =
      setting && !isNull(setting.pickup_method)
        ? setting.pickup_method.filter(item => item === 'DriveThru')
        : [];

    return {
      is_catering_service:
        setting && !isNull(setting.is_catering_service)
          ? setting.is_catering_service
          : false,
      default_menu:
        setting && !isNull(setting.default_menu)
          ? setting.default_menu
          : 'KRAVEIN',
      addition_emails:
        setting && !isNull(setting.addition_emails)
          ? setting.addition_emails
          : [],
      platform: setting && !isNull(setting.platform) ? setting.platform : '',
      channel_link_id:
        setting && !isNull(setting.channel_link_id)
          ? setting.channel_link_id
          : '',
      not_allow_order_to_create_on_deliverect:
        setting && !isNull(setting.not_allow_order_to_create_on_deliverect)
          ? setting.not_allow_order_to_create_on_deliverect
          : false,

      primaryEmail: primaryEmail.length !== 0 ? first(primaryEmail).value : '',
      slug: place.slug,
      primaryMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      is_custom_service:
        setting && !isNull(setting.is_custom_service)
          ? setting.is_custom_service
          : false,
      step: step || 1,
      user_id: userId,
      service_type_setting_id:
        setting && !isNull(setting.service_type_setting_id)
          ? setting.service_type_setting_id
          : uuidv4(),
      place_id:
        setting && !isNull(setting.place_id)
          ? setting.place_id
          : place.place_id,
      service_type:
        setting && !isNull(setting.service_type) ? setting.service_type : '',
      is_private_menu:
        setting && !isNull(setting.is_private_menu)
          ? setting.is_private_menu
          : false,
      status: setting && !isNull(setting.status) ? setting.status : 'ACTIVE',
      description_enable:
        setting && !isNull(setting.description_enable)
          ? setting.description_enable
          : false,
      description:
        setting && !isNull(setting.description) ? setting.description : '',
      tag: setting && !isNull(setting.tag) ? setting.tag : [],
      approval_status:
        setting && !isNull(setting.approval_status)
          ? setting.approval_status
          : 'APPROVED',
      zone_setting:
        setting && !isNull(setting.zone_setting)
          ? removeTypenameValueParserInZoneSetting(setting.zone_setting)
          : [],
      day_estimate:
        setting && !isNull(setting.day_estimate)
          ? dayEstimateValueParser(setting.day_estimate)
          : [],
      payment_method:
        setting && !isNull(setting.payment_method)
          ? setting.payment_method
          : ['CARD'],
      delivery_fee:
        setting && !isNull(setting.delivery_fee)
          ? parseFloat(setting.delivery_fee).toFixed(2)
          : null,
      schedule_order_when_closed:
        setting && !isNull(setting.schedule_order_when_closed)
          ? setting.schedule_order_when_closed
          : true,
      schedule_order_duration:
        setting && !isNull(setting.schedule_order_duration)
          ? setting.schedule_order_duration
          : null,
      preparation_estimate:
        setting && !isNull(setting.preparation_estimate)
          ? setting.preparation_estimate
          : 15,
      delivery_estimate:
        setting && !isNull(setting.delivery_estimate)
          ? setting.delivery_estimate
          : 5,
      added_estimate:
        setting && !isNull(setting.added_estimate)
          ? setting.added_estimate
          : 15,
      cut_off_time:
        setting && !isNull(setting.cut_off_time) ? setting.cut_off_time : 15,
      food_setup_required:
        setting && !isNull(setting.food_setup_required)
          ? setting.food_setup_required
          : false,
      display_attendee_options:
        setting && !isNull(setting.display_attendee_options)
          ? setting.display_attendee_options
          : false,
      display_table_number:
        setting && !isNull(setting.display_table_number)
          ? setting.display_table_number
          : false,
      minimum_no_of_attendees:
        setting && !isNull(setting.minimum_no_of_attendees)
          ? setting.minimum_no_of_attendees
          : 1,
      maximum_no_of_attendees:
        setting && !isNull(setting.maximum_no_of_attendees)
          ? setting.maximum_no_of_attendees
          : 100,
      minimum_order_amount:
        setting && !isNull(setting.minimum_order_amount)
          ? parseFloat(setting.minimum_order_amount).toFixed(2)
          : parseFloat(0).toFixed(2),
      maximum_order_amount:
        setting && !isNull(setting.maximum_order_amount)
          ? parseFloat(setting.maximum_order_amount).toFixed(2)
          : parseFloat(1000).toFixed(2),
      enable_group_order:
        setting && !isNull(setting.enable_group_order)
          ? setting.enable_group_order
          : false,
      schedule_order:
        setting && !isNull(setting.schedule_order)
          ? setting.schedule_order
          : false,
      allow_pickup_options:
        setting && !isNull(setting.allow_pickup_options)
          ? setting.allow_pickup_options
          : false,
      include_vehicle_information:
        setting && !isNull(setting.include_vehicle_information)
          ? setting.include_vehicle_information
          : false,
      pickup_method:
        setting && !isNull(setting.pickup_method) ? setting.pickup_method : [],
      allow_order_cancellation:
        setting &&
        setting.allow_order_cancellation !== null &&
        setting.allow_order_cancellation !== undefined
          ? setting.allow_order_cancellation
          : true,
      pickup_instructions:
        setting && !isNull(setting.pickup_instructions)
          ? setting.pickup_instructions
          : `Please call the restaurant on ${
              primaryMobile.length !== 0 ? first(primaryMobile).value : ''
            } when you are at the pickup location`,
      driver_pickup_instruction:
        setting && !isNull(setting.driver_pickup_instruction)
          ? setting.driver_pickup_instruction
          : ``,
      inStore: inStore.length !== 0,
      curbSide: curbSide.length !== 0,
      carPark: carPark.length !== 0,
      driveThru: driveThru.length !== 0,
      image_url:
        setting && !isNull(setting.image_url) ? setting.image_url : null,
      menus: [],
      openClose,
      display_menu_hours:
        setting && !isNull(setting.display_menu_hours)
          ? setting.display_menu_hours
          : true,
      display_price:
        setting && !isNull(setting.display_price)
          ? setting.display_price
          : true,
      auto_confirm:
        setting && !isNull(setting.auto_confirm) ? setting.auto_confirm : false,
      auto_ready:
        setting && !isNull(setting.auto_ready) ? setting.auto_ready : false,
      is_auto_cancel:
        setting && !isNull(setting.auto_cancel)
          ? setting.auto_cancel.is_auto_cancel
          : false,
      time_in_mins:
        setting &&
        !isNull(setting.auto_cancel) &&
        setting.auto_cancel.time_in_mins > 2
          ? setting.auto_cancel.time_in_mins
          : 10,
      delivery_channel:
        setting && !isNull(setting.delivery_channel)
          ? setting.delivery_channel
          : 'RESTAURANT',
      delivery_integration:
        setting && !isNull(setting.delivery_integration)
          ? omit(
              {
                ...setting.delivery_integration,
                order_settings: omit(
                  setting?.delivery_integration?.order_settings,
                  '__typename',
                ),
              },
              '__typename',
            )
          : {
              order_settings: {
                customer_email: false,
                delivery_details: true,
                delivery_fees: false,
                order_items: false,
                order_number: true,
                payment_details: false,
                pickup_details: true,
                total: false,
              },
              api_key: '',
              menu_id: [],
              name: '',
            },
      random_id: '',
      isAdd, // this was added because when changing order_settings the form was not becoming dirty. not a good fix but a temp fix
    };
  },

  validate: values => {
    const errors = {};
    if (!values.image_url && !values.is_custom_service) {
      errors.image_url = 'Image is required!';
    }
    if (!values.primaryMobile) {
      errors.primaryMobile = 'Primary contact phone is required!';
    }
    if (values.primaryMobile && values.primaryMobile.length !== 0) {
      const check = isPossiblePhoneNumber(values.primaryMobile);
      if (!check) {
        errors.primaryMobile = 'Please enter valid mobile number';
      }
    }
    if (values.display_attendee_options) {
      if (!values.minimum_no_of_attendees) {
        errors.minimum_no_of_attendees = 'Minimum no of attendees is required';
      }
      if (!values.maximum_no_of_attendees) {
        errors.maximum_no_of_attendees = 'Maximum no of attendees is required';
      }
    }
    if (values.allow_pickup_options) {
      if (values.pickup_method.length === 0) {
        errors.pickup_method = 'Choose atleast one option to continue!';
      }
    }
    if (
      values.zone_setting.length === 0 &&
      values.service_type === 'Delivery'
    ) {
      errors.zone_setting = 'Zone setting is required field!';
    }

    if (
      !values.isAdd &&
      lowerCase(values.service_type) === 'delivery' &&
      values.delivery_integration.name === 'SHIPDAY' &&
      values.delivery_integration.menu_id.length === 0
    ) {
      errors.delivery_integration =
        'Atleast one menu must be added to the delivery integration settings';
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    addition_emails: yup
      .array()
      .of(yup.string().email('Please enter a valid email!')),

    service_type: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Service type name is required!')
      .matches(/^[\w\s]+$/, 'Allowed characters : A-Z, a-z, 0-9'),
    primaryEmail: yup
      .string()
      .email('Please enter valid email')
      .required('Primary contact email is required!'),
    description: yup
      .string()
      .nullable('Please enter at least 5 characters')
      .min(5, 'Please enter at least 5 characters')
      .max(288, 'Please enter not greater than 288 characters')
      .test('no-only-spaces', 'Please enter a valid service message!', value =>
        /\S/.test(value),
      ),
    // image_url: yup
    //   .string()
    //   .nullable('Image is required!')
    //   .required('Image is required!'),
    pickup_instructions: yup
      .string()
      .min(10, 'Please enter at least 10 characters')
      .max(288, 'Please enter not greater than 288 characters'),
    driver_pickup_instruction: yup
      .string()
      .min(10, 'Please enter at least 10 characters')
      .max(288, 'Please enter not greater than 288 characters'),
  }),

  handleSubmit: (values, { props }) => {
    const transform = arr => {
      const leftSet = new Set();
      const rightSet = new Set();
      return arr.filter((ele, idx) => {
        if (idx === 0) {
          leftSet.add(ele.latitude);
          rightSet.add(ele.longitude);
          return true;
        }
        if (idx === arr.length - 1) {
          return true;
        }
        if (leftSet.has(ele.latitude) && rightSet.has(ele.longitude)) {
          return false;
        }
        leftSet.add(ele.latitude);
        rightSet.add(ele.longitude);

        return true;
      });
    };

    const zoneSetting = [];
    values.zone_setting.forEach(ele => {
      if (ele.area_type === 'CIRCLE') {
        zoneSetting.push(ele);
      } else {
        const temp = { ...ele };
        temp.delivery_area.area_polygon = transform([
          ...temp.delivery_area.area_polygon,
        ]);
        zoneSetting.push(temp);
      }
    });
    props.onSubmit({
      ...values,
      zone_setting: zoneSetting,
    });
  },

  displayName: 'MenuTypeForm',
})(Form);

export default MenuTypeForm;
