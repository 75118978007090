import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  margin: 1rem 0rem;
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.lightGreyAlpha : theme.white};
`;

const Item = styled(Flex)`
  padding: 1rem 0.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.lightGreyBeta};
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.lightGreyBeta : theme.white};
`;

const IconWrapper = styled.span`
  padding-right: 1rem;
`;

const deleteItemLinkMutation = gql`
  mutation deleteItemLink($input: [NavigateMenuItemLinkInput]) {
    delete_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const updateItemLinkMutation = gql`
  mutation updateItemLink($input: [MenuItemLinkInput]) {
    update_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const Row = ({ item, index, onDelete, userId }) => {
  const [deleteItemLink, { loading }] = useMutation(deleteItemLinkMutation, {
    client: client.clientPrivate,
    variables: {
      input: [{ user_id: userId, item_link_id: item.item_link_id }],
    },
    onCompleted: () => onDelete(item.item_link_id),
  });

  return (
    <Draggable
      key={item.item_link_id}
      draggableId={item.item_link_id}
      index={index}
    >
      {(provided2, snapshot2) => (
        <Item
          ref={provided2.innerRef}
          {...provided2.draggableProps}
          {...provided2.dragHandleProps}
          isDragging={snapshot2.isDragging}
        >
          <Flex>
            <IconWrapper>
              <i className="fas fa-arrows-alt" />
            </IconWrapper>
            <p className="is-size-2 has-text-weight-semibold">{item.name}</p>
            <p style={{ fontSize: '10px', color: '#505050' }}>
              &nbsp;({item.internal_name})
            </p>
          </Flex>
          <button
            type="button"
            className={`button ${loading && 'is-loading'}`}
            onClick={() => {
              deleteItemLink();
            }}
          >
            <i className="far fa-trash-alt" />
          </button>
        </Item>
      )}
    </Draggable>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Items = ({ items, onDelete }) => {
  const { userId } = useStoreState(state => state.auth);
  const [itemsData, setItemsData] = useState(items);

  const [updateItemLink] = useMutation(updateItemLinkMutation, {
    client: client.clientPrivate,
    onCompleted: () => {},
  });

  useEffect(() => {
    setItemsData(items);
    console.log('setItemsData', items);
  }, [items]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      itemsData,
      result.source.index,
      result.destination.index,
    );
    setItemsData(newItems);
    console.log('menu_item_id', newItems);
    updateItemLink({
      variables: {
        input: newItems.map((item, index) => ({
          user_id: userId,
          menu_item_id: item.menu_item_id,
          item_link_id: item.item_link_id,
          display_order: index,
        })),
      },
    });
    console.log('result', result);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {itemsData.map((item, index) => (
              <Row
                item={item}
                index={index}
                onDelete={onDelete}
                userId={userId}
              />
            ))}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Items;
