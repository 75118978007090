import React from 'react';
import gql from 'graphql-tag';
import uuidv4 from 'uuid/v4';

import client from '../../../utils/apolloClient';

const searchMenusAndPlaceQuery = gql`
  query searchMenusAndPlaceQuery($placeId: String, $userId: String) {
    search_menus(
      input: {
        filter: {
          menu_filter: { status: "ACTIVE" }
          place_filter: { place_id: [$placeId] }
        }
        user_id: $userId
      }
    ) {
      menu_listing {
        menu_hour {
          day
          is_active
          option {
            end
            end_in_sec
            start
            start_in_sec
            type
          }
        }
        menu_id
        name
        service_type
        service_type_setting {
          service_type_setting_id
          allow_pickup_options
          delivery_estimate
          delivery_fee
          display_table_number
          display_attendee_options
          is_custom_service
          maximum_no_of_attendees
          maximum_order_amount
          minimum_no_of_attendees
          minimum_order_amount
          payment_method
          pickup_instructions
          pickup_method
          platform
          preparation_estimate
          schedule_order
          schedule_order_duration
          schedule_order_when_closed
          service_type
        }
      }
    }
    search_places(
      input: {
        user_id: $userId
        filter: { place_filter: { place_id: [$placeId] } }
      }
    ) {
      place_listing {
        address_line_1
        address_line_2
        slug
        state
        place_id
        name
        longitude
        latitude
        city
        post_code
        status
        contact {
          type
          value
          display
          display_order
          is_primary
        }
      }
    }
    search_service_type_settings(
      input: {
        filter: { place_filter: { place_id: [$placeId] } }
        user_id: $userId
      }
    ) {
      service_type_setting_listing {
        service_type_setting_id
        service_type
      }
    }
  }
`;

export const fetchMenus = async ({ placeId, userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchMenusAndPlaceQuery,
      variables: {
        placeId,
        userId,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_menus &&
      Array.isArray(data.data.search_menus?.menu_listing) &&
      data?.data?.search_places &&
      Array.isArray(data.data.search_places?.place_listing) &&
      data?.data?.search_service_type_settings &&
      Array.isArray(
        data.data.search_service_type_settings?.service_type_setting_listing,
      )
    ) {
      return {
        menus: data.data.search_menus.menu_listing.filter(ele => ele.menu_hour),
        place: data.data.search_places.place_listing[0],
        serviceTypes:
          data.data.search_service_type_settings.service_type_setting_listing,
      };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const searchTableMenuQuery = gql`
  query searchTableMenuQuery($placeId: String, $id: String) {
    search_table_menu(
      input: {
        filter: {
          table_menu_filter: {
            place_id: $placeId
            service_type_setting_id: $id
          }
        }
      }
    ) {
      total_pages
      total_size
      table_menu_listing {
        user_id
        place_id
        service_type_setting_id
        table_menu_id
        menu_id
      }
    }
  }
`;

export const fetchTableMenu = async ({ placeId, serviceTypeSettingId }) => {
  try {
    const data = await client.clientPublic.query({
      client: client.clientPrivate,
      query: searchTableMenuQuery,
      variables: {
        placeId,
        id: serviceTypeSettingId,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_menu?.table_menu_listing &&
      Array.isArray(data.data.search_table_menu.table_menu_listing) &&
      data.data.search_table_menu.table_menu_listing[0]
    ) {
      return data.data.search_table_menu.table_menu_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// notificaiton_setting {
//   email_user_cancel_booking
//   email_user_confirm_booking
//   email_venue_cancel_booking
//   email_venue_new_booking
//   sms_user_confirm_booking
// }

const fetchTableBookingSettingQuery = gql`
  query searchTableSetting($input: SearchInput) {
    search_table_setting(input: $input) {
      table_setting_listing {
        allow_booking_cancel
        booking_cut_off_time
        allow_booking_change

        place {
          city
          state
          post_code
          status
        }
        booking_hours {
          day
          is_active
          option {
            end
            end_in_sec
            start
            start_in_sec
            type
          }
        }
        booking_range_min
        booking_message
        contact {
          display
          display_order
          is_primary
          type
          value
        }
        table_details {
          table_name
          table_capacity
          table_number
        }
        display_booking_message
        image_url
        max_booking_per_slot
        max_guests_per_booking
        max_guests_per_slot
        min_guests_per_booking
        minimum_lead_time_min
        place_id
        publish_booking
        schedule_order_when_closed
        service_type
        service_type_setting_id
        status
        advance_booking_days
        auto_cancel {
          time_in_mins
          is_auto_cancel
        }
        auto_confirm
        allow_user_pre_order
        menu_id
        tag
      }
    }
  }
`;

export const fetchTableSetting = async ({ placeId }) => {
  try {
    const data = await client.clientPublic.query({
      client: client.clientPublic,
      query: fetchTableBookingSettingQuery,
      variables: {
        input: {
          filter: {
            service_type_setting_filter: {
              place_id: placeId,
              service_type: 'Table booking',
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_setting &&
      Array.isArray(data.data.search_table_setting?.table_setting_listing) &&
      data.data.search_table_setting?.table_setting_listing.length !== 0
    ) {
      return data.data.search_table_setting.table_setting_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useTableSetting = ({ placeId }) => {
  const [tableSettingObj, setTableSettingObj] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setTableSettingObj(null);
      const res = await fetchTableSetting({ placeId });
      if (res) {
        setTableSettingObj(res);
      } else {
        // pass
      }
    };
    fetchFunc();
  }, [placeId]);
  return [tableSettingObj];
};

const fetchTableBookingCustomHoursQuery = gql`
  query fetchTableBookingCustomHoursQuery($input: SearchInput) {
    search_table_setting(input: $input) {
      table_setting_listing {
        custom_hour {
          close_all_day
          date
          day
          is_active
          option {
            end
            end_in_sec
            start
            start_in_sec
            type
          }
        }
      }
    }
  }
`;

export const fetchTableBookingCustomHours = async ({ placeId }) => {
  try {
    const data = await client.clientPublic.query({
      client: client.clientPublic,
      query: fetchTableBookingCustomHoursQuery,
      variables: {
        input: {
          filter: {
            service_type_setting_filter: {
              place_id: placeId,
              service_type: 'Table booking',
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_setting &&
      Array.isArray(data.data.search_table_setting?.table_setting_listing) &&
      data.data.search_table_setting?.table_setting_listing.length !== 0 &&
      Array.isArray(
        data.data.search_table_setting.table_setting_listing[0].custom_hour,
      )
    ) {
      return data.data.search_table_setting.table_setting_listing[0]
        .custom_hour;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useTableSettingCustomHours = ({ placeId }) => {
  const [customHoursFromSettings, setCustomHoursFromSettings] = React.useState(
    [],
  );
  const [
    customHoursFromSettingsLoading,
    setCustomHoursFromSettingsLoading,
  ] = React.useState(false);

  const [refetchDataFlag, setRefetchDataFlag] = React.useState();

  const refetchDataFunc = () => {
    setRefetchDataFlag(uuidv4());
  };

  React.useEffect(() => {
    const fetchFunc = async () => {
      setCustomHoursFromSettingsLoading(true);
      setCustomHoursFromSettings([]);
      const res = await fetchTableBookingCustomHours({ placeId });
      if (res) {
        setCustomHoursFromSettings(res);
      } else {
        // pass
      }
      setCustomHoursFromSettingsLoading(false);
    };
    fetchFunc();
  }, [placeId, refetchDataFlag]);

  return [
    customHoursFromSettings,
    customHoursFromSettingsLoading,
    refetchDataFunc,
  ];
};

const deleteCustomHourMutation = gql`
  mutation updateCustomHourMutation($input: TableSettingInput) {
    update_table_setting(input: $input) {
      error {
        code
        description
      }
    }
  }
`;

export const deleteCustomHour = async ({ input }) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: deleteCustomHourMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.create_table_setting?.error) &&
      res.data.create_table_setting.error[0]
    ) {
      return {
        error: res.data.create_table_setting.error[0]?.description,
        success: false,
      };
    }
    return {
      error: null,
      success: true,
    };
  } catch {
    return {
      error: 'Oops! Something went wrong.',
      success: false,
    };
  }
};

const createTableSettingMutation = gql`
  mutation createTableSettingMutation($input: TableSettingInput) {
    create_table_setting(input: $input) {
      error {
        code
        description
        object {
          id
          name
          type
        }
      }
    }
  }
`;

export const createTableSetting = async ({ input }) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createTableSettingMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.create_table_setting?.error) &&
      res.data.create_table_setting.error[0]
    ) {
      return res.data.create_table_setting.error[0]?.description;
    }
    return true;
  } catch (error) {
    return null;
  }
};

const updateTableSettingMutation = gql`
  mutation createTableSettingMutation($input: TableSettingInput) {
    update_table_setting(input: $input) {
      error {
        code
        description
        object {
          id
          name
          type
        }
      }
    }
  }
`;

export const updateTableSetting = async ({ input }) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateTableSettingMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.update_table_setting?.error) &&
      res.data.update_table_setting.error[0]
    ) {
      return res.data.update_table_setting.error[0]?.description;
    }
    return true;
  } catch (error) {
    return null;
  }
};

// {

// }

const createTableMenuMutation = gql`
  mutation createTableMenuMutation($input: TableMenuInput) {
    create_table_menu(input: $input) {
      user_id
      table_menu_id
      place_id
      service_type_setting_id
      menu_id
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      error {
        code
        description
      }
    }
  }
`;

// error {
//   code
//   description
//   object
// }

export const createTableMenu = async ({
  userId,
  placeId,
  serviceTypeSettingId,
  menuId,
}) => {
  const input = {};

  Object.assign(input, {
    user_id: userId,
    place_id: placeId,
    service_type_setting_id: serviceTypeSettingId,
    menu_id: menuId,
  });

  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createTableMenuMutation,
      variables: {
        input,
      },
    });

    if (
      res?.data?.create_table_menu?.error &&
      Array.isArray(res.data.create_table_menu.error) &&
      res.data.create_table_menu.error[0]
    ) {
      return {
        isError: true,
        error: res.data.create_table_menu.error[0].description,
      };
    }

    return { isError: false, error: null };
  } catch {
    return {
      isError: true,
      error:
        "Oops! Something didn't work as expected. Please contact support if the issue persists.",
    };
  }
};

const updateTableMenuMutation = gql`
  mutation updateTableMenuMutation($input: TableMenuInput) {
    update_table_menu(input: $input) {
      user_id
      table_menu_id
      place_id
      service_type_setting_id
      menu_id
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      error {
        code
        description
      }
    }
  }
`;

export const updateTableMenu = async ({
  userId,
  placeId,
  serviceTypeSettingId,
  menuId,
  tableMenuId,
}) => {
  const input = {};

  Object.assign(input, {
    user_id: userId,
    place_id: placeId,
    service_type_setting_id: serviceTypeSettingId,
    menu_id: menuId,
    table_menu_id: tableMenuId,
  });

  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateTableMenuMutation,
      variables: {
        input,
      },
    });

    if (
      res?.data?.update_table_menu?.error &&
      Array.isArray(res.data.update_table_menu.error) &&
      res.data.update_table_menu.error[0]
    ) {
      return {
        isError: true,
        error: res.data.update_table_menu.error[0].description,
      };
    }

    return { isError: false, error: null };
  } catch {
    return {
      isError: true,
      error:
        "Oops! Something didn't work as expected. Please contact support if the issue persists.",
    };
  }
};

export const saveTableMenu = async ({
  userId,
  placeId,
  serviceTypeSettingId,
  menuId,
  tableMenuId,
  isUpdate,
}) => {
  if (isUpdate) {
    const res1 = await updateTableMenu({
      userId,
      placeId,
      serviceTypeSettingId,
      menuId,
      tableMenuId,
    });

    return res1;
  }

  const res2 = await createTableMenu({
    userId,
    placeId,
    serviceTypeSettingId,
    menuId,
  });

  return res2;
};

export default {};
