import React from 'react';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';

// import ListingDetails from './ListingDetails';
// import MenuDetails from './MenuDetails';
import { useBankDetails, usePlaceData } from './helpers';

import Form from './Form';

const OnboardPlace = ({ match, history }) => {
  const { userId, user } = useStoreState(state => state.auth);

  const [fetchPlaceLoading, fetchPlaceError, fetchPlaceData] = usePlaceData({
    placeId: match.params.placeId,
  });

  const [bankDetailsLoading, bankDetails, ,] = useBankDetails({
    placeId: match.params.placeId,
  });

  console.log('bankingdetails...', {
    bankDetailsLoading,
    bankDetails,
  });

  if (fetchPlaceLoading) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: '#00AEEF',
            fontSize: '22px',
          }}
        >
          Loading Place Data...
        </span>
      </Layout>
    );
  }

  if (fetchPlaceError) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: 'red',
            fontSize: '22px',
          }}
        >
          Error Loading Place Data!
        </span>
      </Layout>
    );
  }

  if (bankDetailsLoading) {
    return (
      <Layout>
        <span
          style={{
            padding: '2rem',
            color: '#00AEEF',
            fontSize: '22px',
          }}
        >
          Loading Bank Details Data...
        </span>
      </Layout>
    );
  }

  if (fetchPlaceData) {
    return (
      <Layout>
        <Form
          user={user}
          history={history}
          userId={userId}
          place={fetchPlaceData}
          isUpdate={!!bankDetails?.acc_bsb_number}
          bankDetails={bankDetails}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <span
        style={{
          padding: '2rem',
          color: '#00AEEF',
          fontSize: '22px',
        }}
      >
        No Data!
      </span>
    </Layout>
  );
};

export default OnboardPlace;
