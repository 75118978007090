import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../../components/global/Layout';
import Details from './Details';
import client from '../../../utils/apolloClient';
import { Loading, Message } from '../../../components/elements';

const searchOnboardingSetupQuery = gql`
  query searchOnboardingSetupQuery {
    search_onboarding_setup(input: {}) {
      total_pages
      total_size
      onboarding_setup_data_listing {
        user_id
        onboarding_setup_id
        subscriptions {
          name
          fee_type
          value
        }
        fee {
          name
          fee_type
          value
        }
        error {
          code
          description
          object {
            id
            type
            name
          }
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const OnboardingSetup = () => {
  const { data, loading, error, refetch } = useQuery(
    searchOnboardingSetupQuery,
    {
      variables: {},
      client: client.clientPublic,
    },
  );

  console.log('data', data, loading);

  return (
    <Layout>
      {error && <Message>{error.message}</Message>}
      {loading && <Loading />}
      {!loading && !error && (
        <Details
          onboardingSetupId={
            data?.search_onboarding_setup?.onboarding_setup_data_listing &&
            Array.isArray(
              data.search_onboarding_setup.onboarding_setup_data_listing,
            ) &&
            data.search_onboarding_setup.onboarding_setup_data_listing[0] &&
            data.search_onboarding_setup.onboarding_setup_data_listing[0]
              .onboarding_setup_id
          }
          onboardingSetupSubscriptionsData={(() => {
            if (
              data?.search_onboarding_setup?.onboarding_setup_data_listing &&
              Array.isArray(
                data.search_onboarding_setup.onboarding_setup_data_listing,
              ) &&
              data.search_onboarding_setup.onboarding_setup_data_listing[0] &&
              data.search_onboarding_setup.onboarding_setup_data_listing[0]
                .subscriptions &&
              Array.isArray(
                data.search_onboarding_setup.onboarding_setup_data_listing[0]
                  .subscriptions,
              )
            ) {
              return data.search_onboarding_setup
                .onboarding_setup_data_listing[0].subscriptions;
            }
            return null;
          })()}
          onboardingSetupFeesData={(() => {
            if (
              data?.search_onboarding_setup?.onboarding_setup_data_listing &&
              Array.isArray(
                data.search_onboarding_setup.onboarding_setup_data_listing,
              ) &&
              data.search_onboarding_setup.onboarding_setup_data_listing[0] &&
              data.search_onboarding_setup.onboarding_setup_data_listing[0]
                .fee &&
              Array.isArray(
                data.search_onboarding_setup.onboarding_setup_data_listing[0]
                  .fee,
              )
            ) {
              return data.search_onboarding_setup
                .onboarding_setup_data_listing[0].fee;
            }
            return null;
          })()}
          refetch={refetch}
        />
      )}
    </Layout>
  );
};

export default OnboardingSetup;
