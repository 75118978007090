import React from 'react';

import { Label } from 'rbx';
import BasicTextEditor from './BasicTextEditor';

const CancellationTerms = ({ values, setFieldValue, errors, touched }) => (
  <div className="m-4">
    <Label>Cancellation Terms: Terms & Conditions</Label>
    <BasicTextEditor
      disabled={!values.cancel_booking}
      name="booking_message"
      value={values.cancellation_terms_and_condition}
      handleChange={e => setFieldValue('cancellation_terms_and_condition', e)}
      // onBlur={handleBlur}
      errors={errors.cancellation_terms_and_condition}
      touched={touched.cancellation_terms_and_condition}
      disableToastMessage
      optionalText="(Min 10 and Max 772 characters)"
    />
    {values.cancellation_terms_and_condition && (
      <div style={{ textAlign: 'right' }}>
        {values.cancellation_terms_and_condition.length > 772 ||
        values.cancellation_terms_and_condition.length < 10
          ? ''
          : `Characters left ${772 -
              values.cancellation_terms_and_condition.length}`}
      </div>
    )}
  </div>
);

export default CancellationTerms;
