/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';

import { Table as CustomTable, Box } from '../../../components/elements';

import Header from './Header';
import List from './List';
import AddEditForm from './AddEditForm';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

// const createOnboardingSetupMutation = gql`
//   mutation createOnboardingSetupMutation($input: CreateOnboardingSetupInput) {
//     create_onboarding_setup(input: $input) {
//       onboarding_setup_id
//       user_id
//       fee {
//         name
//         fee_type
//         value
//       }
//       subscriptions {
//         subscription_type
//         fee_type
//         value
//       }
//       error {
//         code
//         description
//       }
//       audit {
//         created_at
//         created_by
//         updated_at
//         updated_by
//       }
//     }
//   }
// `;

const updateOnboardingSetupMutation = gql`
  mutation updateOnboardingSetupMutation($input: OnboardingSetupInput) {
    update_onboarding_setup(input: $input) {
      onboarding_setup_id
      user_id
      fee {
        name
        fee_type
        value
      }
      subscriptions {
        name
        fee_type
        value
      }
      error {
        code
        description
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const calculateTValue = (type, value) =>
  ({
    PRICE: `$${parseFloat(value).toFixed(2)}`,
    PERCENTAGE: `${value}%`,
  }[type]);

const Details = ({
  onboardingSetupId,
  onboardingSetupSubscriptionsData,
  onboardingSetupFeesData,
  refetch,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [fee, setFee] = useState(null);
  const [isActiveModal, setIsActiveModal] = useState(false);

  const subscriptions = onboardingSetupSubscriptionsData
    ? onboardingSetupSubscriptionsData.map(ele => ({
        ...ele,
        tValue: calculateTValue(ele.fee_type, ele.value),
      }))
    : [];

  const fees = onboardingSetupFeesData
    ? onboardingSetupFeesData.map(ele => ({
        ...ele,
        tValue: calculateTValue(ele.fee_type, ele.value),
      }))
    : [];

  const allFees = [...fees, ...subscriptions];

  // const [createTax, { loading }] = useMutation(createTaxMutation, {
  //   client: client.clientPrivate,
  //   onCompleted: ({ create_tax }) => {
  //     if (!isNil(create_tax.error)) {
  //       create_tax.error.map(item => toast.error(item.description));
  //     } else {
  //       setIsActiveModal(false);
  //       refetch();
  //     }
  //   },
  // });

  const [updateOnboardingSetup, { loading: loading2 }] = useMutation(
    updateOnboardingSetupMutation,
    {
      client: client.clientPrivate,
      onCompleted: ({ update_onboarding_setup }) => {
        if (!isNil(update_onboarding_setup.error)) {
          update_onboarding_setup.error.map(item =>
            toast.error(item.description),
          );
        } else {
          setIsActiveModal(false);
          refetch();
          setFee(null);
        }
      },
      onError: () => {
        toast.error(
          "Oops! Something didn't work as expected. Please contact support if the issue persists.",
        );
      },
    },
  );

  return (
    <React.Fragment>
      <Header setFee={setFee} setIsActiveModal={setIsActiveModal} />
      <Box>
        <Container>
          <CustomTable>
            <Table.Head>
              <Table.Row>
                <Table.Heading>Name</Table.Heading>
                <Table.Heading>Fee Type</Table.Heading>
                <Table.Heading>Value</Table.Heading>
                <Table.Heading>Actions</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                allFees={allFees}
                onEdit={value => {
                  setFee(value);
                  setIsActiveModal(true);
                }}
              />
            </Table.Body>
          </CustomTable>
        </Container>
      </Box>
      {isActiveModal && (
        <AddEditForm
          fee={fee}
          loading={loading2}
          isActive={isActiveModal}
          onClose={() => setIsActiveModal(false)}
          onSubmit={async values => {
            const input = {
              user_id: userId,
              onboarding_setup_id: onboardingSetupId,
            };

            input.fee = fees.map(ele => {
              const temp = { ...ele };
              delete temp.tValue;
              delete temp.__typename;
              if (ele.name === values.name) {
                temp.value = parseFloat(values.value);
                temp.fee_type = values.type;
              }
              return temp;
            });

            input.subscriptions = subscriptions.map(ele => {
              const temp = { ...ele };
              delete temp.tValue;
              delete temp.__typename;
              if (ele.name === values.name) {
                temp.value = parseFloat(values.value);
                temp.fee_type = values.type;
              }
              return temp;
            });

            await updateOnboardingSetup({
              variables: {
                input,
              },
            });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Details;
