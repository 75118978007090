/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { sortBy } from 'lodash';
import client from '../../../../utils/apolloClient';

import { getAllModifierGroups } from '../../Item/helpers';

const searchMenuCategories = gql`
  query searchMenusQuery($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        menu_id
        menu_section {
          place_id
          menu_section_id
          display_order
          menu_id
          name
          description
          status
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getAllCategories = ({ user_id, place_id, menu_id }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchMenuCategories,
        variables: {
          input: {
            sort: 'UPDATED_DESC',
            size: 100,
            filter: {
              place_filter: { place_id },
            },
            user_id,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menus &&
          Array.isArray(data.search_menus.menu_listing)
        ) {
          const filteredObj = data.search_menus.menu_listing.find(
            ele => ele.menu_id === menu_id,
          );
          if (filteredObj && Array.isArray(filteredObj.menu_section)) {
            resolve(filteredObj.menu_section);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const searchSectionsSettingsQuery = gql`
  query searchSections($input: SearchInput) {
    search_menu_sections(input: $input) {
      menu_section_listing {
        menu_section_id
        place_id
        menu_id
        name
      }
    }
  }
`;

const updateServiceMutation = gql`
  mutation updateService($input: [MenuSectionInput]) {
    update_menu_section(input: $input) {
      menu_section_id
      name
      error {
        description
      }
    }
  }
`;

const fetchmenuSectionDetails = gql`
  query fetchmenuSectionDetails($input: NavigateMenuSectionInput) {
    fetch_menu_section(input: $input) {
      menu_id
    }
  }
`;

export const fetchAllCategoriesOfThePlace = ({
  user_id,
  place_id,
  query = '',
}) => {
  const input = {
    user_id,
    size: 50,
    from: 0,
    sort: 'UPDATED_DESC',
  };
  const menu_section_filter = { status: 'ACTIVE' };
  Object.assign(menu_section_filter, query !== '' && { name: query });
  Object.assign(input, {
    filter: { place_filter: { place_id }, menu_section_filter },
  });
  return new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchSectionsSettingsQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_sections &&
          data.search_menu_sections.menu_section_listing
        ) {
          resolve(data.search_menu_sections.menu_section_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const addCategory = async ({ user_id, menu_id, menu_section_id }) => {
  const input = { menu_id, menu_section_id, user_id };
  await client.clientPrivate.mutate({
    client: client.clientPrivate,
    mutation: updateServiceMutation,
    variables: {
      input: [input],
    },
  });
};

export const menuSectionDetails = async ({ menu_section_id }) => {
  const input = { menu_section_id };
  return new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: fetchmenuSectionDetails,
        variables: {
          input,
        },
      })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.fetch_menu_section &&
          response.data.fetch_menu_section.menu_id
        ) {
          resolve(response.data.fetch_menu_section.menu_id);
        } else {
          resolve([]);
        }
      })
      .catch(error => reject(error));
  });
};

export const createItemLinkMutation = gql`
  mutation createItemLink($input: [MenuItemLinkInput]) {
    create_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const createMenuSectionsMutationMutation = gql`
  mutation createMenuSectionsMutation($input: MenuSectionInput) {
    create_menu_section(input: $input) {
      place_id
      menu_id
      menu_section_id
      error {
        description
      }
    }
  }
`;

export const createNewMenuSection = async ({ input }) =>
  client.clientPrivate.mutate({
    client: client.clientPrivate,
    mutation: createMenuSectionsMutationMutation,
    variables: {
      input,
    },
  });

const menuItemQuery = gql`
  query menuItem($input: NavigateMenuItemInput) {
    fetch_menu_item(input: $input) {
      menu_item_id
      modifier_group_id
      variant {
        prompt_id
        prompt
        variants {
          variant_id
          variant_name
          variant_price
          availability_status
        }
      }
      popular
      allow_item_review
      availability_status
    }
  }
`;

export const getMenuItem = menuItemId =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: menuItemQuery,
        variables: {
          input: {
            menu_item_id: menuItemId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        resolve(data.fetch_menu_item);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchSectionsAndLinksQuery = gql`
  query searchMenus($input: SearchInput, $linkInput: SearchInput) {
    search_menu_section(input: $input) {
      menu_section_listing {
        menu_section_id
        name
        menu_id
        display_order
      }
    }
    search_menu_item_links(input: $linkInput) {
      menu_item_link_listing {
        item_link_id
        display_order

        availability_date
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_price

            availability_date
          }
        }
        modifier {
          modifier_group_id
          menu_item_id
          menu_item {
            menu_item_id
          }
          override_price
          override_variant {
            prompt_id
            prompt
            variants {
              variant_id
              variant_price

              availability_date
            }
          }

          availability_date
          min_qty
          max_qty
          display_order
        }
        menu_section {
          menu_section_id
        }
        menu_item {
          menu_item_id
          name
          price
          image_url
          item_id
          description
          calories
          kilojoules
          popular
          allow_item_review
          availability_status
          modifier_group_id
          availability_date
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
              availability_date
            }
          }
        }
      }
    }
  }
`;

export const getSectionsAndLinks = ({ menuId, placeId, query }) =>
  new Promise((resolve, reject) => {
    const menu_item_link_filter = { status: 'ACTIVE' };
    Object.assign(menu_item_link_filter, menuId && { menu_id: menuId });
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchSectionsAndLinksQuery,
        variables: {
          input: {
            size: 30,
            filter: {
              menu_section_filter: {
                menu_id: menuId,
                place_id: placeId,
              },
            },
          },
          linkInput: {
            size: 200,
            search_criteria: query || '',
            filter: { menu_item_link_filter },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_section &&
          data.search_menu_item_links &&
          data.search_menu_section.menu_section_listing &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve({
            sections: sortBy(
              data.search_menu_section.menu_section_listing,
              'display_order',
            ),
            links: data.search_menu_item_links.menu_item_link_listing,
          });
        } else {
          resolve({ sections: [], links: [] });
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const fetchSectionsData = async (placeId, menuId) => {
  try {
    const data = await getSectionsAndLinks({ client, placeId, menuId });
    const mergeLinksInSections = data.sections.map(item => ({
      ...item,
      items: data.links.filter(
        item2 =>
          item2.menu_section &&
          item2.menu_section.menu_section_id === item.menu_section_id,
      ),
    }));
    const finalList = mergeLinksInSections.filter(
      item => item.items.length !== 0,
    );
    return finalList;
  } catch (err) {
    console.log(err);
  }
};

export const countDecimals = value => {
  if (Math.floor(value) !== value) {
    if (
      value.toString().split('.')[1] &&
      value.toString().split('.')[1].length
    ) {
      return value.toString().split('.')[1].length;
    }
  }
  return 0;
};

export const getDataFromVariant = variant => {
  let data;
  if (Array.isArray(variant) && variant[0]) {
    const vs = variant[0].variants;
    data = Array.isArray(vs) && vs.length !== 0 ? vs : [];
  } else {
    data = [];
  }
  return data;
};

const updateItemLinkMutation = gql`
  mutation updateItemLink($input: [MenuItemLinkInput]) {
    update_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

export const updateItemLink = async ({ menuItemLinkInput }) => {
  const res = await client.clientPrivate.mutate({
    client: client.clientPrivate,
    mutation: updateItemLinkMutation,
    variables: {
      input: [menuItemLinkInput],
    },
  });
  return res.data.update_menu_item_link[0].item_link_id;
};

export const editDispatchEnums = {
  START_EDIT_CATEGORIES: 'START_EDIT_CATEGORIES',
  STOP_EDIT_CATEGORIES: 'STOP_EDIT_CATEGORIES',
  START_EDIT_MENU_ITEMS: 'START_EDIT_MENU_ITEMS',
  STOP_EDIT_MENU_ITEMS: 'STOP_EDIT_MENU_ITEMS',
};

export const checkItemVariant = value => Array.isArray(value) && value[0];

// item details form data calculations

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        item_link_id
        display_order
        availability_date
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_price
            variant_name
            availability_date
          }
        }
        menu_section {
          menu_section_id
        }
        menu_item {
          menu_item_id
          name
          price
          image_url
          item_id
          description
          calories
          kilojoules
          popular
          allow_item_review
          availability_status
          modifier_group_id
          availability_date
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
              availability_date
            }
          }
        }
      }
    }
  }
`;

export const fetchMenuLinks = ({ menuId, menuSectionId, menuItemId }) =>
  new Promise((resolve, reject) => {
    const menu_item_link_filter = { status: 'ACTIVE', menu_id: menuId };
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchMenusQuery,
        variables: {
          input: {
            search_criteria: '',
            filter: {
              menu_item_link_filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_item_links &&
          Array.isArray(data.search_menu_item_links.menu_item_link_listing) &&
          data.search_menu_item_links.menu_item_link_listing.length !== 0
        ) {
          let links = data.search_menu_item_links.menu_item_link_listing;
          links = links.filter(
            lnk =>
              lnk.menu_section.menu_section_id === menuSectionId &&
              lnk.menu_item.menu_item_id === menuItemId,
          );
          resolve(links);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const getMenuLinks = async ({ menuId, menuSectionId, menuItemId }) => {
  try {
    const menuLinks = await fetchMenuLinks({
      menuId,
      menuSectionId,
      menuItemId,
    });
    return menuLinks;
  } catch (error) {
    console.log('getMenuLinks', error);
  }
};

const getVariantLevelData = (variant, overrideVariant) => {
  const condition = value =>
    !!(
      value &&
      Array.isArray(value) &&
      value[0] &&
      value[0].variants &&
      Array.isArray(value[0].variants)
    );
  const temp = [];
  let minBasePrice;
  let minOverridePrice;
  if (condition(variant)) {
    variant[0].variants.forEach(ele1 => {
      const { variant_name: name } = ele1;
      const { variant_price: basePrice } = ele1;
      let overridePrice;

      const ov =
        condition(overrideVariant) &&
        overrideVariant[0].variants.find(
          ele => ele1.variant_id === ele.variant_id,
        );

      if (ov) {
        overridePrice = ov.override_price;
      }

      if (!minBasePrice) {
        minBasePrice = basePrice;
        minOverridePrice = overridePrice;
      } else if (parseFloat(basePrice) < parseFloat(minBasePrice)) {
        minBasePrice = basePrice;
        minOverridePrice = overridePrice;
      }
      temp.push({ name, basePrice, overridePrice });
    });
  }
  return { variantCostDetails: temp, minBasePrice, minOverridePrice };
};

export const transformModifier = modifierObj => {
  let { name: prompt } = modifierObj;
  prompt = `${prompt} (M)`;
  const { is_required, selection_quantity } = modifierObj;
  let subText;
  if (is_required && selection_quantity) {
    subText =
      selection_quantity === 1
        ? 'Required : 1 Option'
        : `Required : ${selection_quantity} Options`;
  } else if (!is_required && selection_quantity) {
    subText =
      selection_quantity === 1
        ? 'Optional : 1 Option'
        : `Optional : ${selection_quantity} Options`;
  }

  const temp1 = [];
  const { modifier } = modifierObj;
  if (modifier && Array.isArray(modifier)) {
    modifier.forEach(ele1 => {
      let basePrice = ele1.menu_item.price;
      const { name, variant } = ele1.menu_item;
      let { override_price: overridePrice } = ele1;
      const { override_variant: overrideVariant } = ele1;
      if (variant && variant[0]) {
        const {
          variantCostDetails,
          minBasePrice,
          minOverridePrice,
        } = getVariantLevelData(variant, overrideVariant);
        basePrice = minBasePrice;
        overridePrice = minOverridePrice;
        temp1.push({
          name,
          basePrice,
          overridePrice,
          variant: {
            prompt: variant[0].prompt,
            costDetails: variantCostDetails,
          },
        });
      } else {
        temp1.push({
          name,
          basePrice,
          overridePrice,
        });
      }
    });
  }
  return {
    prompt,
    type: 'modifier',
    subText,
    costDetails: temp1,
    apiObject: null,
  };
};

export const getVariantDataFromLink = link => {
  const { menu_item, variant } = link;
  const { menu_item_id } = menu_item;
  if (
    menu_item &&
    menu_item.variant &&
    Array.isArray(menu_item.variant) &&
    menu_item.variant[0]
  ) {
    const { prompt } = menu_item.variant[0];
    const { prompt_id } = menu_item.variant[0];
    const itemName = menu_item.name;
    const temp1 = [];
    const temp2 = [];
    menu_item.variant[0].variants.forEach(ele => {
      const { variant_name, variant_id } = ele;
      let { variant_price } = ele;
      const basePrice = variant_price;
      let overridePrice;
      if (
        variant &&
        Array.isArray(variant) &&
        variant[0] &&
        variant[0].variants
      ) {
        overridePrice = variant[0].variants.find(
          v => v.variant_id === variant_id,
        );
      }
      if (overridePrice) {
        variant_price = overridePrice.variant_price;
      } else {
        variant_price = null;
      }
      temp1.push({
        menuItemId: menu_item_id,
        name: variant_name,
        id: variant_id,
        basePrice,
        overridePrice: overridePrice?.variant_price,
      });
      temp2.push({ variant_name, variant_id, variant_price });
    });
    return {
      prompt: `${prompt} (V)`,
      type: 'variant',
      costDetails: temp1,
      apiObj: {
        item_link_id: link.item_link_id,
        menu_item_id: menu_item.menu_item_id,
        itemName,
        mergedVariant: { prompt, prompt_id, variants: temp2 },
        menuItemVariant: menu_item.variant,
      },
    };
  }
};

export const getItemDetailsFormData = async (
  menuItemId,
  setItemDetailsFormDataLoading,
  menuId,
  menuSectionId,
) => {
  setItemDetailsFormDataLoading(true);
  const sortGroups = (modifier_group_id, groups) => {
    const temp = [];
    modifier_group_id.forEach(edge => {
      const data = groups.filter(
        item2 => edge && edge === item2.modifier_group_id,
      );
      if (edge && data.length !== 0) {
        temp.push(data[0]);
      }
    });
    return temp;
  };
  try {
    const links = await getMenuLinks({ menuId, menuSectionId, menuItemId });
    const groups = await getAllModifierGroups({
      modifier_group_id: links[0].menu_item.modifier_group_id,
    });
    const sortedGroups = sortGroups(
      links[0].menu_item.modifier_group_id,
      groups,
    );
    const variantData = getVariantDataFromLink(links[0]);
    const modifiersData = sortedGroups.map(transformModifier);
    let finalData = [];
    if (variantData) {
      finalData = [variantData, ...modifiersData];
    } else {
      finalData = [...modifiersData];
    }
    setItemDetailsFormDataLoading(false);
    return finalData;
  } catch (error) {
    console.log(error);
    setItemDetailsFormDataLoading(false);
  }
};

const updateServiceTypeSettingMutation = gql`
  mutation updateServiceTypeSetting($input: ServiceTypeSettingInput) {
    update_service_type_setting(input: $input) {
      service_type_setting_id
      error {
        description
      }
    }
  }
`;

export const updateServiceTypeSetting = async ({ input }) => {
  try {
    client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateServiceTypeSettingMutation,
      variables: {
        input,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const searchMenuQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        publish_menu
        menu_id
      }
    }
  }
`;

export const fetchMenuListingAndSetState = async ({
  serviceTypeSettingId,
  userId,
  setMenuListing,
  // setMenuListingLoading,
}) => {
  // setMenuListingLoading(true);
  const input = {
    user_id: userId,
    filter: {
      menu_filter: {
        status: 'ACTIVE',
        service_type_setting_id: serviceTypeSettingId,
      },
    },
  };

  try {
    const { data } = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchMenuQuery,
      variables: { input },
      fetchPolicy: 'network-only',
    });
    if (Array.isArray(data?.search_menus?.menu_listing)) {
      const menuListing = data.search_menus.menu_listing;
      setMenuListing(menuListing);
      // setMenuListingLoading(false);
    } else {
      setMenuListing([]);
      // setMenuListingLoading(false);
    }
  } catch (error) {
    console.log(error);
    setMenuListing([]);
    // setMenuListingLoading(false);
  }
};
