import React from 'react';
import { Column } from 'rbx';
import { withFormik } from 'formik';
// import styled from 'styled-components';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';

import { Input } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

import { submitBankDetails } from './helpers';

const BankDetailsForm = props => {
  const {
    isSubmitting,
    handleSubmit,
    place,
    history,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = props;

  return (
    <>
      <Header
        history={history}
        loading={isSubmitting}
        handleSubmit={handleSubmit}
        place={{
          name: place.name,
          state: place.state,
          city: place.city,
          post_code: place.post_code,
          claim_status: place.claim_status,
          status: place.status,
        }}
      />
      <Column>
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={8}>
            <Input
              label="Account Name (exactly as it appears on your bank account)"
              name="acc_name"
              value={values.acc_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.acc_name && touched.acc_name && 'is-danger'}
              errors={errors.acc_name}
              touched={touched.acc_name}
              disableToastMessage
              required
            />
          </Column>
        </Column.Group>
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={4}>
            <Input
              label="BSB Number"
              name="acc_bsb_number"
              value={values.acc_bsb_number}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.acc_bsb_number && touched.acc_bsb_number && 'is-danger'
              }
              errors={errors.acc_bsb_number}
              touched={touched.acc_bsb_number}
              disableToastMessage
              required
            />
          </Column>
          <Column size={4}>&nbsp;</Column>
        </Column.Group>
        <Column.Group vcentered multiline gapSize={8}>
          <Column size="8">
            <Input
              label="Bank Account Number"
              name="acc_number"
              value={values.acc_number}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.acc_number && touched.acc_number && 'is-danger'}
              errors={errors.acc_number}
              touched={touched.acc_number}
              disableToastMessage
              required
            />
          </Column>
        </Column.Group>
      </Column>
      <hr />
      <Footer
        history={history}
        loading={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ bankDetails, user, place }) => ({
    acc_bsb_number: bankDetails?.acc_bsb_number || '',
    status: bankDetails?.status || 'ACTIVE',
    user_id: bankDetails?.user_id || user?.user_id,
    place_id: bankDetails?.place_id || place?.place_id,
    acc_number: bankDetails?.acc_number || '',
    acc_name: bankDetails?.acc_name || '',
  }),

  validate: () => {},

  validationSchema: yup.object().shape({
    acc_bsb_number: yup
      .string()
      .max(6, 'Acc BSB Number should not exceed 6 characters.')
      .required('Acc BSB Number is required'),
    acc_number: yup
      .string()
      .max(10, 'Acc Number should not exceed 10 characters.')
      .required('Acc Number is required'),
    acc_name: yup.string().required('Acc Name is required'),
  }),

  handleSubmit: async (values, { props }) => {
    const res = await submitBankDetails({
      input: {
        ...values,
        place_bank_detail_id:
          props?.bankDetails?.place_bank_detail_id || uuidv4(),
      },
      isUpdate: props.isUpdate,
    });

    if (res.isError) {
      toast.error(res.error);
      return;
    }

    swal({
      title: 'Banking Details Saved Successfully!',
      type: 'success',
    }).then(() => {
      props.history.goBack();
    });
  },

  enableReinitialize: true,

  displayName: 'PlaceBankAccount',
})(BankDetailsForm);

export default Form;
