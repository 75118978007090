/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import { PageHeader } from '../../../components/elements';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Header = ({
  loading,
  handleSubmit,
  place,
  history,
  formStatus,
  activeTab,
  next,
  prev,
  saveDraft = true,
}) => (
  <PageHeader
    title="Restaurant Onboarding"
    otherText={`${place.name} | ${place.state} | ${place.city}, ${place.post_code} (${place.status} / ${place.claim_status})`}
  >
    <Level.Item align="left">
      <button
        className="button"
        style={{ backgroundColor: '#00b28c', color: 'white' }}
        onClick={() => {}}
        type="button"
      >
        {formStatus}
      </button>
    </Level.Item>
    <Level.Item align="right">
      <Level.Item>
        <a onClick={() => history.goBack()} className="button is-light">
          <span>Cancel</span>
        </a>
      </Level.Item>
      {activeTab !== 0 && (
        <Level.Item>
          <button onClick={prev} className="button is-primary">
            <span>Prev Tab</span>
          </button>
        </Level.Item>
      )}

      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action="create_place"
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => (
              <Level.Item>
                <button
                  type="submit"
                  className={`button is-primary ${loading && 'is-loading'}`}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {saveDraft ? <span>Save Draft</span> : <span>Submit</span>}
                </button>
              </Level.Item>
            )}
          />
        )}
      </ACLSConsumer>

      {activeTab !== 4 && (
        <Level.Item>
          <button onClick={next} className="button is-primary">
            <span>Next Tab</span>
          </button>
        </Level.Item>
      )}
    </Level.Item>
  </PageHeader>
);

export default withRouter(Header);
