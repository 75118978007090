import React from 'react';

import { Label } from 'rbx';
import BasicTextEditor from './BasicTextEditor';

const PaymentPolicyTerms = ({ values, setFieldValue, errors, touched }) => (
  <div className="m-4">
    <Label>Booking Terms: Terms & Conditions</Label>
    <BasicTextEditor
      disabled={!values.collect_booking_fee}
      name="booking_message"
      value={values.booking_terms_and_condition}
      handleChange={e => setFieldValue('booking_terms_and_condition', e)}
      // onBlur={handleBlur}
      errors={errors.booking_terms_and_condition}
      touched={touched.booking_terms_and_condition}
      disableToastMessage
      optionalText="(Min 10 and Max 915 characters)"
    />

    {values.booking_terms_and_condition && (
      <div style={{ textAlign: 'right' }}>
        {values.booking_terms_and_condition.length > 915 ||
        values.booking_terms_and_condition.length < 10
          ? ''
          : `Characters left ${915 -
              values.booking_terms_and_condition.length}`}
      </div>
    )}
  </div>
);

export default PaymentPolicyTerms;
