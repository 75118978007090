/* eslint-disable func-names */
import React from 'react';
import { useStoreState } from 'easy-peasy';

import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { forEach, intersectionBy, sortBy } from 'lodash';

import {
  Input,
  ErrorMessage,
  TagInputs,
  Select,
  MobileInput,
} from '../../../../components/elements';

import { ACLSConsumer } from '../../../../utils/aclsContext';
import { useUserData } from './helpers';

yup.addMethod(yup.string, 'testNonNegativeValue', function(errorMessage) {
  return this.test(`test-non-negative-value`, errorMessage, function(value) {
    const { path, createError } = this;
    const num = +value;

    return num >= 0 || createError({ path, message: errorMessage });
  });
});

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 80vh;
      padding: 0rem 1rem;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const isJustAPartnerFunc = roleArray => {
  const partner = (roleArray ?? []).filter(rl => rl.startsWith('partner'));
  const kravein = (roleArray ?? []).filter(rl => rl.startsWith('kravein'));

  let isPartner = false;

  if (kravein.length !== 0) {
    isPartner = false;
  } else if (partner.length !== 0) {
    isPartner = true;
  }

  return isPartner;
};

const parseRolesWithLabels = (userCmsRoles, assign_roles) => {
  const rolesWithLabels = [];

  forEach(assign_roles, item => {
    const filteredRoles = userCmsRoles.filter(edge => edge.value === item);
    if (filteredRoles.length !== 0) {
      rolesWithLabels.push({
        value: filteredRoles[0].value,
        label: `${filteredRoles[0].label} (${filteredRoles[0].value})`,
      });
    }
  });

  return sortBy(rolesWithLabels, 'label');
};

const AssignRoles = ({
  assign_roles,
  userCmsRoles,
  role,
  setFieldValue,
  isJustAPartner,
  isOwnAccount,
  errors,
}) => {
  const roleValues = intersectionBy(
    userCmsRoles,
    role.map(item => ({ value: item })),
    'value',
  );

  const userRoles = parseRolesWithLabels(userCmsRoles, assign_roles);

  return (
    <>
      <TagInputs
        label="Role"
        disabled={isJustAPartner && isOwnAccount}
        required
        suggestions={userRoles}
        value={roleValues}
        onChange={value => {
          if (value === null) {
            // setFieldValue('access', { place_id: [] });
            // setFieldValue('places', []);
            setFieldValue('role', []);
          } else {
            const roleInput = value.map(item => item.value);
            setFieldValue('role', roleInput);
          }
        }}
      />
      {errors.role && <ErrorMessage message={errors.role} />}
    </>
  );
};

const FormContent = props => {
  const {
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    userData,
    errors,
  } = props;

  const { user } = useStoreState(state => state.auth);

  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-half">
          <Select
            label="Status"
            value={[{ value: userData.status, label: userData.status }]}
            onChange={({ value }) => setFieldValue('status', value)}
            required
            disabled
          />
        </div>
        <div className="column is-half">
          <Input
            label="Name"
            value={`${userData.first_name} ${userData.last_name}`}
            required
            disabled
          />
        </div>
        <div className="column is-half">
          <Input
            label="Display Name"
            required
            value={userData.display_name}
            disabled
          />
        </div>
        <div className="column is-half">
          <MobileInput
            label="Contact Phone"
            name="primaryContact"
            value={
              userData.contact && Array.isArray(userData.contact)
                ? userData.contact.find(
                    c => c.is_primary && c.type === 'mobile',
                  )?.value
                : null
            }
            disabled
          />
        </div>
        <div className="column is-half">
          <Input
            label="Contact Email"
            value={userData.email}
            name="email"
            placeholder="Email"
            disabled
          />
        </div>

        <div className="column is-half">
          <ACLSConsumer>
            {({ assignRoles, role: ctxRole }) => (
              <AssignRoles
                assign_roles={assignRoles}
                isJustAPartner={isJustAPartnerFunc(ctxRole)}
                isOwnAccount={user?.email === userData.email}
                role={values.role}
                userCmsRoles={
                  userData.rolesFromCMS ? userData.rolesFromCMS : []
                }
                setFieldValue={setFieldValue}
                errors={errors}
              />
            )}
          </ACLSConsumer>
        </div>
      </div>
      <footer className="modal-card-foot">
        <button type="button" className="button" onClick={onClose}>
          cancel
        </button>
        <button
          type="button"
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={handleSubmit}
        >
          Save
        </button>
      </footer>
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ userData }) => ({
    user: userData,
    role: userData?.role ? userData.role : [],
  }),

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props }) => {
    props.onSubmit({
      user: values.user,
      role: values.role,
    });
  },
  enableReinitialize: true,
  displayName: 'Add Edit Admin User',
})(FormContent);

const Modal = props => {
  const { isActive, onClose, loading, editUser, onSubmit } = props;

  const [searchLogin, setSearchLogin] = React.useState(editUser?.email ?? '');
  const [searchError, setSearchError] = React.useState(false);

  const [userIsLoading, fetchUserFunc] = useUserData({ email: searchLogin });

  const [userData, setUserData] = React.useState(editUser ?? null);

  console.log('userIsLoading', userIsLoading);
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Admin</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          <div
            style={{ position: 'relative', marginBottom: '1rem' }}
            className="column is-6"
          >
            <Input
              label="Email"
              type="text"
              id="UserManagement_AddAdminUser_Login"
              value={searchLogin}
              onChange={user => {
                setSearchError(false);
                setSearchLogin(user.target.value);
              }}
              placeholder="Enter an email connected to KRAVEiN"
              disabled={editUser}
            />
            <button
              type="button"
              className={`button is-primary ${userIsLoading && 'is-loading'}`}
              disabled={editUser || userIsLoading}
              style={{
                position: 'absolute',
                top: '38px',
                right: '-90px',
              }}
              onClick={async () => {
                const schema = yup.object().shape({
                  email: yup
                    .string()
                    .required()
                    .email(),
                });

                const isValidEmail = await schema.isValid({
                  email: searchLogin.trim(),
                });

                if (!isValidEmail) {
                  setSearchError(true);
                } else {
                  const userRes = await fetchUserFunc({
                    email: searchLogin.trim(),
                  });

                  if (userRes) {
                    setUserData(userRes);
                  }
                }
              }}
            >
              <span className="has-text-weight-semibold">Search</span>
            </button>
            {searchError && (
              <p
                style={{
                  fontSize: '10px',
                  position: 'absolute',
                  bottom: '0',
                  color: 'red',
                  marginLeft: '2px',
                }}
              >
                Please enter a valid email
              </p>
            )}
          </div>

          {userData && (
            <Form
              userData={userData}
              onClose={onClose}
              loading={loading}
              onSubmit={onSubmit}
            />
          )}
        </section>
      </div>
    </Container>
  );
};

export default Modal;
