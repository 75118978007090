import React, { useState } from 'react';
import styled from 'styled-components';

import Form from './Form';
import VariantItem from './VariantItem';
import Modifiers from './Modifiers';

const Container = styled.div``;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.waterMarkGrey};
  padding: 1rem 1rem;
  align-items: center;
  background-color: ${({ availability }) =>
    !availability ? '#fbf8f8' : '#fbf8f8'};
`;

const Item = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`;

const IconStyled = styled.i`
  margin-right: 10px;
`;

const LinkItem = ({
  link,
  placeId,
  onHandleLinkSubmission,
  onHandleLinkModifierSubmission,
}) => {
  const [isActive, setIsActive] = useState(false);

  const rootIsAvailable = link.rootItemAvailabilityStatus !== 'UNAVAILABLE';
  const availability_date =
    new Date(link.availability_date) > new Date()
      ? link.availability_date
      : null;
  const availabilityStatus = availability_date ? 'UNAVAILABLE' : 'AVAILABLE';

  return (
    <>
      <Container>
        <Flex>
          <Item
            onClick={() => {
              if (link.readOnlyItem) {
                // pass
              } else {
                setIsActive(!isActive);
              }
            }}
          >
            {link?.variant?.length !== 0 ||
            link?.modifier_groups_id?.length !== 0 ? (
              <>
                {isActive ? (
                  <IconStyled className="fas fa-chevron-down" />
                ) : (
                  <IconStyled className="fas fa-chevron-right" />
                )}
              </>
            ) : (
              <div />
            )}
            <div>
              <h4 className="title is-4">{link.name}</h4>
              <h3 className="subtitle is-5">
                {link.serviceType} - {link.menuName} | {link.sectionName}
              </h3>
            </div>
          </Item>

          <Form
            readOnly={link.readOnlyItem}
            rootIsAvailable={rootIsAvailable}
            availability_date={availability_date}
            availabilityStatus={availabilityStatus}
            onSubmit={date => {
              onHandleLinkSubmission(date, 'ITEM_LINK');
            }}
          />
        </Flex>
      </Container>
      {isActive &&
        link.variant &&
        link.variant.map(edge =>
          edge.variants.map(edge2 => {
            const var_availability_date =
              new Date(edge2.availability_date) > new Date()
                ? edge2.availability_date
                : null;
            const availabilityStatus2 = var_availability_date
              ? 'UNAVAILABLE'
              : 'AVAILABLE';
            return (
              <VariantItem
                availabilityStatus={availabilityStatus2}
                availability_date={var_availability_date}
                rootIsAvailable={rootIsAvailable}
                prompt={edge.prompt}
                variant={edge2}
                onSubmit={date => {
                  onHandleLinkSubmission(date, 'ITEM_LINK_VARIANT', {
                    ...edge2,
                    prompt_id: edge.prompt_id,
                  });
                }}
              />
            );
          }),
        )}
      {isActive && (
        <Modifiers
          placeId={placeId}
          link={link}
          onHandleLinkModifierSubmission={onHandleLinkModifierSubmission}
          linkRootAVDate={availability_date}
        />
      )}
    </>
  );
};

export default LinkItem;
