import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';

import { fetchMenus, fetchTableMenu, fetchTableSetting } from './helpers';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import TableBookingSettingForm from './TableBookingSettingForm';

const LoadingText = styled.span`
  padding: 1rem;
  color: #00aeef;
`;

// const DangerText = styled.span`
//   padding: 1rem;
//   color: red;
// `;

const TableBookingSetting = ({ match, history }) => {
  const { userId } = useStoreState(state => state.auth);

  const [menus, setMenus] = React.useState([]);
  const [noMenusAvailableError, setNoMenusAvailableError] = React.useState(
    false,
  );
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [tableMenu, setTableMenu] = React.useState(null);

  console.log('activeMenu', activeMenu);
  const [loadingMenus, setLoadingMenus] = React.useState(true);
  const [place, setPlace] = React.useState();
  const [serviceTypes, setServiceTypes] = React.useState();
  const [tableBookingSetting, setTableBookingSetting] = React.useState(null);

  const [loadingTableBookingData, setLoadingTableBookingData] = React.useState(
    false,
  );

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoadingMenus(true);
      const res = await fetchMenus({ placeId: match.params.placeId, userId });
      if (res) {
        setMenus(res?.menus ?? []);
        setPlace(res?.place);
        setServiceTypes(res?.serviceTypes);
        if (res?.menus.length === 0) {
          setNoMenusAvailableError(true);
        } else {
          setNoMenusAvailableError(false);
        }
      }
      setLoadingMenus(false);
    };
    fetchFunc();
  }, []);

  // React.useEffect(() => {
  //   if (menus.length !== 0) {
  //     setActiveMenu(menus[0]);
  //   }
  // }, [menus]);

  React.useEffect(() => {
    const fetchFunc = async () => {
      const checkTableBooking = tb =>
        tb === 'Table booking' ||
        tb === 'Table Booking' ||
        tb === 'Table bookings';
      setLoadingTableBookingData(true);
      const tableBooking = (serviceTypes ?? []).find(ele =>
        checkTableBooking(ele.service_type),
      );
      if (tableBooking) {
        const res = await fetchTableSetting({
          placeId: match.params.placeId,
        });
        if (res) {
          setTableBookingSetting(res);
          const res2 = await fetchTableMenu({
            placeId: match.params.placeId,
            serviceTypeSettingId: res.service_type_setting_id,
          });
          if (res2) {
            const tempActiveMenu = menus.find(
              ele => ele.menu_id === res2.menu_id,
            );
            setTableMenu(res2);
            setActiveMenu(tempActiveMenu);
          }
        }
      }
      setLoadingTableBookingData(false);
    };
    if (serviceTypes) {
      fetchFunc();
    }
  }, [serviceTypes]);

  // if (noMenusAvailableError) {
  //   return (
  //     <Layout>
  //       <Header />
  //       <DangerText>
  //         Please Add a Menu to this Place To Create Table Booking Settings!
  //       </DangerText>
  //     </Layout>
  //   );
  // }

  if (loadingMenus) {
    return (
      <Layout>
        <Header />
        <LoadingText>loading Place & Menu details...</LoadingText>
      </Layout>
    );
  }

  if (loadingTableBookingData) {
    return (
      <Layout>
        <Header />
        <LoadingText>fetching Table Booking Settings...</LoadingText>
      </Layout>
    );
  }

  return (
    <TableBookingSettingForm
      userId={userId}
      place={place}
      menus={menus}
      activeMenu={activeMenu}
      noMenusAvailable={noMenusAvailableError}
      history={history}
      tableBookingSetting={tableBookingSetting}
      tableMenu={tableMenu}
    />
  );
};

export default TableBookingSetting;
