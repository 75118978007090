import React from 'react';

import { Tab } from 'rbx';

import {
  Tabs,
  // InputErrorMessage,
} from '../../../components/elements';

const TabSection = ({ tab, setTab }) => (
  <Tabs className="tabs is-medium">
    <Tab active={tab === 0} onClick={() => setTab(0)}>
      Selected Day Bookings
    </Tab>
    <Tab active={tab === 1} onClick={() => setTab(1)}>
      New Bookings
    </Tab>
    <Tab active={tab === 2} onClick={() => setTab(2)}>
      Upcoming Bookings
    </Tab>
    <Tab active={tab === 3} onClick={() => setTab(3)}>
      Previous Bookings
    </Tab>
    <Tab active={tab === 4} onClick={() => setTab(4)}>
      Booking Sessions
    </Tab>
  </Tabs>
);

export default TabSection;
