import React from 'react';
import styled from 'styled-components';
import { isNaN, range } from 'lodash';

import { Column, Input, Label } from 'rbx';
import {
  CheckBox,
  Select,
  Input as CustomInput,
  InputAddOn,
  BoxWithHeader,
  InputErrorMessage,
  TextArea,
} from '../../../components/elements';

const Field = styled.div`
  align-items: center;
  &&& {
    .input {
      min-width: 7rem;
    }
  }
`;

const LabelWrapper = styled.div`
  padding-right: 1rem;
  width: 100%;
`;

const SelectWrapper = styled.div`
  padding-right: 1rem;
  width: 30%;
`;

const OptionalText = styled.p`
  &&& {
    color: #737373;
    padding-left: 2px;
    padding-top: 0.5rem;
  }
`;

const Delivery = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  type,
  errors,
  touched,
}) => {
  const cuOffSuggestions = range(5, 65, 5).map(item => ({
    label: `${item} Min`,
    value: item,
  }));

  const addedEstimate = [0, 10, 15, 20, 30].map(item => ({
    label: `${item} Min`,
    value: item,
  }));

  const rangeForNumberOfDays = range(1, 11).map(item => ({
    label: item,
    value: item,
  }));

  return (
    <>
      <Column.Group vcentered gapSize={8}>
        <Column size="6">
          <CustomInput
            label="Service Type Name"
            value={values.service_type}
            disabled
          />
        </Column>
        <Column size="6">
          <CustomInput label="Status" value={values.status} disabled />
        </Column>
      </Column.Group>
      <p className="title has-text-weight-semibold">
        {values.service_type} Settings
      </p>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size="6">
          <Field className="is-flex">
            <LabelWrapper>
              <Label>Minimum Order Amount</Label>
            </LabelWrapper>
            <InputAddOn
              addonsText="$"
              isLeft
              value={values.minimum_order_amount}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('minimum_order_amount', null);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue(
                    'minimum_order_amount',
                    parseFloat(e.target.value),
                  );
                }
              }}
              onBlur={e => {
                if (e.target.value === '') {
                  setFieldValue('minimum_order_amount', null);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue(
                    'minimum_order_amount',
                    parseFloat(e.target.value).toFixed(2),
                  );
                }
              }}
              type="number"
              min="0"
              max="50"
              step="0.01"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field className="is-flex">
            <LabelWrapper>
              <Label>Maximum Order Amount</Label>
            </LabelWrapper>
            <InputAddOn
              addonsText="$"
              isLeft
              value={values.maximum_order_amount}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('maximum_order_amount', null);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue(
                    'maximum_order_amount',
                    parseFloat(e.target.value),
                  );
                }
              }}
              onBlur={e => {
                if (e.target.value === '') {
                  setFieldValue('maximum_order_amount', null);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue(
                    'maximum_order_amount',
                    parseFloat(e.target.value).toFixed(2),
                  );
                }
              }}
              type="number"
              min="50"
              max="1000"
              step="0.01"
            />
          </Field>
        </Column>

        {type === 'delivery' && (
          <Column size="6">
            <Field className="is-flex">
              <LabelWrapper>
                <Label>Default Delivery Fee</Label>
              </LabelWrapper>
              <InputAddOn
                addonsText="$"
                isLeft
                value={values.delivery_fee}
                onChange={e => {
                  if (e.target.value === '') {
                    setFieldValue('delivery_fee', null);
                  } else if (!isNaN(parseFloat(e.target.value))) {
                    setFieldValue('delivery_fee', parseFloat(e.target.value));
                  }
                }}
                onBlur={e => {
                  if (e.target.value === '') {
                    setFieldValue('delivery_fee', null);
                  } else if (!isNaN(parseFloat(e.target.value))) {
                    setFieldValue(
                      'delivery_fee',
                      parseFloat(e.target.value).toFixed(2),
                    );
                  }
                }}
                type="number"
                min="0"
                step="0.01"
              />
            </Field>
          </Column>
        )}
        <Column size="6">
          <Field className="is-flex">
            <LabelWrapper>
              <Label>Last order accepted before closing time </Label>
            </LabelWrapper>
            <LabelWrapper>
              <Select
                value={{
                  label: `${values.cut_off_time} Min`,
                  value: values.cut_off_time,
                }}
                options={cuOffSuggestions}
                onChange={value => {
                  setFieldValue('cut_off_time', value.value);
                }}
              />
            </LabelWrapper>
          </Field>
          <OptionalText className="is-size-4">
            (Choose from 10, 15, 20, 30, 45, 60, 75 and 90 minutes)
          </OptionalText>
        </Column>
        {type !== 'delivery' && <Column size="6" />}
        <Column size="6">
          <Label className="is-flex">
            Allow user to schedule orders in advance &nbsp; &nbsp;
            <CheckBox
              value={values.schedule_order}
              onChange={value => {
                setFieldValue('schedule_order', value);
                if (!value) {
                  setFieldValue('schedule_order_when_closed', false);
                  setFieldValue('schedule_order_duration', null);
                } else {
                  setFieldValue('schedule_order_duration', 1);
                }
              }}
            />
          </Label>
        </Column>

        <Column size="6">
          <Field className="is-flex">
            <Label className="is-flex">
              Allow user to schedule when the place is closed &nbsp;&nbsp;{' '}
              <CheckBox
                value={values.schedule_order_when_closed}
                onChange={value =>
                  setFieldValue('schedule_order_when_closed', value)
                }
                disabled={!values.schedule_order}
              />
            </Label>
          </Field>
          <br />
          <Field className="is-flex">
            <Label className="is-flex">
              Catering Menu&nbsp;&nbsp;{' '}
              <CheckBox
                value={values.is_catering_service}
                onChange={() => {
                  setFieldValue(
                    'is_catering_service',
                    !values.is_catering_service,
                  );
                }}
              />
            </Label>
          </Field>
        </Column>
        <Column size="6">
          <Field className="is-flex">
            <LabelWrapper>
              <Label>
                Maximum number of days user can schedule order in advance
              </Label>
            </LabelWrapper>
            <SelectWrapper>
              <Select
                value={{
                  label: values.schedule_order_duration,
                  value: values.schedule_order_duration,
                }}
                options={rangeForNumberOfDays}
                onChange={value => {
                  setFieldValue('schedule_order_duration', value.value);
                }}
                disabled={!values.schedule_order}
              />
            </SelectWrapper>
          </Field>
          <OptionalText className="is-size-4">
            (A maximum of 10 days can be selected )
          </OptionalText>
        </Column>
      </Column.Group>
      <hr />
      <Column.Group vcentered multiline gapSize={4}>
        <Column size="4">
          <Field className="is-flex">
            <Label className="is-flex">
              Display Attendee Options &nbsp;&nbsp;{' '}
              <CheckBox
                value={values.display_attendee_options}
                onChange={value => {
                  setFieldValue('display_attendee_options', value);
                }}
                // disabled={type === 'dinein'}
              />
            </Label>
          </Field>
        </Column>
        <Column size="4">
          <Field className="is-flex">
            <LabelWrapper>
              <Label>Minimum Attendees</Label>
            </LabelWrapper>
            <Input
              value={values.minimum_no_of_attendees}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('minimum_no_of_attendees', null);
                } else if (!isNaN(parseInt(e.target.value, 10))) {
                  setFieldValue(
                    'minimum_no_of_attendees',
                    parseInt(e.target.value, 10),
                  );
                }
              }}
              type="number"
              min="1"
              step="0"
              disabled={!values.display_attendee_options}
            />
          </Field>
          <InputErrorMessage
            errors={errors.minimum_no_of_attendees}
            touched={touched.minimum_no_of_attendees}
            disableToastMessage
          />
        </Column>
        <Column size="4">
          <Field className="is-flex">
            <LabelWrapper>
              <Label> Maximum Attendees </Label>
            </LabelWrapper>
            <Input
              value={values.maximum_no_of_attendees}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('maximum_no_of_attendees', null);
                } else if (!isNaN(parseInt(e.target.value, 10))) {
                  setFieldValue(
                    'maximum_no_of_attendees',
                    parseInt(e.target.value, 10),
                  );
                }
              }}
              type="number"
              min={`${values.minimum_no_of_attendees}`}
              max="100"
              step="0"
              disabled={!values.display_attendee_options}
            />
          </Field>
          <InputErrorMessage
            errors={errors.maximum_no_of_attendees}
            touched={touched.maximum_no_of_attendees}
            disableToastMessage
          />
        </Column>
      </Column.Group>
      {type === 'dinein' && (
        <Column.Group vcentered multiline gapSize={4}>
          <Column size="4">
            <Field className="is-flex">
              <Label className="is-flex">
                Allow Table Number Input &nbsp;&nbsp;{' '}
                <CheckBox
                  value={values.display_table_number}
                  onChange={value => {
                    setFieldValue('display_table_number', value);
                  }}
                  disabled={type !== 'dinein'}
                />
              </Label>
            </Field>
          </Column>
        </Column.Group>
      )}
      <hr />
      {type === 'pickup' && (
        <Column.Group vcentered multiline gapSize={4}>
          <Column size="4">
            <Field className="is-flex">
              <Label className="is-flex">
                Allow Pickup Options &nbsp;&nbsp;{' '}
                <CheckBox
                  value={values.allow_pickup_options}
                  onChange={value => {
                    setFieldValue('allow_pickup_options', value);
                    if (value) {
                      setFieldValue('inStore', true);
                      setFieldValue('pickup_method', [
                        ...values.pickup_method,
                        'InStore',
                      ]);
                    }
                  }}
                  disabled={type !== 'pickup'}
                />
              </Label>
            </Field>
            <InputErrorMessage
              errors={errors.pickup_method}
              touched={touched.pickup_method}
              disableToastMessage
            />
          </Column>
        </Column.Group>
      )}
      {type === 'pickup' && (
        <Column.Group vcentered multiline gapSize={4}>
          <Column size="3">
            <Field className="is-flex">
              <Label className="is-flex">
                Instore &nbsp;&nbsp;{' '}
                <CheckBox
                  value={values.inStore}
                  onChange={value => {
                    if (value) {
                      setFieldValue('inStore', true);
                      setFieldValue('pickup_method', [
                        ...values.pickup_method,
                        'InStore',
                      ]);
                    } else {
                      setFieldValue('inStore', false);
                      const filteredValue = values.pickup_method.filter(
                        item => item !== 'InStore',
                      );
                      setFieldValue('pickup_method', filteredValue);
                    }
                  }}
                  disabled={!values.allow_pickup_options}
                />
              </Label>
            </Field>
          </Column>
          <Column size="3">
            <Field className="is-flex">
              <Label className="is-flex">
                Curbside &nbsp;&nbsp;{' '}
                <CheckBox
                  value={values.curbSide}
                  onChange={value => {
                    if (value) {
                      setFieldValue('curbSide', true);
                      setFieldValue('pickup_method', [
                        ...values.pickup_method,
                        'CurbSide',
                      ]);
                    } else {
                      setFieldValue('curbSide', false);
                      const filteredValue = values.pickup_method.filter(
                        item => item !== 'CurbSide',
                      );
                      setFieldValue('pickup_method', filteredValue);
                    }
                  }}
                  disabled={!values.allow_pickup_options}
                />
              </Label>
            </Field>
          </Column>
          <Column size="3">
            <Field className="is-flex">
              <Label className="is-flex">
                Carpark &nbsp;&nbsp;{' '}
                <CheckBox
                  value={values.carPark}
                  onChange={value => {
                    if (value) {
                      setFieldValue('carPark', true);
                      setFieldValue('pickup_method', [
                        ...values.pickup_method,
                        'Carpark',
                      ]);
                    } else {
                      setFieldValue('carPark', false);
                      const filteredValue = values.pickup_method.filter(
                        item => item !== 'Carpark',
                      );
                      setFieldValue('pickup_method', filteredValue);
                    }
                  }}
                  disabled={!values.allow_pickup_options}
                />
              </Label>
            </Field>
          </Column>
          <Column size="3">
            <Field className="is-flex">
              <Label className="is-flex">
                Drive-Thru &nbsp;&nbsp;{' '}
                <CheckBox
                  value={values.driveThru}
                  onChange={value => {
                    if (value) {
                      setFieldValue('driveThru', true);
                      setFieldValue('pickup_method', [
                        ...values.pickup_method,
                        'DriveThru',
                      ]);
                    } else {
                      setFieldValue('driveThru', false);
                      const filteredValue = values.pickup_method.filter(
                        item => item !== 'DriveThru',
                      );
                      setFieldValue('pickup_method', filteredValue);
                    }
                  }}
                  disabled={!values.allow_pickup_options}
                />
              </Label>
            </Field>
          </Column>
        </Column.Group>
      )}
      {type === 'pickup' && (
        <TextArea
          label="Pickup instructions"
          // id="AddServiceType_ServiceTypeDescription"
          name="pickup_instructions"
          value={values.pickup_instructions}
          onChange={handleChange}
          onBlur={handleBlur}
          optionalText="( Min 10 and Max 288 Characters )"
          errors={errors.pickup_instructions}
          touched={touched.pickup_instructions}
          disableToastMessage
          disabled={!values.allow_pickup_options}
        />
      )}
      {type === 'delivery' && (
        <TextArea
          label="Driver Pickup instructions"
          // id="AddServiceType_ServiceTypeDescription"
          name="driver_pickup_instruction"
          value={values.driver_pickup_instruction}
          onChange={handleChange}
          onBlur={handleBlur}
          optionalText="( Min 10 and Max 288 Characters )"
          errors={errors.driver_pickup_instruction}
          touched={touched.driver_pickup_instruction}
          disableToastMessage
        />
      )}
      {type === 'pickup' && (
        <Column.Group vcentered multiline gapSize={4}>
          <Column size="4">
            <Field className="is-flex">
              <Label className="is-flex">
                Include license plate number, car make and color &nbsp;&nbsp;{' '}
                <CheckBox
                  value={values.include_vehicle_information}
                  onChange={value => {
                    setFieldValue('include_vehicle_information', value);
                  }}
                  disabled={!values.allow_pickup_options}
                />
              </Label>
            </Field>
          </Column>
        </Column.Group>
      )}

      <BoxWithHeader title="Default Estimates">
        <Column.Group vcentered gapSize={4}>
          <Column size="4">
            <Select
              label="Estimate Range"
              value={{
                label: `${values.added_estimate} Min`,
                value: values.added_estimate,
              }}
              options={addedEstimate}
              onChange={value => {
                setFieldValue('added_estimate', value.value);
              }}
              optionalText2="( Choose from 0, 10, 15, 20 or 30 mins for the estimate to display
                as a range. For example if the total estimate is 30 min, the
                estimates range is 30-40 min to the user )"
            />
          </Column>
        </Column.Group>

        <Column.Group vcentered gapSize={4}>
          <Column size="3">
            <Field className="is-flex">
              <LabelWrapper>
                <Label>Prep Estimate</Label>
              </LabelWrapper>
              <LabelWrapper>
                <Select
                  value={{
                    label: `${values.preparation_estimate} Min`,
                    value: values.preparation_estimate,
                  }}
                  options={cuOffSuggestions}
                  onChange={value => {
                    setFieldValue('preparation_estimate', value.value);
                  }}
                />
              </LabelWrapper>
            </Field>
          </Column>
          {type === 'delivery' && (
            <Column size="1">
              <i className="fas fa-plus" />
            </Column>
          )}

          {type === 'delivery' && (
            <Column size="3">
              <Field className="is-flex">
                <LabelWrapper>
                  <Label>Delivery Estimates</Label>
                </LabelWrapper>
                <LabelWrapper>
                  <Select
                    value={{
                      label: `${values.delivery_estimate} Min`,
                      value: values.delivery_estimate,
                    }}
                    options={cuOffSuggestions}
                    onChange={value => {
                      setFieldValue('delivery_estimate', value.value);
                    }}
                  />
                </LabelWrapper>
              </Field>
            </Column>
          )}
          {type === 'delivery' && (
            <Column size="4">
              <Field className="is-flex">
                <LabelWrapper>
                  <Label>Total Estimates</Label>
                </LabelWrapper>
                <InputAddOn
                  addonsText="Min"
                  isRight
                  value={
                    parseFloat(values.delivery_estimate) +
                    parseFloat(values.preparation_estimate)
                  }
                  type="number"
                  min="0"
                  disabled
                />
              </Field>
            </Column>
          )}
        </Column.Group>
      </BoxWithHeader>
    </>
  );
};

export default Delivery;
