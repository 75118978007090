import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import swal from 'sweetalert';

import { isNull, omit, forEach } from 'lodash';
import moment from 'moment-timezone';
import { Label, Column } from 'rbx';
import Hours from './Hours';

import {
  ReactDateTimePicker,
  Input,
  InputErrorMessage,
  // CheckBox,
  Select,
} from '../../../../../components/elements';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
    .column {
      padding: 0.8rem 0.5rem;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const calCulateOpen24Hours = option => {
  let open24Hours = false;
  if (
    option.length !== 0 &&
    option[0].start === '12:00 AM' &&
    option[0].end === '11:59 PM'
  ) {
    open24Hours = true;
  }

  return open24Hours;
};
const MainForm = props => {
  const {
    isActive,
    onClose,
    handleSubmit,
    loading,
    isUpdate,
    values,
    setFieldValue,
    errors,
    touched,
  } = props;
  const minDate = new Date();
  // minDate.setDate(minDate.getDate() + 1);
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <div>
            <p className="modal-card-title">Custom Hours</p>
          </div>
          <CloseButtonWrapper>
            <button
              type="button"
              className="delete"
              aria-label="close"
              onClick={onClose}
            />
          </CloseButtonWrapper>
        </header>
        <section className="modal-card-body">
          <DetailsFormWrapper>
            <Column.Group>
              <Column size="6">
                <div style={{ position: 'relative', bottom: '6px' }}>
                  <Label
                    className="is-flex"
                    style={{ position: 'relative', top: '6px' }}
                  >
                    Service Type
                  </Label>
                  <Select
                    placeholder="Select Menu"
                    value={{ label: 'Table Booking', value: 'Table Booking' }}
                    options={[]}
                    onChange={() => {}}
                    isLoading={false}
                    disabled
                  />
                </div>
              </Column>

              <Column size="6">
                {isUpdate ? (
                  <Input
                    label="Date"
                    value={moment(values.day, 'YYYY-MM-DD').format(
                      'MMM, DD YYYY',
                    )}
                    disabled
                  />
                ) : (
                  <ReactDateTimePicker
                    label="Date"
                    value={values.day}
                    onChange={value => {
                      setFieldValue('day', value);
                    }}
                    minDate={minDate}
                  />
                )}

                <InputErrorMessage
                  errors={errors.day}
                  touched={touched.day}
                  disableToastMessage
                />
              </Column>
            </Column.Group>
            <Hours {...props} />
            {/* {!isUpdate && (
              <>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <Label className="is-flex">
                    <CheckBox
                      value={values.range}
                      onChange={value => {
                        setFieldValue('range', value);
                      }}
                    />
                    <span style={{ width: '600px' }}>
                      Apply this custom hour to multiple days
                    </span>
                  </Label>
                </div>
                {values.range && (
                  <ReactDateTimePicker
                    label="End Date"
                    value={values.endDay}
                    onChange={value => {
                      setFieldValue('endDay', value);
                    }}
                    minDate={new Date(values?.day || new Date())}
                  />
                )}
              </>
            )} */}
          </DetailsFormWrapper>
          <div className="is-flex is-justify-content-space-between m-4">
            <button
              type="button"
              className="button"
              aria-label="close"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`button is-primary ${loading && 'is-loading'}`}
              aria-label="close"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </section>
      </div>
    </Container>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ hour }) => {
    let hoursType = 'CUSTOM';
    const open24Hours = hour ? calCulateOpen24Hours(hour.option) : false;
    if (open24Hours) {
      hoursType = 'OPEN_24_HOUR';
    }
    if (
      hour &&
      Array.isArray(hour.option) &&
      hour.option.length === 0 &&
      hour.is_active === false
    ) {
      hoursType = 'CLOSED_ALL';
    }

    return {
      object_type: 'MENU',
      closedAllDay:
        hour && !isNull(hour.closed_all_day) ? hour.closed_all_day : false,
      day: hour && !isNull(hour.date) ? hour.date : null,
      option: hour && !isNull(hour.option) ? hour.option : [],
      hoursType,
      range: false,
      endDay: null,
    };
  },
  handleSubmit: (values, { props }) => {
    if (!values.day) {
      swal('Please add start date!');
      return;
    }
    if (
      values.hoursType === 'CUSTOM' &&
      Array.isArray(values.option) &&
      values.option.length === 0
    ) {
      swal('Please add Hours!');
      return;
    }
    if (values.range && new Date(values.endDay) <= new Date(values.day)) {
      swal('Please add correct dates!');
      return;
    }
    const input = omit(values, [
      'closedAllDay',
      'is_active',
      'option',
      'hoursType',
    ]);

    if (
      values.hoursType === 'CUSTOM' &&
      Array.isArray(values.option) &&
      values.option.find(ele => ele.start === '' || ele.end === '')
    ) {
      swal('Please add hours!');
      return;
    }
    const parseOption = option => {
      const final = [];
      forEach(option, item => {
        const data = omit(item, 'id', '__typename');
        final.push(data);
      });
      return final;
    };
    const option = parseOption(values.option);
    props.onSubmit({
      ...input,
      day: moment(values.day).format('YYYY-MM-DD'),
      closed_all_day: values.closedAllDay,
      option,
      status: 'ACTIVE',
    });
  },
})(MainForm);

export default Form;
