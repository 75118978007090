import React from 'react';

import styled from 'styled-components';
import { Table, Button, Icon } from 'rbx';

import ReactTooltip from 'react-tooltip';

import { useUserData } from './helpers';

const Element = styled.div``;

const ActionButtons = ({ email, onDelete, onEdit }) => {
  const [userIsLoading, fetchUserFunc] = useUserData({ email });
  const [userAccessData, setUserAccessData] = React.useState(null);
  const [deleting, setDeleting] = React.useState(false);

  React.useState(() => {
    const fetchFunc = async () => {
      const res = await fetchUserFunc();
      setUserAccessData(res);
    };
    fetchFunc();
  }, []);

  if (userIsLoading) {
    return <span>loading...</span>;
  }

  return (
    <Element>
      <Button.Group hasAddons>
        <Button onClick={() => onEdit(userAccessData)}>
          <Icon size="small">
            <i className="fas fa-edit" />
          </Icon>
        </Button>
        {deleting ? (
          <span>loading...</span>
        ) : (
          <Button
            onClick={async () => {
              setDeleting(true);
              await onDelete(userAccessData);
              setDeleting(false);
            }}
            disabled={deleting}
          >
            <Icon size="small">
              <i className="fas fa-trash has-text-grey" />
            </Icon>
          </Button>
        )}
      </Button.Group>
    </Element>
  );
};

const List = ({ admins, onDelete, onEdit }) => (
  <>
    {admins.map(item => (
      <Table.Row>
        <Table.Cell>{item.user_id}</Table.Cell>
        <Table.Cell>{item.display_name}</Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell>
          <ReactTooltip html place="top" type="dark" effect="float" />
          <p
            data-tip={`<span>
          <p>roles: ${item.role.join(', ')}</p>
        </span>`}
            data-html
          >
            {item.role.length >= 2
              ? `${item.role.slice(0, 2).join(', ')}...`
              : item.role.join(', ')}
          </p>
        </Table.Cell>
        <Table.Cell>
          {item.contact &&
          Array.isArray(item.contact) &&
          item.contact.find(ele => ele.type === 'mobile') ? (
            <a
              href={`tel:${
                item.contact.find(ele => ele.type === 'mobile').value
              }`}
            >
              {item.contact.find(ele => ele.type === 'mobile').value}
            </a>
          ) : (
            '-'
          )}
        </Table.Cell>

        <Table.Cell>
          <ActionButtons
            onEdit={onEdit}
            onDelete={onDelete}
            email={item.email}
            key={item.role.join('-')}
          />
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
