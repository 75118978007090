import React from 'react';

import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import { Column } from 'rbx';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';

import { CheckBox } from '../../../components/elements';

// import { Tabs } from '../../../components/elements';

import { useUserOrdersDashboardData, downloadCSVFile } from './helpers';
import UsersDashboardTable from './UsersDashboardTable';
import HistoricDashboardTable from './HistoricDashboardTable';

const UsersDashboard = ({
  history,
  // match,
  location,
}) => {
  const routeState = qs.parse(location.search);

  const { userId } = useStoreState(state => state.auth);

  let { startDate, endDate } = routeState;

  if (startDate) {
    // pass
  } else {
    startDate = null;
  }

  if (endDate) {
    // pass
  } else {
    endDate = null;
  }

  const [downloadCsv, setDownloadCsv] = React.useState(false);
  const [excludeTestPlace, setExcludeTestPlace] = React.useState(false);
  const [isCashExclude, setIsCashExclude] = React.useState(false);

  const placeFilter = {};

  if (isCashExclude === true) {
    placeFilter.is_cash_exclude = true;
  }
  if (excludeTestPlace === true) {
    placeFilter.is_test_place = false;
  }

  const [dataLoading, userOrdersDashboardData] = useUserOrdersDashboardData({
    adminUserId: userId,
    startDate,
    endDate,
    place_filter: placeFilter,
  });

  return (
    <Layout>
      <Header />
      <Column.Group
        style={{ padding: '1rem 1rem 0 1rem' }}
        vcentered
        multiline
        gapSize={8}
      >
        <Column size={3}>
          <CheckBox
            label="Exclude Test Restaurants"
            value={excludeTestPlace}
            onChange={value => {
              setExcludeTestPlace(value);
            }}
          />
        </Column>
        <Column size={3}>
          <CheckBox
            label="Exclude Cash Orders"
            name="event_business"
            value={isCashExclude}
            onChange={value => {
              setIsCashExclude(value);
            }}
          />
        </Column>
      </Column.Group>

      <HistoricDashboardTable
        usersDashboardTableData={userOrdersDashboardData}
        loading={dataLoading}
      />

      <Search
        startDate={startDate}
        endDate={endDate}
        routeState={routeState}
        history={history}
        downloadCsv={downloadCsv}
        onClick={async () => {
          setDownloadCsv(true);

          try {
            const temp = [userOrdersDashboardData].map(ele => {
              const { __typename, ...ele2 } = ele;
              console.log(__typename);
              return ele2;
            });

            const csvData = (() => {
              let header = Object.keys(temp[0]).join(',');
              header = `${header}\n`;
              const body = temp
                .map(ele => Object.values(ele))
                .map(ele => ele.join(','))
                .join('\n');
              return `${header}${body}`;
            })();
            downloadCSVFile(
              csvData,
              `users-dashboard-${new Date().toISOString()}.csv`,
            );
          } catch (error) {
            console.log('error on downloadCsv click function');
            console.log(error);
            setDownloadCsv(false);
          }
          setDownloadCsv(false);
        }}
      />
      {/* <Tabs className="tabs is-medium is-fullwidth"></Tabs> */}
      <UsersDashboardTable
        usersDashboardTableData={userOrdersDashboardData}
        loading={dataLoading}
      />
    </Layout>
  );
};

export default UsersDashboard;
