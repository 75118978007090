import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

// import Can from '../../../utils/Can';
// import { ACLSConsumer } from '../../../utils/aclsContext';

import { Heading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Restaurant Onboarding</Heading>
      </Level.Item>
      <Level.Item align="right">
        <Link to="/add-new-onboard" className="button is-primary">
          <span className="has-text-weight-semibold">Add New Onboarding</span>
        </Link>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Header);
